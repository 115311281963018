import { Instruction } from "../BlockComponent";

import { LastCard } from "./common";

export const Content_6_13_A = [
  Instruction(`typography_0`),
  Instruction("typography_1"),
  Instruction(`typography_2`),
  Instruction(`typography_3`),
  Instruction(`typography_4`),
  Instruction(`typography_5`),
  Instruction(`typography_6`),
  Instruction(`typography_7`),
  Instruction(`typography_8`),
  Instruction(`typography_9`),
  Instruction(`typography_10`),
  Instruction(`typography_11`),
  Instruction(`typography_12`),
  Instruction(`typography_13`),
  Instruction(`typography_14`),
  Instruction(`typography_15`),
  Instruction(`typography_16`),
  Instruction(`typography_17`),
  Instruction(`typography_18`),
  Instruction(`typography_19`),
  Instruction(`typography_20`),
  Instruction(`typography_21`),
  Instruction(`typography_22`),
  Instruction(`typography_23`),
  Instruction(`typography_24`),
  Instruction(`typography_25`),
  Instruction(`typography_26`),
  Instruction(`typography_27`),
  Instruction(`typography_28`),
  Instruction(`typography_29`),
  Instruction(`typography_30`),
  Instruction(`typography_31`),
  Instruction(`typography_32`),
  Instruction(`typography_33`),
  Instruction(`typography_34`),
  Instruction(`typography_35`),
  Instruction(`typography_36`),
  Instruction(`typography_37`),
  Instruction(`typography_38`),
  Instruction(`typography_39`),
  Instruction(`typography_40`),
  Instruction(`typography_41`),
  Instruction(`typography_42`),
  Instruction(`typography_43`),
  Instruction(`typography_44`),
  Instruction(`typography_45`),

  ...LastCard,
];

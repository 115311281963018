import { automaticThoughtIdType } from "api/conceptApi";
import { seqPostfix_ko } from "data/prefixData";
import { getRawTaskTitleFromTaskId, getTaskIdFromTaskKey } from "logic/logics";
import { useTranslation } from "react-i18next";

export default function useGetTaskTitleFromTaskKey(taskKey: string) {
  const taskId = getTaskIdFromTaskKey(taskKey);
  const { t } = useTranslation("translation", {
    keyPrefix: "task.title",
  });

  const N = taskId.split("#")[1] as automaticThoughtIdType;
  const M = taskId.split("#")[2] as automaticThoughtIdType;

  const title =
    taskKey === "dashboard"
      ? "대시보드"
      : taskKey === "thoughtmap"
      ? "생각 그물"
      : taskKey === "valueCompass"
      ? "가치 나침반"
      : taskKey === "progress"
      ? "진행 상황"
      : taskKey === "payment"
      ? "월 이용권"
      : // "월 구독"
      taskKey === "chat"
      ? "채팅"
      : getRawTaskTitleFromTaskId(taskId);
  const postfix = taskId.includes("_")
    ? ` (${taskId.split("_")[1].split("#")[0]})`
    : "";

  // return `${t(title || "", {
  //   N: `${seqPostfix_ko[N]} `, //행동활성화
  //   M: M, //행동활성화
  //   n: N, //행동실험
  // })}${postfix}`;
  if (M || N) {
    return `${t(title || "", {
      N: `${seqPostfix_ko[N]} `, //행동활성화
      M: M, //행동활성화
      n: N, //행동실험
    })}${postfix}`;
  }
  return `${title}${postfix}`;
}

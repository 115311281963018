export const translation_6_2_A = {
  en: {
    v1: {
      typography_0_0_0: "We're going to draw our Thought Map, step by step.",
      typography_1_0_0:
        "We'll see what intermediate beliefs lead to those automatic thoughts we've noticed,",
      typography_2_0_0: "Track back to the core beliefs these come from,",
      typography_3_0_0:
        "And look into the big experiences that put these core beliefs in our hearts.",
      typography_4_0_0:
        "Once we get our intermediate beliefs, core beliefs, and big experiences sorted, we'll see the whole Thought Map.",
      typography_5_0_0: "Next, we'll learn how to step back from this map.",
      typography_6_0_0: "Starting with intermediate beliefs now.",
      typography_7_0_0: "Rules, Attitudes, Assumptions",
      typography_8_0_0:
        "Intermediate beliefs are like the rules, attitudes, and assumptions we set up to deal with our core beliefs.",
      typography_9_0_0:
        "These could be about following, fighting, or dodging our core beliefs.",
      typography_10_0_0:
        "We often let these intermediate beliefs guide our daily lives.",
      typography_11_0_0: "Let's get into rules, attitudes, and assumptions.",
      typography_12_0_0: "(1) Rules",
      typography_13_0_0: `Rules are often phrased as "I must..."`,
      typography_14_0_0: `"Don’t ever mess up"`,
      typography_15_0_0: `"Don’t backtalk the boss"`,
      typography_16_0_0: `"The oldest child should always set the example"`,
      typography_17_0_0: `These are examples of rules.`,
      typography_18_0_0: `Rules are meant to guide us.`,
      typography_19_0_0: `But rules that come from core beliefs can lead to negative automatic thoughts,`,
      typography_20_0_0: "And end up making us feel sad or anxious.",
      typography_21_0_0: "(2) Attitudes",
      typography_22_0_0: `Attitudes usually sound like "This is the way it is..."`,
      typography_23_0_0: `"Failure is not an option"`,
      typography_24_0_0: `"It’s best to avoid tough challenges"`,
      typography_25_0_0: `"Depression is something you can’t beat"`,
      typography_26_0_0: "These are examples of attitudes.",
      typography_27_0_0: "Like a compass for life, attitudes help direct us.",
      typography_28_0_0: `However, attitudes coming from core beliefs can also lead to negative thoughts,`,
      typography_29_0_0: "And cause depression and anxiety.",
      typography_30_0_0: "(3) Assumptions",
      typography_31_0_0: `Assumptions typically follow "If this happens, then that will..."`,
      typography_32_0_0: `"If I fail, everything's ruined"`,
      typography_33_0_0: `"Showing weakness will make me a target"`,
      typography_34_0_0: `"Trying something too big will just end in failure"`,
      typography_35_0_0: "These are examples of assumptions.",
      typography_36_0_0: `Assumptions, just like rules, born from core beliefs, foster negative thoughts and often lead to depression and anxiety.`,
      typography_37_0_0: "Role of Intermediate Beliefs",
      typography_38_0_0:
        "Intermediate beliefs sprout from our underlying core beliefs.",
      typography_39_0_0: `Core beliefs are about fundamental, absolute thoughts,`,
      typography_40_0_0: `While intermediate beliefs apply more to specific situations or things.`,
      typography_41_0_0: `Intermediate beliefs aren't random.`,
      typography_42_0_0:
        "They're like coping methods for dealing with troublesome core beliefs.",
      typography_43_0_0: `For example, someone with the core belief "I am not good enough" might,`,
      typography_44_0_0: `Adopt the attitude "Better to stick to what you know", accepting their core belief to avoid further stress,`,
      typography_45_0_0: `Or "Best to avoid hard challenges", keeping away from situations that challenge their core belief,`,
      typography_46_0_0: `Or believe "A mistake would be the end of the world", trying to disprove their core belief by aiming for perfection.`,
      typography_47_0_0:
        "Whether it's by adhering, avoiding, or confronting, intermediate beliefs can soften the impact of core beliefs.",
      typography_48_0_0:
        "The catch is, over time, these intermediate beliefs and the automatic thoughts they generate might actually strengthen the core beliefs.",
      typography_49_0_0:
        "That's why understanding our intermediate beliefs within the Thought Map is crucial.",
      typography_50_0_0: `It helps us understand how our mind functions and why we end up feeling down or anxious and stay that way.`,
      typography_51_0_0: `Thoughts, Popcorn, and Me`,
      typography_52_0_0:
        "Did you remember we compared our mind to a popcorn machine when we started Distancing?",
      typography_53_0_0: "Pop, pop. The machine unpredictably makes popcorn.",
      typography_54_0_0: "Popcorn doesn’t just appear out of nowhere.",
      typography_55_0_0: "It’s made from seeds deep inside the machine.",
      typography_56_0_0: `Similarly, automatic thoughts, our "popcorn", are produced from deeper core and intermediate beliefs.`,
      typography_57_0_0: `As we’ve learned, we can’t directly choose our thoughts; they just pop up.`,
      typography_58_0_0: `And we can't just change the seeds in our "popcorn machine"`,
      typography_59_0_0: "because they’re part of who we are.",
      typography_60_0_0:
        "Thus, preventing unwanted popcorn from popping isn’t really possible",
      typography_61_0_0: `because "pain is part of life".`,
      typography_62_0_0: `But "suffering is optional".`,
      typography_63_0_0:
        "Once we know which seed our popped thoughts come from, stepping back from those thoughts becomes easier.",
      typography_64_0_0:
        "Not reacting too much to these thoughts, just peacefully acknowledging they're in our minds.",
      typography_65_0_0: `So, figuring out our intermediate beliefs, our "seeds", is pretty key, isn't it?`,
      typography_66_0_0: `Now, let’s think about what intermediate beliefs I have.`,
    },
  },
  ko: {
    v1: {
      typography_0_0_0: "이제부터 생각 그물을 하나씩 그려나가볼 텐데요.",
      typography_1_0_0:
        "함께 찾은 자동적 사고는 어떠한 중간 믿음으로부터 만들어졌는지,",
      typography_2_0_0:
        "그리고 그러한 중간 믿음은 어떠한 핵심 믿음으로부터 발생되었는지,",
      typography_3_0_0:
        "핵심 믿음은 어떠한 핵심 경험을 통해 우리 마음속에 자리잡게 되었는지, 하나씩 알아볼 거예요.",
      typography_4_0_0:
        "그렇게 중간 믿음, 핵심 믿음, 핵심 경험을 파악하고 나면 생각 그물이 내게 어떤 영향을 주고 있는지 이해하는 시간을 가질 거랍니다.",
      typography_5_0_0:
        "이후로는 그러한 생각 그물로부터 빠져나와 거리를 두는 연습을 해 볼 거예요.",
      typography_6_0_0: "자, 그러면 중간 믿음부터 시작해보죠.",
      typography_7_0_0: "규칙, 태도, 가정",
      typography_8_0_0:
        "앞서 '핵심 믿음의 위협에 대처하기 위해 만든 규칙, 태도, 가정'을 중간 믿음이라고 했어요.",
      typography_9_0_0:
        "핵심 믿음을 그대로 따르거나, 맞서 싸우거나, 혹은 회피하기 위해 만들어낸 것들이죠.",
      typography_10_0_0:
        "우리는 그러한 중간 믿음을 토대로 일상을 살아가곤 해요.",
      typography_11_0_0:
        "우선 중간 믿음에 해당하는 규칙, 태도, 가정 각각에 대해 알아볼게요.",
      typography_12_0_0: "(1) 규칙",
      typography_13_0_0: `규칙은 주로 "~해야 한다"라는 문장으로 정리돼요.`,
      typography_14_0_0: `"절대 실수해서는 안 된다"`,
      typography_15_0_0: `"상사의 말을 거역해선 안 된다"`,
      typography_16_0_0: `"장남은 모범을 보여야 한다"`,
      typography_17_0_0: `이러한 예시들이 규칙이라고 할 수 있죠.`,
      typography_18_0_0: `규칙은 삶의 방향성을 정해주는 역할을 하기도 해요.`,
      typography_19_0_0: `하지만 핵심 믿음으로부터 나온 규칙들은 부정적인 자동적 사고를 만들어내고,`,
      typography_20_0_0: "결국 우울이나 불안을 불러일으킨답니다.",
      typography_21_0_0: "(2) 태도",
      typography_22_0_0: `태도는 주로 "~는 ~다"라는 문장으로 정리돼요.`,
      typography_23_0_0: `"실패는 용납할 수 없다"`,
      typography_24_0_0: `"어려운 일은 가능한 피하는 게 좋다"`,
      typography_25_0_0: `"우울함은 극복할 수 있는 게 아니다"`,
      typography_26_0_0: "이러한 예시들이 태도라고 할 수 있어요.",
      typography_27_0_0:
        "마찬가지로 태도는 일종의 가치관 같은 거라 삶을 이끌어나가는 방향성이 되기도 한답니다.",
      typography_28_0_0: `하지만 역시 핵심 믿음으로부터 나온 태도는 부정적인 자동적 사고를 만들게 돼요.`,
      typography_29_0_0: "그 결과, 우울과 불안을 야기하게 된답니다.",
      typography_30_0_0: "(3) 가정",
      typography_31_0_0: `가정은 주로 "~라면 ~할 것이다"라는 문장으로 정리돼요.`,
      typography_32_0_0: `"내가 실패를 한다면 삶이 무너질 것이다"`,
      typography_33_0_0: `"약점을 보인다면 공격 당할 것이다"`,
      typography_34_0_0: `"분에 맞지 않는 새로운 일을 시도한다면 실패하고 말 것이다"`,
      typography_35_0_0: "이러한 예시들이 규칙이라고 할 수 있어요.",
      typography_36_0_0: `핵심 믿음으로부터 만들어진 규칙 역시 부정적인 사고를 만들고, 우울과 불안을 만들 때가 많답니다.`,
      typography_37_0_0: "중간 믿음의 역할",
      typography_38_0_0:
        "중간 믿음은 그보다 더 깊은 내면인 핵심 믿음으로부터 만들어져요.",
      typography_39_0_0: `이후 다시 배우겠지만 핵심 믿음은 보다 근원적이고 절대적인 생각인 반면,`,
      typography_40_0_0: `반면 중간 믿음은 그보다는 특정한 상황이나 대상에게 국한되어 적용되는 규칙, 태도, 가정을 뜻하죠.`,
      typography_41_0_0: `중간 믿음은 그냥 만들어지는 게 아니에요.`,
      typography_42_0_0:
        "앞서 이야기한 것처럼 중간 믿음은 나를 괴롭히는 핵심 믿음에 대한 일종의 대처 전략과도 같아요.",
      typography_43_0_0: `가령 "나는 무능하다"라는 핵심 믿음이 있는 사람은,`,
      typography_44_0_0: `"사람은 분수를 지키는 것이 좋다"라는 태도를 마음에 품고 살며, 핵심 믿음을 그대로 받아들이며 더 이상 감정 소모를 하지 않으려고 하기도 하고,`,
      typography_45_0_0: `"어려운 일은 가능한 피하는 게 좋다"라는 태도를 마음에 품고 살며, 핵심 믿음을 마주하게 될 상황을 피하기도 하고,`,
      typography_46_0_0: `"실수한다면 큰일날 것이다"라는 가정을 마음에 품고 살며, 완벽주의를 통해 믿음이 틀렸다는 것을 증명하려고 노력하기도 해요.`,
      typography_47_0_0:
        "그대로 따르든, 피하든, 싸우든, 어떤 방식이든 간에 중간 믿음은 핵심 믿음의 부정적인 영향을 줄여주기도 한답니다.",
      typography_48_0_0:
        "하지만 문제는 장기적인 관점에서 이러한 중간 믿음과 그로부터 만들어진 자동적 사고는 오히려 핵심 믿음을 더 강화한다는 거예요.",
      typography_49_0_0:
        "따라서 생각 지도 속에서 나의 중간 믿음을 잘 이해하는 것이 매우 중요해요.",
      typography_50_0_0: `그래야 나의 마음이 어떻게 작동하고, 우울과 불안이 왜 만들어지고 지속되는지 이해할 수 있기 때문이에요.`,
      typography_51_0_0: `생각과 팝콘과 나`,
      typography_52_0_0:
        "디스턴싱을 시작하며 우리의 마음은 정확히 팝콘 기계처럼 동작한다고 배웠어요. 기억나시나요?",
      typography_53_0_0:
        "탁, 탁. 팝콘 기계는 불규칙적으로 팝콘을 만들고 있네요.",
      typography_54_0_0:
        "사실 팝콘은 그저 무작위적으로 아무렇게나 생성되는 건 아니랍니다.",
      typography_55_0_0:
        "팝콘 기계의 더 깊은 곳에 있는 씨앗으로부터 팝콘이 만들어지죠.",
      typography_56_0_0:
        "즉, 더 깊은 곳에 있는 핵심 믿음과 중간 믿음으로부터 팝콘에 해당하는 자동적 사고가 만들어져요.",
      typography_57_0_0: `그리고 앞서 배웠듯, 우린 스스로의 의지대로 생각할 수 없어요. 팝콘은 무작위로 튀어 오를 뿐이죠.`,
      typography_58_0_0: `마찬가지로 팝콘 기계의 더 깊은 곳에 있는 씨앗을 마음대로 바꿀 순 없답니다.`,
      typography_59_0_0: "그건 우리의 정체성과도 같은 것이니까요.",
      typography_60_0_0:
        "결국 그러한 정체성으로부터 원치 않는 팝콘 조각이 튀어오르는 건 막을 수 없을 거예요.",
      typography_61_0_0: "우리 삶에서 '고통은 불가피'하니까요.",
      typography_62_0_0: "하지만 '괴로움은 선택'할 수 있답니다.",
      typography_63_0_0:
        "마음속에 떠오른 팝콘이 어떤 씨앗의 산물인지 알게 되면, 우리는 그 생각으로부터 거리를 두는 일이 훨씬 수월해지기 때문이에요.",
      typography_64_0_0:
        "마음대로 튀어오른 팝콘에 과하게 반응하지 않고, 그 생각이 내 마음속에 있다는 사실을 기꺼이 인정하고 경험하면서 말이죠.",
      typography_65_0_0:
        "그렇다면 우리는 마음속에는 어떤 중간 믿음에 해당하는 씨앗이 있는지 이해하는 것이 중요하겠죠?",
      typography_66_0_0: `자, 그러면 우선 나에게는 어떤 중간 믿음이 있는지 살펴볼까요?`,
    },
  },
};

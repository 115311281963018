import { axiosInstance, type CustomResponseFormat } from "api/instance";
import {
  AutomaticThoughtForValueType,
  ConceptKeyType_automaticThoughtForValue,
  ConceptType,
} from "./conceptApi";

export const DASHBOARD_DOMAIN = "dashboard";

export const DASHBOARD_DETAIL_ENDPOINT = "detail";
export const DASHBOARD_VALUE_EXPLORATION_DETAIL_ENDPOINT =
  "value-exploration/detail";

export interface DoneTaskType {
  taskKey: string;
  taskId: string;
}

export interface DoneTaskListByDateType {
  date: string;
  doneTaskList: DoneTaskType[];
}
export interface ScoreListType {
  scoreList: number[];
}

export interface TestResultType {
  anxiety?: ScoreListType;
  depression?: ScoreListType;
  stress?: ScoreListType;
  burnout?: ScoreListType;
}

export interface HistoryType {
  listByDate?: DoneTaskListByDateType[];
}
export interface DashboardType {
  concept: ConceptType;
  history: HistoryType;
  selfCheckResult: TestResultType;
}

export type ValueDomainType =
  | "부부"
  | "건강"
  | "사회 기여"
  | "종교"
  | "여가"
  | "배움"
  | "경력"
  | "연인"
  | "친구"
  | "부모/자녀";

export const valueDomainList = [
  "부부",
  "건강",
  "사회 기여",
  "종교",
  "여가",
  "배움",
  "경력",
  "연인",
  "인간 관계",
  "부모/자녀",
];

export interface ValueListByDomainType {
  domain: ValueDomainType;
  valueList: {
    value: string;
    countValueBehaviorCompleted: number;
    countValueBehaviorTotal: number;
  }[];
}
export type AutomaticThoughtForValueDataType = {
  [key in ConceptKeyType_automaticThoughtForValue]?: AutomaticThoughtForValueType;
};
export type ValueExplorationType = {
  valueListByDomain: ValueListByDomainType[];
} & AutomaticThoughtForValueDataType;

export const dashboardApi = {
  load: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<DashboardType>>(
      `${DASHBOARD_DOMAIN}/${DASHBOARD_DETAIL_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  getValueExploration: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<ValueExplorationType>>(
      `${DASHBOARD_DOMAIN}/${DASHBOARD_VALUE_EXPLORATION_DETAIL_ENDPOINT}`,
      { params: { pid: pid } }
    ),
};

import { Header1, Instruction, InstructionWithImage } from "../BlockComponent";

import { LastCard } from "./common";

export const Content_6_1_A = [
  Instruction(`typography_0`),
  Instruction(`typography_1`),
  Instruction(`typography_2`),
  Instruction(`typography_3`),
  Instruction(`typography_4`),
  Instruction(`typography_5`),
  Instruction(`typography_6`),
  Instruction(`typography_7`),

  Header1(`typography_8`),
  Instruction(`typography_9`),
  Instruction(`typography_10`),
  Instruction(`typography_11`),
  Instruction(`typography_12`),
  Instruction(`typography_13`),
  Instruction(`typography_14`),
  Instruction(`typography_15`),
  Instruction(`typography_16`),
  Instruction(`typography_17`),
  Instruction(`typography_18`),
  InstructionWithImage(`typography_19`, "0_v2"),

  Header1(`typography_20`),
  InstructionWithImage(`typography_21`, "1_v2"),
  Instruction(`typography_22`),
  Instruction(`typography_23`),
  InstructionWithImage(`typography_24`, "2_v2"),
  Instruction(`typography_25`),
  Instruction(`typography_26`),
  Instruction(`typography_27`),

  Header1(`typography_28`),
  InstructionWithImage(`typography_29`, "3_v2"),
  Instruction(`typography_30`),
  Instruction(`typography_31`),
  Instruction(`typography_32`),
  Instruction(`typography_33`),
  Instruction(`typography_34`),
  Instruction(`typography_35`),
  InstructionWithImage(`typography_36`, "4_v2"),
  Instruction(`typography_37`),

  Header1(`typography_38`),
  Instruction(`typography_39`),
  Instruction(`typography_40`),
  Instruction(`typography_41`),
  Instruction(`typography_42`),
  InstructionWithImage(`typography_43`, "5_v2"),
  Instruction(`typography_44`),
  Instruction(`typography_45`),
  Instruction(`typography_46`),
  Instruction(`typography_47`),
  Instruction(`typography_48`),
  InstructionWithImage(`typography_49`, "6_v2"),
  Instruction(`typography_50`),
  Instruction(`typography_51`),
  Instruction(`typography_52`),
  Instruction(`typography_53`),

  Header1(`typography_54`),
  Instruction(`typography_55`),
  Instruction(`typography_56`),
  InstructionWithImage(`typography_57`, "7_v2"),
  Instruction(`typography_58`),
  Instruction(`typography_59`),
  Instruction(`typography_60`),
  Instruction("typography_61"),
  Instruction(`typography_62`),
  Instruction(`typography_63`),
  InstructionWithImage(`typography_64`, "8_v2"),
  Instruction(`typography_65`),

  Header1(`typography_66`),
  Instruction(`typography_67`),
  Instruction(`typography_68`),
  Instruction(`typography_69`),
  Instruction("typography_70"),
  Instruction(`typography_71`),
  Instruction(`typography_72`),

  Header1(`typography_73`),
  Instruction(`typography_74`),
  InstructionWithImage(`typography_75`, "9_v2"),
  Instruction(`typography_76`),
  Instruction(`typography_77`),
  Instruction(`typography_78`),
  Instruction(`typography_79`),
  Instruction(`typography_80`),
  Instruction(`typography_81`),
  Instruction(`typography_82`),
  Instruction(`typography_83`),
  Instruction(`typography_84`),
  Instruction(`typography_85`),
  Instruction(`typography_86`),
  Instruction(`typography_87`),
  Instruction(`typography_88`),

  ...LastCard,
];

import { automaticThoughtIdList } from "api/conceptApi";
import { translation_0_0_A } from "./activity/0-0-A";
import { translation_0_1_A } from "./activity/0-1-A";
import { translation_0_2_A } from "./activity/0-2-A";
import { translation_0_3_A } from "./activity/0-3-A";
import { translation_0_4_A } from "./activity/0-4-A";
import { translation_0_5_A } from "./activity/0-5-A";
import { translation_0_6_A } from "./activity/0-6-A";
import { translation_1_1_A } from "./activity/1-1-A";
import { translation_1_2_A } from "./activity/1-2-A";
import { translation_1_3_A } from "./activity/1-3-A";
import { translation_100_0_A } from "./activity/100-0-A";
import { translation_100_1_A } from "./activity/100-1-A";
import { translation_101_1_1_A } from "./activity/101-1-1-A";
import { translation_101_1_2_A } from "./activity/101-1-2-A";
import { translation_101_1_3_T } from "./activity/101-1-3-T";
import { translation_101_2_1_A } from "./activity/101-2-1-A";
import { translation_101_2_2_A } from "./activity/101-2-2-A";
import { translation_101_2_3_A } from "./activity/101-2-3-A";
import { translation_101_2_4_A } from "./activity/101-2-4-A";
import { translation_101_2_5_A } from "./activity/101-2-5-A";
import { translation_102_1_A } from "./activity/102-1-A";
import { translation_102_2_A } from "./activity/102-2-A";
import { translation_103_1_A } from "./activity/103-1-A";
import { translation_104_0_A } from "./activity/104-0-A";
import { translation_104_1_A } from "./activity/104-1-A";
import { translation_104_2_A } from "./activity/104-2-A";
import { translation_2_1_A } from "./activity/2-1-A";
import { translation_2_2_T } from "./activity/2-2-T";
import { translation_2_3_A } from "./activity/2-3-A";
import { translation_2_4_A } from "./activity/2-4-A";
import { translation_3_2_A } from "./activity/3-2-A";
import { translation_3_3_A } from "./activity/3-3-A";
import { translation_4_1_A } from "./activity/4-1-A";
import { translation_4_10_A } from "./activity/4-10-A";
import { translation_4_11_A } from "./activity/4-11-A";
import { translation_4_2_A } from "./activity/4-2-A";
import { translation_4_3_1_A } from "./activity/4-3-1-A";
import { translation_4_3_2_A } from "./activity/4-3-2-A";
import { translation_4_4_1_A } from "./activity/4-4-1-A";
import { translation_4_4_2_A } from "./activity/4-4-2-A";
import { translation_4_4_3_A } from "./activity/4-4-3-A";
import { translation_4_4_4_A } from "./activity/4-4-4-A";
import { translation_4_4_5_A } from "./activity/4-4-5-A";
import { translation_4_4_6_A } from "./activity/4-4-6-A";
import { translation_4_5_1_A } from "./activity/4-5-1-A";
import { translation_4_5_2_A } from "./activity/4-5-2-A";
import { translation_4_5_3_A } from "./activity/4-5-3-A";
import { translation_4_5_4_A } from "./activity/4-5-4-A";
import { translation_4_5_5_A } from "./activity/4-5-5-A";
import { translation_4_5_6_A } from "./activity/4-5-6-A";
import { translation_4_6_A } from "./activity/4-6-A";
import { translation_4_7_A } from "./activity/4-7-A";
import { translation_4_8_A } from "./activity/4-8-A";
import { translation_4_9_A } from "./activity/4-9-A";
import { translation_5_1_A } from "./activity/5-1-A";
import { translation_5_2_A } from "./activity/5-2-A";
import { translation_5_3_A } from "./activity/5-3-A";
import { translation_5_4_A } from "./activity/5-4-A";
import { translation_5_5_A } from "./activity/5-5-A";
import { translation_5_6_A } from "./activity/5-6-A";
import { translation_5_7_T } from "./activity/5-7-T";
import { translation_6_1_A } from "./activity/6-1-A";
import { translation_6_10_A } from "./activity/6-10-A";
import { translation_6_11_A } from "./activity/6-11-A";
import { translation_6_12_A } from "./activity/6-12-A";
import { translation_6_13_A } from "./activity/6-13-A";
import { translation_6_2_A } from "./activity/6-2-A";
import { translation_6_3_A } from "./activity/6-3-A";
import { translation_6_4_A } from "./activity/6-4-A";
import { translation_6_5_A } from "./activity/6-5-A";
import { translation_6_6_A } from "./activity/6-6-A";
import { translation_6_7_A } from "./activity/6-7-A";

import { translation_6_8_A } from "./activity/6-8-A";
import { translation_6_9_A } from "./activity/6-9-A";

import { translation_6_97_A } from "./activity/6-97-A";
import { translation_6_98_A } from "./activity/6-98-A";
import { translation_6_99_A } from "./activity/6-99-A";
import { translation_7_1_A } from "./activity/7-1-A";
import { translation_7_3_A } from "./activity/7-3-A";
import { translation_7_4_A } from "./activity/7-4-A";
import { translation_7_5_A } from "./activity/7-5-A";
import { translation_7_6_A } from "./activity/7-6-A";
import { translation_7_7_A } from "./activity/7-7-A";
import { translation_7_8_A } from "./activity/7-8-A";
import { translation_7_9_A } from "./activity/7-9-A";
import { translation_8_1_A } from "./activity/8-1-A";
import { translation_8_10_A } from "./activity/8-10-A";
import { translation_8_2_A } from "./activity/8-2-A";
import { translation_8_3_A } from "./activity/8-3-A";
import { translation_8_4_A } from "./activity/8-4-A";
import { translation_8_5_A } from "./activity/8-5-A";
import { translation_8_6_A } from "./activity/8-6-A";
import { translation_8_8_A } from "./activity/8-8-A";
import { translation_8_9_A } from "./activity/8-9-A";
import { translation_9_1_A } from "./activity/9-1-A";
import { translation_9_10_A } from "./activity/9-10-A";
import { translation_9_12_A } from "./activity/9-12-A";
import { translation_9_13_A } from "./activity/9-13-A";
import { translation_9_2_A } from "./activity/9-2-A";
import { translation_9_3_A } from "./activity/9-3-A";
import { translation_9_4_A } from "./activity/9-4-A";
import { translation_9_6_A } from "./activity/9-6-A";
import { translation_9_8_A } from "./activity/9-8-A";
import { translation_meditationTool } from "./activity/meditationTool";
import { translation_selfCheck } from "./activity/selfCheck";
import { behavioralActivationResource } from "./behavioralActivation";
import { thinkingTrapResource } from "./thinkingTrap";
import { thoughtmapResource } from "./thoughtmap";

export const ko = {
  ui: {
    "생각과 거리두고\n가치로 나아가세요": "생각과 거리두고\n가치로 나아가세요",
    "안전하고 효과적인 비약물 인지치료,\n정신건강 전문 코치들이 이끕니다.":
      "안전하고 효과적인 비약물 인지치료,\n정신건강 전문 코치들이 이끕니다.",
    "과학에 기반한 인지치료 프로그램,\n전문가와 함께 우울과 불안을 해결하세요.":
      "과학에 기반한 인지치료 프로그램,\n전문가와 함께 우울과 불안을 해결하세요.",
    "생각과 거리두고\n우울증에서 벗어나세요":
      "생각과 거리두고\n우울증에서 벗어나세요",
    "초기 우울증을 위한 디지털 인지치료\n전문가와 함께 우울과 무기력을 해결합니다. ":
      "초기 우울증을 위한 디지털 인지치료\n전문가와 함께 우울과 무기력을 해결합니다. ",
    처음이에요: "처음이에요",
    "이미 코치를 배정받았어요": "이미 코치를 배정받았어요",
    로그인: "{{type}} 로그인",
    "로그인 완료": "로그인 완료",

    "사업자 정보": "사업자 정보",
    "(대) 서비스 이용약관": "서비스 이용약관",
    "(대) 개인정보 처리방침": "개인정보 처리방침",

    "서비스 이용약관": "서비스 이용약관",
    "개인정보 처리방침": "개인정보 처리방침",
    확인: "확인",

    "앱에서 진행해주세요": "앱에서 진행해주세요",
    "다른 계정으로 로그인하기": "다른 계정으로 로그인하기",

    "꾸준히 하는 활동": "꾸준히 하는 활동",
    "다음 할 활동": "다음 할 활동",
    "마친 활동": "마친 활동",

    "구독 관리": "구독 관리",
    로그아웃: "로그아웃",
    회원탈퇴: "회원탈퇴",
    "정말로 로그아웃할까요?": "정말로 로그아웃할까요?",
    "정말로 탈퇴할까요?": "정말로 탈퇴할까요?",
    "탈퇴하기 전에 이용 중인 구독을 먼저 취소해 주세요":
      "탈퇴하기 전에 이용 중인 구독을 먼저 취소해 주세요",
    "구독 취소하기": "구독 취소하기",
    "로그아웃 하기": "로그아웃 하기",
    탈퇴하기: "탈퇴하기",
    "문의사항은 여기로 보내주세요.": "문의사항은 여기로 보내주세요.",
    "사용 중인 닉네임을 알려주시면 더 빠르게 처리해 드릴게요.":
      "사용 중인 닉네임을 알려주시면 더 빠르게 처리해 드릴게요.",

    "주어진 활동을 모두 마쳤어요": "주어진 활동을 모두 마쳤어요",
    "좌측 사이드바에서 주어진 활동을 진행해주세요":
      "좌측 사이드바에서 주어진 활동을 진행해주세요",
    "코치 선생님이 확인 후에 메시지를 드릴 거예요.":
      "코치 선생님이 확인 후에 메시지를 드릴 거예요.",
    "'다음 할 활동' 목록에서 순서대로 하나씩 마쳐주세요.":
      "'다음 할 활동' 목록에서 순서대로 하나씩 마쳐주세요.",
    "첫 번째 활동을 마쳤어요": "첫 번째 활동을 마쳤어요",
    "잠시 뒤 코치 선생님이 배정될 거예요.":
      "잠시 뒤 코치 선생님이 배정될 거예요.",
    "사이드바 열기": "사이드바 열기",

    고민: "고민",
    목표: "목표",
    "요즈음 나를 괴롭히는 고민은?": "요즈음 나를 괴롭히는 고민은?",
    "내가 만들고 싶은 변화는?": "내가 만들고 싶은 변화는?",
    "진행 중": "진행 중",
    접기: "접기",
    "전체 진도 보기": "전체 진도 보기",
    마치기: "마치기",

    "검사 결과가 없어요.": "검사 결과가 없어요.",

    "알림 허용하기": "알림 허용하기",
    "알림 허용됨": "알림 허용됨",
    "음성 안내 재생하기": "음성 안내 재생하기",
    "이어서 재생하기": "이어서 재생하기",
    "리뷰 남기기": "리뷰 남기기",

    "새로운 기록 쓰기": "새로운 기록 쓰기",
    추가하기: "추가하기",

    "더 보기": "더 보기",
    "더 알아보기": "더 알아보기",
  },
  thoughtmap: {
    "핵심 경험": "핵심 경험",
    "핵심 믿음": "핵심 믿음",
    "중간 믿음": "중간 믿음",
    "대처 전략": "대처 전략",
    패턴: "패턴",
    "아래 핵심 믿음을 먼저 찾은 뒤에 핵심 경험을 찾아요.":
      "아래 핵심 믿음을 먼저 찾은 뒤에 핵심 경험을 찾아요.",
    "아래 대처 전략을 먼저 찾은 뒤에 핵심 믿음을 찾아요.":
      "아래 대처 전략을 먼저 찾은 뒤에 핵심 믿음을 찾아요.",
    "아래 자동적 사고를 먼저 찾은 뒤에 대처 전략을 찾아요.":
      "아래 자동적 사고를 먼저 찾은 뒤에 대처 전략을 찾아요.",
    "코치 선생님과 함께 찾아봐요.": "코치 선생님과 함께 찾아봐요.",
    "지금의 나를 만든 과거의 경험은?": "지금의 나를 만든 과거의 경험은?",
    "내 마음속 깊이 자리 잡은 생각은?": "내 마음속 깊이 자리 잡은 생각은?",
    "오늘날 내가 삶을 해쳐나가는 방식은?":
      "오늘날 내가 삶을 해쳐나가는 방식은?",
    "일상에서 내가 겪는 불편은?": "일상에서 내가 겪는 불편은?",
    "찾은 생각함정": "찾은 생각함정",
    ...thoughtmapResource.ko,
  },
  selfCheck: {
    우울: "우울",
    불안: "불안",
    스트레스: "스트레스",
    ...translation_selfCheck.ko.v1,
  },
  thinkingTrap: {
    ...thinkingTrapResource.ko,
  },
  task: {
    progress: {
      "패턴 찾아내기": "패턴 찾아내기",
      "일상에서 어떻게 불편함을 겪는지 알아봐요.":
        "일상에서 어떻게 불편함을 겪는지 알아봐요.",
      "생각 살펴보기": "생각 살펴보기",
      "정확하지 않거나 도움이 되지 않는 생각을 다뤄요.":
        "정확하지 않거나 도움이 되지 않는 생각을 다뤄요.",
      "기꺼이 하기": "기꺼이 하기",
      "나의 감정과 생각을 마주하고 그대로 경험해요.":
        "나의 감정과 생각을 마주하고 그대로 경험해요.",
      "뿌리 이해하기": "뿌리 이해하기",
      "나의 과거가 어떻게 오늘날을 만드는지 이해해요.":
        "나의 과거가 어떻게 오늘날을 만드는지 이해해요.",
      "가치대로 살기": "가치대로 살기",
      "나의 가치에 맞게 행동하며 풍성한 삶을 만들어요.":
        "나의 가치에 맞게 행동하며 풍성한 삶을 만들어요.",
    },
    title: {
      "생각 지도": "생각 지도",
      "생각 그물": "생각 그물",
      "가치 나침반": "가치 나침반",
      "진행 상황": "진행 상황",
      대시보드: "대시보드",
      "월 구독": "월 구독",
      "월 이용권": "월 이용권",
      채팅: "채팅",

      "디스턴싱 시작하기": "디스턴싱 시작하기", //0-0-A
      "생각과 거리두기": "생각과 거리두기", //0-4-A
      "디스턴싱, 내면의 세계로": "디스턴싱, 내면의 세계로",
      "나의 첫 번째 생각기록지": "나의 첫 번째 생각기록지", //0-7-A
      "부정적 반응 측정하기": "부정적 반응 측정하기", //0-1-A
      "이번 주 돌아보기": "이번 주 돌아보기", //0-3-A
      "목표 돌아보기": "목표 돌아보기", //0-5-A

      "나의 고민 알아보기": "나의 고민 알아보기",
      "나의 고민과 목표": "나의 고민과 목표", //1-1-A
      "프로그램 구성하기": "프로그램 구성하기", //1-2-A
      "생각과 팝콘과 나": "생각과 팝콘과 나", //1-3-A
      "효과적이지 않은 대처": "효과적이지 않은 대처", //1-4-A

      "생각기록지 작성 연습하기": "생각기록지 작성 연습하기", //2-1-A
      "꾸러미 분리하기": "꾸러미 분리하기", //2-3-A
      "분리 연습하기": "분리 연습하기", //2-4-A
      생각기록지: "생각기록지", //2-2-T

      "자동적인 생각": "자동적인 생각", //3-2-A
      "자동적 사고 살펴보기": "자동적 사고 살펴보기", //3-3-A

      생각함정: "생각함정", //4-1-A
      "다양한 관점에서 생각해보기": "다양한 관점에서 생각해보기", //4-2-A
      "정확하지 않은 생각들": "정확하지 않은 생각들", //4-3-1-A
      "도움이 되지 않는 생각들": "도움이 되지 않는 생각들", //4-3-2-A
      "생각함정 1. 책임 과다": "생각함정 1. 책임 과다", //4-4-1-A
      "생각함정 2. 근거 부족": "생각함정 2. 근거 부족", //4-4-2-A
      "생각함정 3. 부정 편향": "생각함정 3. 부정 편향", //4-4-3-A
      "생각함정 4. 평가": "생각함정 4. 평가", //4-4-4-A
      "생각함정 5. 개념화": "생각함정 5. 개념화", //4-4-5-A
      "생각함정 6. 반추": "생각함정 6. 반추", //4-4-6-A
      "책임 과다 다루기": "책임 과다 다루기", //4-5-1-A
      "근거 부족 다루기": "근거 부족 다루기", //4-5-2-A
      "부정 편향 다루기": "부정 편향 다루기", //4-5-3-A
      "평가 다루기": "평가 다루기", //4-5-4-A
      "개념화 다루기": "개념화 다루기", //4-5-5-A
      "반추 다루기": "반추 다루기", //4-5-6-A
      "생각과 행동": "생각과 행동", //4-6-A
      안전행동: "안전행동", //4-7-A
      "나만의 행동 실험실": "나만의 행동 실험실", //4-8-A
      "행동 실험 n단계 계획하기": "행동 실험 {{n}}단계 계획하기", //4-9-A
      "행동 실험 n단계 실행하기": "행동 실험 {{n}}단계 실행하기", //4-10-A
      "행동 실험실 마치기": "행동 실험실 마치기", //4-11-A

      "뇌의 재활훈련": "뇌의 재활훈련", //5-1-A
      "기꺼이 하기": "기꺼이 하기", //5-2-A
      "생각 바라보기": "생각 바라보기", //5-3-A
      "감정 바라보기": "감정 바라보기", //5-4-A
      "신체 감각 바라보기": "신체 감각 바라보기", //5-5-A
      "마음 바라보기": "마음 바라보기", //5-6-A
      명상활동지: "명상활동지", //5-7-T

      "생각 그물": "생각 그물", //6-1-A
      "중간 믿음": "중간 믿음", //6-2-A
      "중간 믿음 살펴보기": "중간 믿음 살펴보기", //6-3-A
      "핵심 믿음": "핵심 믿음", //6-4-A
      "핵심 믿음 찾아보기": "핵심 믿음 찾아보기", //6-5-A
      "핵심 믿음 살펴보기": "핵심 믿음 살펴보기", //6-6-A
      "핵심 경험": "핵심 경험", //6-7-A
      "핵심 경험 살펴보기": "핵심 경험 살펴보기", //6-8-A
      "대처 전략과 중간 믿음": "대처 전략과 중간 믿음", //6-10-A
      "생각 그물 이해하기": "생각 그물 이해하기", //6-9-A
      "생각 그물과 가치": "생각 그물과 가치", //6-11-A
      "생각 그물과 거리두기": "생각 그물과 거리두기", //6-12-A
      "생각과 거리두고 가치로 나아가기": "생각과 거리두고 가치로 나아가기", //6-13-A

      "핵심 경험 찾아보기": "핵심 경험 찾아보기", //6-97-A
      "대처 전략 살펴보기": "대처 전략 살펴보기", //6-98-A
      "대처 전략과 자동적 사고": "대처 전략과 자동적 사고", //6-99-A

      "긍정 기록 시작하기": "긍정 기록 시작하기", //7-1-A
      "나의 첫 번째 긍정 기록": "나의 첫 번째 긍정 기록", //7-2-A
      "삶의 방향 선택하기": "삶의 방향 선택하기", //7-3-A
      "가치 살펴보기": "가치 살펴보기", //7-4-A
      "가치 행동": "가치 행동", //7-5-A
      "가치 행동 설계하기": "가치 행동 설계하기", //7-6-A
      "가치 행동 n단계 계획하기": "가치 행동 {{n}}단계 계획하기", //7-7-A
      "가치 행동 n단계 실행하기": "가치 행동 {{n}}단계 실행하기", //7-8-A
      "가치 정리하기": "가치 정리하기", //7-9-A

      "생각과 다시 관계 맺기": "생각과 다시 관계 맺기", //8-1-A
      "첫 번째 명상 훈련": "첫 번째 명상 훈련", //8-2-A
      "레몬 맛 팝콘": "레몬 맛 팝콘", //8-10-A
      "두 번째 명상 훈련": "두 번째 명상 훈련", //8-3-A
      "세 번째 명상 훈련": "세 번째 명상 훈련", //8-4-A
      "네 번째 명상 훈련": "네 번째 명상 훈련", //8-5-A
      "훈련 루틴 만들기": "훈련 루틴 만들기", //8-6-A
      "감정과 신체 다스리기": "감정과 신체 다스리기", //8-8-A
      "생각에서 빠져나오기": "생각에서 빠져나오기", //8-9-A

      "무기력의 악순환": "무기력의 악순환", //100-1-A
      "행동 이해하기": "행동 이해하기", //101-1-1-A
      "활동 기록하기": "활동 기록하기", //101-1-2-A
      활동기록지: "활동기록지", //101-1-3-T

      "나의 악순환 알아보기": "나의 악순환 알아보기", //101-2-1-A
      "악순환 패턴 정리하기": "악순환 패턴 정리하기", //101-2-2-A
      "방향성 되찾기": "방향성 되찾기", //101-2-3-A
      "나의 가치 정하기": "나의 가치 정하기", //101-2-4-A
      "선순환 패턴 정리하기": "선순환 패턴 정리하기", //101-2-5-A

      "무기력 평가하기": "무기력 평가하기", //102-1-A

      "밖에서부터 안으로": "밖에서부터 안으로", //103-1-A
      "N번째 활동 - 고르기": "{{N}}번째 활동 - 고르기", //104-0-A
      "N번째 활동 - M단계 계획하기": "{{N}}번째 활동 - {{M}}단계 계획하기", //104-1-A
      "N번째 활동 - M단계 실행하기": "{{N}}번째 활동 - {{M}}단계 실행하기", //104-2-A

      명상: "명상",
    },

    activity: {
      common: {
        // lastCard1:
        // "이번 활동은 어땠나요? 의견을 편하게 말씀해주세요. 없다면 화살표를 눌러 다음으로 넘어가 주세요.",
        lastCard1:
          "이번 활동은 어땠나요? 느낀 점이나 어려웠던 점, 걱정되는 점을 편하게 말씀해주세요. 없다면 화살표를 눌러 다음으로 넘어가 주세요.",
        lastCard2: "활동을 마쳤어요. [마치기]를 눌러주세요",
        coachNextActionCard: "어떻게 진행할까요?",
        coachNextAction_option1: "아직 이야기 중이에요",
        coachNextAction_option2: "이번 단계를 한 번 더 해보기로 했어요",
        coachNextAction_option3: "다음 단계로 넘어가기로 했어요",
        coachNextAction_option4: "단계를 다시 짜기로 했어요",
        예시: "예시",
        "나의 고민": "나의 고민",
        고민: "고민",
        목표: "목표",
        날짜: "날짜",

        // 상황: "상황",
        // 생각: "생각",
        // "영향 정도": "영향 정도",
        // "감정/신체": "감정/신체",
        // 행동: "행동",
        // "자동적 사고": "자동적 사고",
        ...thoughtmapResource.ko,
        ...behavioralActivationResource.ko,
        $A$: "$A$",

        전혀: "전혀",
        며칠간: "며칠간",
        "7일 이상": "7일 이상",
        "거의 매일": "거의 매일",
        "거의 아님": "거의 아님",
        가끔: "가끔",
        자주: "자주",
        "매우 자주": "매우 자주",
        selfcheck_evaluation1_1: "전혀",
        selfcheck_evaluation1_2: "며칠간",
        selfcheck_evaluation1_3: "7일 이상",
        selfcheck_evaluation1_4: "거의 매일",

        selfcheck_evaluation2_1: "전혀",
        selfcheck_evaluation2_2: "거의 아님",
        selfcheck_evaluation2_3: "가끔",
        selfcheck_evaluation2_4: "자주",
        selfcheck_evaluation2_5: "매우 자주",

        selfcheck_evaluation3_1: "매우 아님",
        selfcheck_evaluation3_2: "아님",
        selfcheck_evaluation3_3: "그럼",
        selfcheck_evaluation3_4: "매우 그럼",

        selfcheck_evaluation4_1: "전혀",
        selfcheck_evaluation4_2: "거의 아님",
        selfcheck_evaluation4_3: "가끔",
        selfcheck_evaluation4_4: "자주",
        selfcheck_evaluation4_5: "항상",

        selfcheck_evaluation5_1: "전혀",
        selfcheck_evaluation5_2: "거의 아님",
        selfcheck_evaluation5_3: "가끔",
        selfcheck_evaluation5_4: "자주",
        selfcheck_evaluation5_5: "매우 자주",

        selfcheck_evaluation6_1: "전혀 아님",
        selfcheck_evaluation6_2: "아님",
        selfcheck_evaluation6_3: "보통",
        selfcheck_evaluation6_4: "그럼",
        selfcheck_evaluation6_5: "매우 그럼",

        요인: "요인",
        "나의 요인": "나의 요인",
        기여도: "기여도",
        "통제 가능 여부": "통제 가능 여부",
        "통제 가능": "통제 가능",
        "통제 불가능": "통제 불가능",
        근거: "근거",
        요소: "요소",
        "해당하는 근거": "해당하는 근거",
        "해당하지 않는 근거": "해당하지 않는 근거",
        "해당하는 정도": "해당하는 정도",
        "무력화 가능성": "무력화 가능성",
        확률: "확률",
        "다른 가능성": "다른 가능성",
        "최악의 가능성": "최악의 가능성",
        "대처 전략": "대처 전략",
        "도움 대상": "도움 대상",
        단계: "단계",
        "기술 (상황과 나의 직접적인 특성)": "기술 (상황과 나의 직접적인 특성)",
        "사실 (상황과 나의 직접적인 특성)": "사실 (상황과 나의 직접적인 특성)",
        "평가 (상황과 나에 대한 가치 판단)":
          "평가 (상황과 나에 대한 가치 판단)",
        "나의 평가": "나의 평가",
        "마음속에 떠오른 평가": "마음속에 떠오른 평가",
        "거리를 두고 바라본 평가": "거리를 두고 바라본 평가",
        "나의 개념화": "나의 개념화",
        "마음속에 떠오른 개념화": "마음속에 떠오른 개념화",
        "거리두기를 통해 바라본 개념화": "거리두기를 통해 바라본 개념화",
        "나의 반추": "나의 반추",
        "마음속에 떠오른 반추": "마음속에 떠오른 반추",
        "거리두기를 통해 바라본 반추": "거리두기를 통해 바라본 반추",

        "그 사람이 겪을 상황": "그 사람이 겪을 상황",
        "그 사람의 마음속에 떠오를 것 같은 생각":
          "그 사람의 마음속에 떠오를 것 같은 생각",
        "그 사람의 상황": "그 사람의 상황",
        "그 사람의 생각": "그 사람의 생각",
        "생각에 반응했을 때의 이점": "생각에 반응했을 때의 이점",
        "생각에 반응했을 때의 손해": "생각에 반응했을 때의 손해",
        이점: "이점",
        손해: "손해",
        생각함정: "생각함정",
        반증: "반증",
        "사실이라는 근거": "사실이라는 근거",
        "사실이 아니라는 근거": "사실이 아니라는 근거",
        "기술 (경험에 대한 사실)": "기술 (경험에 대한 사실)",
        "평가 (경험에 대한 가치 판단)": "평가 (경험에 대한 가치 판단)",
        "개념화 (정당화하기 위한 이유)": "개념화 (정당화하기 위한 이유)",
        "거리두기 후 영향 정도": "거리두기 후 영향 정도",

        "MMM D일 ddd요일": "MMM D일 ddd요일",

        굴복: "굴복",
        과잉보상: "과잉보상",
        회피: "회피",

        규칙: "규칙",
        태도: "태도",
        가정: "가정",

        "핵심 믿음": "핵심 믿음",
        나: "나",
        타인: "타인",
        세상: "세상",

        영역: "영역",
        가치: "가치",
        "가치 행동": "가치 행동",
        "느낀 점": "느낀 점",
        "어려웠던 점": "어려웠던 점",
        "앞으로의 기대": "앞으로의 기대",

        "스스로에 대해": "스스로에 대해",
        "다른 사람에 대해": "다른 사람에 대해",
        "다른 사람들에 대해": "다른 사람에 대해",
        "이 세상에 대해": "이 세상에 대해",

        "영향 정도 placeholder": "0: 전혀, 100: 삶이 변화할 정도",
        "소감 placeholder": "코치 선생님에게 채팅으로 전달돼요",

        "상황/사건": "상황/사건",
        "단기적 이점": "단기적 이점",
        "장기적 단점": "장기적 단점",
        "장기적 이점": "장기적 이점",
        "예상 난이도": "예상 난이도",
        누구와: "누구와",
        무엇을: "무엇을",
        어디서: "어디서",
        언제: "언제",
        "예상 장애물": "예상 장애물",
        "장애물 극복법": "장애물 극복법",

        결과: "결과",
        가설: "가설",
        "실험 제목": "실험 제목",
        "실험 가설": "실험 가설",
        "실험 내용": "실험 내용",
        "가설이 맞다면": "가설이 맞다면",
        "가설이 틀렸다면": "가설이 틀렸다면",
        "단계 실험 내용": "단계 실험 내용",
        안전행동: "안전행동",
        대처방안: "대처방안",
        어려움: "어려움",
        "단계 실험 결과": "단계 실험 결과",
        "자동적 사고 영향 정도 변화": "자동적 사고 영향 정도 변화",
        "실험 실행 여부": "실험 실행 여부",
        "가설 일치 여부": "가설 일치 여부",
      },
      "0-0-A": translation_0_0_A.ko,
      "0-4-A": translation_0_4_A.ko,
      "0-1-A": translation_0_1_A.ko,
      "0-2-A": translation_0_2_A.ko,
      "0-3-A": translation_0_3_A.ko,
      "0-5-A": translation_0_5_A.ko,
      "0-6-A": translation_0_6_A.ko,

      "1-1-A": translation_1_1_A.ko,
      "1-2-A": translation_1_2_A.ko,

      "2-1-A": translation_2_1_A.ko,
      "2-3-A": translation_2_3_A.ko,
      "2-4-A": translation_2_4_A.ko,
      "2-2-T": translation_2_2_T.ko,

      "1-3-A": translation_1_3_A.ko,
      "3-2-A": translation_3_2_A.ko,

      ...automaticThoughtIdList
        .map((each) => ({
          [`3-3-A_${each}`]: translation_3_3_A.ko,
          [`4-2-A_${each}`]: translation_4_2_A.ko,
          [`4-5-1-A_${each}`]: translation_4_5_1_A.ko,
          [`4-5-2-A_${each}`]: translation_4_5_2_A.ko,
          [`4-5-3-A_${each}`]: translation_4_5_3_A.ko,
          [`4-5-4-A_${each}`]: translation_4_5_4_A.ko,
          [`4-5-5-A_${each}`]: translation_4_5_5_A.ko,
          [`4-5-6-A_${each}`]: translation_4_5_6_A.ko,
          [`4-8-A_${each}`]: translation_4_8_A.ko,
          ...[1, 2, 3, 4, 5]
            .map((N) => ({
              [`4-9-A_${each}#${N}`]: translation_4_9_A.ko,
              [`4-10-A_${each}#${N}`]: translation_4_10_A.ko,
              [`4-11-A_${each}`]: translation_4_11_A.ko,
              [`7-7-A_${each}#${N}`]: translation_7_7_A.ko,
              [`7-8-A_${each}#${N}`]: translation_7_8_A.ko,
            }))
            .reduce(function (acc, obj) {
              // Object.keys(obj)를 사용하여 객체의 키를 가져옵니다.
              var keys = Object.keys(obj);

              // 각 키에 대해 acc 객체에 키와 값을 추가합니다.
              keys.forEach(function (key) {
                acc[key] = obj[key];
              });

              return acc;
            }, {}),
          [`5-3-A_${each}`]: translation_5_3_A.ko,
          [`5-4-A_${each}`]: translation_5_4_A.ko,
          [`5-5-A_${each}`]: translation_5_5_A.ko,
          [`5-6-A_${each}`]: translation_5_6_A.ko,
          [`6-12-A_${each}`]: translation_6_12_A.ko,
          [`7-4-A_${each}`]: translation_7_4_A.ko,
          [`7-6-A_${each}`]: translation_7_6_A.ko,
          [`7-9-A_${each}`]: translation_7_9_A.ko,
        }))
        .reduce(function (acc, obj) {
          // Object.keys(obj)를 사용하여 객체의 키를 가져옵니다.
          var keys = Object.keys(obj);

          // 각 키에 대해 acc 객체에 키와 값을 추가합니다.
          keys.forEach(function (key) {
            acc[key] = obj[key];
          });

          return acc;
        }, {}),

      "4-1-A": translation_4_1_A.ko,
      "4-3-1-A": translation_4_3_1_A.ko,
      "4-3-2-A": translation_4_3_2_A.ko,

      "4-4-1-A": translation_4_4_1_A.ko,
      "4-4-2-A": translation_4_4_2_A.ko,
      "4-4-3-A": translation_4_4_3_A.ko,
      "4-4-4-A": translation_4_4_4_A.ko,
      "4-4-5-A": translation_4_4_5_A.ko,
      "4-4-6-A": translation_4_4_6_A.ko,
      "4-6-A": translation_4_6_A.ko,
      "4-7-A": translation_4_7_A.ko,

      "5-1-A": translation_5_1_A.ko,
      "5-2-A": translation_5_2_A.ko,
      "5-7-T": translation_5_7_T.ko,

      "6-1-A": translation_6_1_A.ko,
      "6-2-A": translation_6_2_A.ko,
      "6-3-A": translation_6_3_A.ko,

      "6-4-A": translation_6_4_A.ko,
      "6-5-A": translation_6_5_A.ko,
      "6-6-A": translation_6_6_A.ko,
      "6-7-A": translation_6_7_A.ko,
      "6-8-A": translation_6_8_A.ko,
      "6-10-A": translation_6_10_A.ko,
      "6-9-A": translation_6_9_A.ko,
      "6-11-A": translation_6_11_A.ko,
      "6-13-A": translation_6_13_A.ko,
      "6-97-A": translation_6_97_A.ko,
      "6-98-A": translation_6_98_A.ko,
      "6-99-A": translation_6_99_A.ko,

      "7-1-A": translation_7_1_A.ko,
      "7-3-A": translation_7_3_A.ko,
      "7-5-A": translation_7_5_A.ko,

      "8-1-A": translation_8_1_A.ko,
      "8-2-A": translation_8_2_A.ko,
      "8-3-A": translation_8_3_A.ko,
      "8-4-A": translation_8_4_A.ko,
      "8-5-A": translation_8_5_A.ko,
      "8-6-A": translation_8_6_A.ko,
      "8-8-A": translation_8_8_A.ko,
      "8-9-A": translation_8_9_A.ko,
      "8-10-A": translation_8_10_A.ko,

      "9-1-A": translation_9_1_A.ko,
      "9-2-A": translation_9_2_A.ko,
      "9-3-A": translation_9_3_A.ko,
      "9-4-A": translation_9_4_A.ko,
      "9-6-A": translation_9_6_A.ko,
      "9-8-A": translation_9_8_A.ko,
      "9-10-A": translation_9_10_A.ko,
      "9-12-A": translation_9_12_A.ko,
      "9-13-A": translation_9_13_A.ko,

      "100-0-A": translation_100_0_A.ko,
      "100-1-A": translation_100_1_A.ko,
      "101-1-1-A": translation_101_1_1_A.ko,
      "101-1-2-A": translation_101_1_2_A.ko,
      "101-1-3-T": translation_101_1_3_T.ko,

      "101-2-1-A": translation_101_2_1_A.ko,
      "101-2-2-A": translation_101_2_2_A.ko,
      "101-2-3-A": translation_101_2_3_A.ko,
      "101-2-4-A": translation_101_2_4_A.ko,
      "101-2-5-A": translation_101_2_5_A.ko,

      "102-1-A": translation_102_1_A.ko,
      "102-2-A": translation_102_2_A.ko,

      "103-1-A": translation_103_1_A.ko,

      ...automaticThoughtIdList
        .map((N) => ({
          [`104-0-A#${N}`]: translation_104_0_A.ko,
          ...Array.from({ length: 5 }, (v, j) => j + 1)
            .map((M) => ({
              [`104-1-A#${N}#${M}`]: translation_104_1_A.ko,
              [`104-2-A#${N}#${M}`]: translation_104_2_A.ko,
            }))
            .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
        }))
        .reduce(function (acc, obj) {
          // Object.keys(obj)를 사용하여 객체의 키를 가져옵니다.
          var keys = Object.keys(obj);

          // 각 키에 대해 acc 객체에 키와 값을 추가합니다.
          keys.forEach(function (key) {
            acc[key] = obj[key];
          });

          return acc;
        }, {}),

      meditationTool: translation_meditationTool.ko,
    },
  },
  payment: {
    "n일 뒤 구독이 만료되어요": "{{expiryDaysLeft}}일 뒤 구독이 만료되어요",
    "구독을 유지하고 프로그램을 이어가세요.":
      "구독을 유지하고 프로그램을 이어가세요.",
    "구독이 만료되었어요": "구독이 만료되었어요",
    "다시 구독하고 프로그램을 이어가세요.":
      "다시 구독하고 프로그램을 이어가세요.",
    "구독하고 이용해주세요": "구독하고 이용해주세요",
    "코치 선생님과 프로그램을 시작하세요.":
      "코치 선생님과 프로그램을 시작하세요.",
    "결제 후 이용해주세요": "결제 후 이용해주세요",
    "스스로를 깊게 이해해보세요": "스스로를 깊게 이해해보세요",
    "변화를 꾸준히 트래킹하세요": "변화를 꾸준히 트래킹하세요",
    "나만의 프로그램을 구성하세요": "나만의 프로그램을 구성하세요",
    "7일 무료 체험": "7일 무료 체험",
    "월별 결제, 언제든 취소 가능": "월별 결제, 언제든 취소 가능",
    "더 알아보기": "더 알아보기",

    "무료 체험 시작하기": "무료 체험 시작하기",
    "구독 시작하기": "구독 시작하기",
    "계속 이용하기": "계속 이용하기",
    "7일의 무료 체험 후 구독이 자동으로 시작됩니다.":
      "7일의 무료 체험 후 구독이 자동으로 시작됩니다.",
    "7일의 무료 체험 후 월 이용권이 자동으로 갱신됩니다.":
      "7일의 무료 체험 후 월 이용권이 자동으로 갱신됩니다.",
    "생각과 거리두고\n가치로 나아가세요": "생각과 거리두고\n가치로 나아가세요",
    "생각과 거리두고\n우울증에서 벗어나세요":
      "생각과 거리두고\n우울증에서 벗어나세요",
    "디스턴싱은 우리 삶을\n변화시킵니다": "디스턴싱은 우리 삶을\n변화시킵니다",
    "이제 당신 차례입니다": "{{nickname}}님,\n이제 당신 차례입니다",

    "월 n원": "월 {{amount}}원",
    "한 달 이용권 n원": "한 달 이용권 {{amount}}원",
    "첫 달은 50% 할인된 가격에 이용하세요":
      "첫 달은 50% 할인된 가격에 이용하세요",
    "한 달 동안 모든 프로그램을 무제한으로 이용하세요":
      "한 달 동안 모든 프로그램을 무제한으로 이용하세요",
    "코치와 함께 프로그램을 진행하세요": "코치와 함께 프로그램을 진행하세요",

    "부담없는 월별 결제": "부담없는 월별 결제",
    "월별 결제": "월별 결제",
    "정신건강 전문 코치 선생님의 리딩": "정신건강 전문 코치 선생님의 리딩",
    "정신건강 전문 AI 코치의 리딩": "정신건강 전문 AI 코치의 리딩",
    "전문 코치 선생님": "전문 코치 선생님",
    "전문 AI 코치": "전문 AI 코치",
    "스스로 우울증을 해결하는 지속가능한 관리 도구":
      "스스로 우울증을 해결하는 지속가능한 관리 도구",
    "지속가능한 관리 도구": "지속가능한 관리 도구",
    "체계적인 인지치료 프로그램": "체계적인 인지치료 프로그램",
    "체계적인 인지치료": "체계적인 인지치료",

    "스스로를 이해하고 한결 나에게 여유로워졌어요. 이제는 마음이 설레는 일도 시작하려고요.":
      "스스로를 이해하고 한결 나에게 여유로워졌어요. 이제는 마음이 설레는 일도 시작하려고요.",
    "코트니, 대학생": "코트니, 대학생",
    "편안한 나로 있는 방법을 터득했어요. 있는 그대로의 나의 모습을 보여주니 회사 사람들과의 인간관계도 편해졌어요.":
      "편안한 나로 있는 방법을 터득했어요. 있는 그대로의 나의 모습을 보여주니 회사 사람들과의 인간관계도 편해졌어요.",
    "보나, 사무직 근로자": "보나, 사무직 근로자",
    "하고 싶으면 하고, 가고 싶으면 가고, 먹고 싶으면 먹는 삶이 이렇게나 자유롭고 행복할 수 있다는 걸 알게 되어서 정말 기뻐요.":
      "하고 싶으면 하고, 가고 싶으면 가고, 먹고 싶으면 먹는 삶이 이렇게나 자유롭고 행복할 수 있다는 걸 알게 되어서 정말 기뻐요.",
    "허니비, 기업 교육 전문가": "허니비, 기업 교육 전문가",
    "끊임없는 걱정의 악순환에서 벗어나니, 그 에너지를 더 소중한 일들에 투자할 수 있게 되었어요.":
      "끊임없는 걱정의 악순환에서 벗어나니, 그 에너지를 더 소중한 일들에 투자할 수 있게 되었어요.",
    "도토리, SW 개발자": "도토리, SW 개발자",
    "현재의 순간에서 기쁨을 알아차리는 법과, 타인의 인정에서 벗어나 스스로를 사랑하고 돌보는 법을 알게 되었어요.":
      "현재의 순간에서 기쁨을 알아차리는 법과, 타인의 인정에서 벗어나 스스로를 사랑하고 돌보는 법을 알게 되었어요.",
    "나다운, 브랜드 마케터": "나다운, 브랜드 마케터",
    "나쁜 평가를 두려워하며 아예 시작도 안하던 일들에 이제는 자신있게 도전하고 있어요.":
      "나쁜 평가를 두려워하며 아예 시작도 안하던 일들에 이제는 자신있게 도전하고 있어요.",
    "냠냠, 인사 관리자": "냠냠, 인사 관리자",

    디스턴싱: "디스턴싱",
    "상담 치료": "상담 치료",
    "약물 치료": "약물 치료",
    "예약 없는 이용": "예약 없는 이용",
    "비밀 보장": "비밀 보장",
    "데일리 관리": "데일리 관리",
    "유연한 개인 대응": "유연한 개인 대응",
    "최신 인지과학 기반": "최신 인지과학 기반",
    "일상에서의 실습": "일상에서의 실습",
    "약물 미사용": "약물 미사용",
    "종결 이후 관리": "종결 이후 관리",

    "전체 기간은 얼마나 되나요?": "전체 기간은 얼마나 되나요?",
    "프로그램을 마치기까지 보통 3개월 정도 걸려요. 사람마다 다를 수는 있어요.":
      "프로그램을 마치기까지 보통 3개월 정도 걸려요. 사람마다 다를 수는 있어요.",
    "코치 선생님이 도와준다고요?": "코치 선생님이 도와준다고요?",
    "AI 코치가 도와준다고요?": "AI 코치가 도와준다고요?",
    "코치는 매일 해야할 활동을 안내하고 프로그램을 리드해 나갈 거예요. 코치는 전문 수련 과정을 마친 심리치료 전문가로 구성되어 있어요. 코치와는 익명으로 프로그램 진행이 가능해요.":
      "코치는 매일 해야할 활동을 안내하고 프로그램을 리드해 나갈 거예요. 코치는 전문 수련 과정을 마친 심리치료 전문가로 구성되어 있어요. 코치와는 익명으로 프로그램 진행이 가능해요.",
    "코치는 매일 해야할 활동을 안내하고 프로그램을 리드해 나갈 거예요. 코치는 디스턴싱 코칭 과정을 학습한 AI로 구성되어 있어요. 코치와는 익명으로 프로그램 진행이 가능해요.":
      "코치는 매일 해야할 활동을 안내하고 프로그램을 리드해 나갈 거예요. 코치는 디스턴싱 코칭 과정을 학습한 AI로 구성되어 있어요. 코치와는 익명으로 프로그램 진행이 가능해요.",
    "누가 도움 받을 수 있나요?": "누가 도움 받을 수 있나요?",
    "우울함, 수치심, 무기력, 폭식을 비롯한 식이장애, 불안감, 강박적인 걱정, 공황장애, 사회공포증, 발표불안, 자기 파괴적 완벽주의, 업무에 대한 부담감, 수면 문제 그리고 이외 복합적인 정서 문제에 도움이 될 수 있어요.":
      "우울함, 수치심, 무기력, 폭식을 비롯한 식이장애, 불안감, 강박적인 걱정, 공황장애, 사회공포증, 발표불안, 자기 파괴적 완벽주의, 업무에 대한 부담감, 수면 문제 그리고 이외 복합적인 정서 문제에 도움이 될 수 있어요.",
    "환청, 환각, 자살사고 등 시급한 의료적 접근이 필요한 분들의 경우 당장의 도움이 될 수 없어요. 단, 의료적 접근을 통해 위 문제들이 안정화가 된 상태에서는 보다 장기적이고 지속적인 변화를 위해 디스턴싱을 시작해봐도 괜찮아요.":
      "환청, 환각, 자살사고 등 시급한 의료적 접근이 필요한 분들의 경우 당장의 도움이 될 수 없어요. 단, 의료적 접근을 통해 위 문제들이 안정화가 된 상태에서는 보다 장기적이고 지속적인 변화를 위해 디스턴싱을 시작해봐도 괜찮아요.",
    "이런 경우 환불해드려요": "이런 경우 환불해드려요",
    "결제 후 서비스를 이용하지 않은 경우 언제든 환불이 가능해요. 결제 후 1회 이상 서비스를 이용한 경우 환불 금액은 판매자에게 문의해주세요.":
      "결제 후 서비스를 이용하지 않은 경우 언제든 환불이 가능해요. 결제 후 1회 이상 서비스를 이용한 경우 환불 금액은 판매자에게 문의해주세요.",
    "서버 문제, 개발 오류 등 회사의 사정으로 인해 일주일을 초과하는 기간 동안 서비스가 원활하게 전개되지 않는 경우에는, 문제 발생 21일 전부터 문제 발생 시점까지 진행된 결제 건에 대해 모두 환불해드려요.":
      "서버 문제, 개발 오류 등 회사의 사정으로 인해 일주일을 초과하는 기간 동안 서비스가 원활하게 전개되지 않는 경우에는, 문제 발생 21일 전부터 문제 발생 시점까지 진행된 결제 건에 대해 모두 환불해드려요.",
    "자세한 규정이 궁금해요": "자세한 규정이 궁금해요",
    "자세한 내용은 서비스 이용약관과 개인정보 처리방침에서 확인해주세요.":
      "자세한 내용은 서비스 이용약관과 개인정보 처리방침에서 확인해주세요.",
  },
};

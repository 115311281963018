export const translation_6_10_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `자, 이제부터 나의 마음속에서 생각 그물이 어떤 영향을 미치고 있는지 이해해 볼 텐데요.`,
      typography_1: `이를 위해서는 ‘중간 믿음'의 역할을 잘 이해하는 게 특히 중요하답니다.`,
      typography_2: `앞서 중간 믿음이란, '핵심 믿음의 위협에 대처하기 위해 만든 규칙, 태도, 가정'이 중간 믿음이라고 말씀드렸는데요.`,
      typography_3: `여기서 ‘핵심 믿음의 위협에 대처한다'는 것이 특히 중요해요.`,
      typography_4: `조금 더 자세하게 알아볼게요.`,
      typography_5: `대처 전략`,
      typography_6: `핵심 믿음은 나를 괴롭게 하는 생각이에요.`,
      typography_7: `무서운 괴물과도 같죠.`,
      typography_8: `인간은 위협적인 대상을 만나면 흔히 다음 중 하나로 대처한답니다: 싸우기, 도망치기, 얼어붙기`,
      typography_9: `핵심 믿음에 대해서도 마찬가지랍니다.`,
      typography_10: `우리는 핵심 믿음과 싸우거나, 그것으로부터 도망치거나, 아니면 아무것도 못 하고 얼어붙죠.`,
      typography_11: `중간 믿음은 핵심 믿음에 대한 대처 전략과 같답니다.`,
      typography_12: `핵심 믿음에 대한 대처 전략으로서 중간 믿음이 만들어지는 것이죠.`,
      typography_13: `내 마음속에 그러한 대처 전략이 있다는 건 사실 당연하답니다.`,
      typography_14: `그렇게 괴로운 핵심 믿음이 자리 잡고 있다면 누구든 마음속에 그럴싸한 대처 전략을 품고 살아갈 거예요.`,
      typography_15: `하지만 문제는 이 대처 전략이 효과가 없다는 것이에요.`,
      typography_16: `핵심 믿음에 대한 대처 전략으로 만들어진 중간 믿음은`,
      typography_17: `부정적인 자동적 사고를 만들어내고,`,
      typography_18: `이 부정적인 사고는 다시 핵심 믿음을 강화하는 악순환이 발생한답니다.`,
      typography_19: `하나씩 예를 들어볼게요.`,
      typography_20: `과잉보상: 싸우기`,
      typography_21: `첫 번째 전략은 맞서 싸우는 것이에요.`,
      typography_22: `“나는 무능하다”라는 핵심 믿음이 있는 사람이 있다고 생각해 볼게요.`,
      typography_23: `어떤 사람은 이 믿음과 싸워서 이기고자 노력해요.`,
      typography_24: `끊임없이 “나는 유능하다”라는 것을 스스로에게 주입하고 남들에게 내세우고자 노력하죠.`,
      typography_25: `이러한 전략을 과잉보상이라고 하는데요.`,
      typography_26: `과잉보상을 주된 전략으로 사용하는 사람들은 흔히 다음과 같은 특성을 보여요.`,
      typography_26_1: `- 자신이 우월한 위치를 차지하도록 상황을 조종함
      - 특권과 지위를 획득하는 데에 에너지를 쏟음
      - 남을 착취함
      - 자신이 가장 중요한 사람이라고 여김
      - 거절을 받으면 화냄
      - 나를 비난할 것이라 예상되는 사람들의 공격에 맞서 싸우는 데에 에너지를 쏟음
      - 비난, 평가절하, 모욕을 당하지 않기 위해 보상적인 행동을 함(예: 워커홀릭, 무리한 노력 등)
      - 완벽해지는 데 너무 골몰한 나머지 그 과정에서 상처 입은 사람들을 배려하지 못함
      - 타인을 신뢰하고 연대하는 능력을 잃음
      - 실패를 어떻게 다루는지 알지 못함`,
      typography_27: `과잉보상을 대처 전략으로 사용하는 사람들은 다음과 같은 중간 믿음을 가질 수 있을 거예요.`,
      typography_27_1: `- 규칙: 완벽하게 해야 한다
      - 태도: 실수란 용납할 수 없다
      - 가정: 좋은 직업을 가지지 못하면 실패한 인생이 된다`,
      typography_28: `가끔은 이 전략이 효과적으로 보일 때도 있답니다. 성과를 보이기도 하죠.`,
      typography_29: `하지만 아무리 완벽하게 노력해도 결국에는 무언가에 실패하게 되어있어요.`,
      typography_30: `그때 과잉보상으로 쌓아 올린 내면은 와르르 무너진답니다.`,
      typography_31: `실패를 어떻게 다루는지 알지 못하고,`,
      typography_32: `실패에 대한 책임도 느끼지 못하고,`,
      typography_33: `자신의 한계를 인정하지 못하죠.`,
      typography_34: `그러다가 전면적인 역풍이 불어오면 이 싸우는 행위도 움츠러들게 된답니다.`,
      typography_35: `이런 상황이 오면 이제 매우 깊은 우울감에 빠져버려요.`,
      typography_36: `회피: 도망치기`,
      typography_37: `두 번째 전략은 도망치는 것이에요.`,
      typography_38: `자신의 믿음을 마주할 가능성을 애초에 차단하는 것이죠.`,
      typography_39: `“나는 무능하다”라는 것을 확인할 수 있는 상황을 만들지 않는 거예요.`,
      typography_40: `이러한 전략을 회피라고 하는데요.`,
      typography_41: `회피를 주된 전략으로 사용하는 사람들은 흔히 다음과 같은 특성을 보여요.`,
      typography_41_1: `- 자신의 감정을 회피, 또는 희미하게만 느낌
      - 감정이 자극될 때 그것을 약화해 버림
      - 부정적인 감정을 느낄 때 약물을 과다복용하거나, 강박적인 행동에 집중하거나, 일에 중독됨
      - 취약하거나 민감한 영역은 피함
      - 과제, 승진, 또는 새로운 프로젝트를 끊임없이 미루거나 피함
      - 집단, 파티, 회의 등을 피함
      - 독립적인 능력을 요구하는 모든 상황을 피함
      - 정서적 생활을 포기하여 아무것도 느끼지 못하는 멍한 상태로 지냄`,
      typography_42: `회피를 대처 전략으로 사용하는 사람들은 다음과 같은 중간 믿음을 가질 수 있을 거예요.`,
      typography_42_1: `- 규칙: 실패해서는 안 된다 (그러니 결국 아예 시작도 안 한다)
      - 태도: 남의 눈에 띄어서 좋을 것 없다 (그러니 결국 모든 교류를 차단한다)
      - 가정: 치료를 받으면 힘들어지기만 한다 (그러니 결국 생각과 감정을 마주하지 않는다)`,
      typography_43: `이 전략도 효과적으로 보일 때가 있답니다. 단기적으로는 문제가 발생하지 않으니까요.`,
      typography_44: `하지만 회피에 의존할수록 삶의 레퍼토리는 점점 더 좁아지고,`,
      typography_45: `또 스스로는 깨닫지 못하지만 자신의 내면 또한 점점 더 병들어간답니다.`,
      typography_46: `“내 삶은 왜 이런 걸까?”, “어쩌다가 이렇게 됐지?”, “삶에 아무런 의미가 없어.”`,
      typography_47: `결국 깊은 우울과 불안에 빠져버려요.`,
      typography_48: `굴복: 얼어붙기`,
      typography_49: `세 번째 전략은 그냥 얼어붙어 버리는 것이에요.`,
      typography_50: `싸우지도, 피하지도 않고, 무기력하게 순응하는 것이죠.`,
      typography_51: `변화의 희망을 가지지 않고, 포기하고, 수동적으로 따르면 오히려 마음은 더 편할지도 모른답니다.`,
      typography_52: `낙심하고 체념하는 것도 어느 정도는 수용하는 것처럼 보이기도 하니까요.`,
      typography_53: `이러한 전략을 굴복이라고 하는데요.`,
      typography_54: `굴복을 주된 전략으로 사용하는 사람들은 흔히 다음과 같은 특성을 보여요.`,
      typography_54_1: `- 자신이 결함투성이라고 생각하고, 느끼고, 행동함
      - 사람들이 나를 공격하고 모욕한다고 느낌
      - 자아의 긍정적인 면을 축소하고 부정적인 면을 강조함
      - 삶에서 마주하는 사건들이 자신의 결함을 증명한다고 해석함
      - 오히려 나의 결함을 부각하는 파트너를 선택함
      - 나의 결함을 부각하는 상황에 무의식적으로 끌림`,
      typography_55: `굴복을 대처 전략으로 사용하는 사람들은 다음과 같은 중간 믿음을 가질 수 있을 거예요.`,
      typography_55_1: `- 규칙: 절대 상대를 기분 나쁘게 해서는 안 된다
      - 태도: 스스로가 바꿀 수 있는 일은 거의 없다
      - 가정: 엄마의 말을 거역하면 천벌 받는다`,
      typography_56: `이 전략조차 단기적으로는 효과적으로 보일 수 있을 거예요.`,
      typography_57: `굴복하고 따르는 게 마음 편할 수도 있으니까요.`,
      typography_58: `하지만 역시 그 과정에서 내면은 끊임없이 병들고 있답니다.`,
      typography_59: `굴복에 의존하다 보면 빠져나올 수 없는 낮은 자존감의 덫에 걸리게 될 거예요.`,
      typography_60: `대처 전략으로서 중간 믿음`,
      typography_61: `과잉보상, 회피, 굴복. 총 세 가지 종류의 대처 전략을 알아보았는데요.`,
      typography_62: `아마 나의 중간 믿음에도 다양한 대처 전략이 포함되어 있을 거예요.`,
      typography_63: `모든 사람이 자신의 핵심 믿음에 대해 하나의 대처 전략으로만 일관되게 반응하는 건 아니랍니다.`,
      typography_64: `하지만 나의 중간 믿음을 가만히 바라보면 나는 주로 어떤 전략을 이용해 심리적 사건에 대처하고 있는지 알 수 있을 거예요.`,
      typography_65: `이를 이해하고 나면 핵심 믿음부터 중간 믿음, 그리고 자동적 사고까지, 즉, 생각 그물의 연결고리가 명확해진답니다.`,
      typography_66: `자, 그러면 이제 내 마음의 전체적인 큰 그림을 알아볼까요?`,
    },
  },
};

export const translation_3_3_A = {
  en: {
    v1: {
      네: "Yes",
      아니요: "No",
      typography_0_0_0:
        "Remember how we talked about automatic thoughts? They are the ones that come to mind almost unconsciously in any situation.",
      typography_1_0_0:
        "Today, we're going to chat about the automatic thoughts we identified while using the <Thought Log>.",
      typography_2_0_0:
        "Specifically, we'll look at the automatic thoughts that really bother us or clash with our values.",
      typography_3_0_0:
        "Here's a recap of our recent exchange in the <Thought Log>. Shall we read it carefully?",
      typography_4_0_0: "Please select a log to recall.",
      typography_5_0_0: "Okay, let's summarize what we've just read.",
      typography_6_0_0:
        "Does this sound like a recent problem that has been troubling you?",
      buttongroup_6_2_0_option0: "Yes",
      buttongroup_6_2_0_option1: "No",
      typography_7_0_0: "Where do you feel it's lacking?",
      typography_8_0_0:
        "Okay, let's stop here for today. I'll review and get back to you soon.",
      typography_9_0_0:
        "Great, we've pinpointed an automatic thought. It's like uncovering a piece of the puzzle in our thought map. You can check out how the thought map is coming together in <My Thought Map>.",
      typographyFromData_10_0_0: `Does the automatic thought, "$A$", seem to recur often?`,
      typography_11_0_0: "How much impact is this thought having on you?",
      typography_12_0_0:
        "Would you like to add anything more about this thought? If there's more you want to explain or add, feel free to share.",
      typography_13_0_0:
        "We'll be looking more into the thought mentioned above. Any worries about that?",
      typography_13_0_0_1: "What's on your mind?",
      typography_14_0_0:
        "Great. Based on our conversation today, we'll tailor the program moving forward. Thanks for your effort today.",
    },
  },
  ko: {
    v1: {
      네: "네",
      아니요: "아니요",
      typography_0_0_0:
        "자동적 사고란 어떠한 상황 속에서 거의 무의식적으로 떠오르는 생각이라는 것, 기억하시죠?",
      typography_1_0_0:
        "<생각기록지>에서 이야기 나누며 발견한 자동적 사고에 대해 얘기해 볼 거예요.",
      typography_2_0_0:
        "그 중에서도 특히 나를 괴롭게 하거나, 나의 가치에 반하는 자동적 사고에 대해 살펴볼 거랍니다.",
      typography_3_0_0:
        "최근에 <생각기록지>에서 우리가 나눈 문답이에요. 한 번 찬찬히 읽어볼까요?",
      typography_4_0_0: "불러올 기록을 하나만 선택해주세요.",
      typography_5_0_0: "좋아요. 위 내용을 정리해볼게요.",
      typography_6_0_0: "어떤가요? 최근에 나를 괴롭혔던 문제가 맞나요?",
      buttongroup_6_2_0_option0: "네",
      buttongroup_6_2_0_option1: "아니요",
      typography_7_0_0: "어떤 부분에서 부족하다고 생각하시나요?",
      typography_8_0_0:
        "좋아요, 그럼 오늘은 여기까지 하도록 해요. 제가 확인 후 다시 말씀드릴게요.",
      typography_9_0_0:
        "좋아요. 자동적 사고를 하나 찾았네요. 생각 그물의 퍼즐을 하나 찾았어요. 조금씩 그려지고 있는 생각 그물은 <생각 그물>에서 확인할 수 있답니다.",
      typographyFromData_10_0_0: `"$A$"와 같은 자동적 사고가 반복적으로 나타나는 것 같나요?`,
      typography_11_0_0: "이 생각은 나에게 얼마나 영향을 주고 있나요?",
      typography_12_0_0:
        "위 생각에 대해 더 말씀해주실 부분이 있을까요? 추가로 설명하고 싶거나 보완하고 싶은 부분이 있다면 공유해주세요.",
      typography_13_0_0:
        "앞으로 위 생각을 조금 더 살펴볼 건데요. 혹시 걱정되는 부분이 있을까요?",
      typography_13_0_0_1: "어떤 점이 걱정되시나요?",
      typography_14_0_0:
        "좋아요. 그러면 오늘 이야기한 것에 맞춰 앞으로 프로그램을 진행해볼게요. 오늘도 고생 많았어요.",
    },
  },
};

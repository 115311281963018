export const translation_reflectGoal = {
  en: {},
  ko: {
    v1: {
      네: "네",
      아니요: "아니요",
      "아직 많이 멀어요": "아직 많이 멀어요",
      "조금 멀게 느껴져요": "조금 멀게 느껴져요",
      "조금 가깝게 느껴져요": "조금 가깝게 느껴져요",
      "이제 꽤 가까워요": "이제 꽤 가까워요",
      "아직 아니에요": "아직 아니에요",
      "할 수 있을 것 같아요": "할 수 있을 것 같아요",
      reflectGoal_typography_0_0_0:
        "다음 한 주도 위와 동일한 목표를 향해 노력해도 괜찮을까요?",
      reflectGoal_typography_1_0_0: "목표의 어떤 부분들을 변경하고 싶으신가요?",
      reflectGoal_typography_2_0_0:
        "좋아요. 코치 선생님이 살펴보고 목표를 수정할 수 있도록 도와줄 거예요.",

      reflectGoal_typography_3_0_0:
        "어떤가요? 나는 이 모습에 얼마만큼 가까이 와있을까요?",
      reflectGoal_typography_4_0_0: "어떤 점에서 아직은 멀다고 느껴지나요?",
      reflectGoal_typography_5_0_0: "어떤 점에서 가까워졌다고 느껴지나요?",
      reflectGoal_typography_6_0_0:
        "이 프로그램의 도움 없이 혼자서도 목표에 더 가까이 다가갈 수 있을 것 같나요?",
      reflectGoal_typography_7_0_0:
        "프로그램의 어떤 도움이 더 필요하다고 생각하나요?",
      reflectGoal_typography_8_0_0:
        "좋아요. 스스로 어떻게 해나가면 되겠다는 계획도 있으실까요?",
      reflectGoal_typography_9_0_0:
        "최근 프로그램 진행에서 목표에 조금이나마 다가가는 데에 도움을 주었던 것이 있다면, 그것은 무엇일까요?",
      reflectGoal_typography_10_0_0:
        "반대로 목표에 다가가는 데에 도움을 주지 않거나 관련이 없다고 여겨지는 게 있다면, 그것은 무엇일까요?",
      reflectGoal_typography_11_0_0:
        "좋아요. 코치 선생님이 참고하여 프로그램 진행을 도와줄 거예요.",
    },
  },
};

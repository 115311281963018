import { automaticThoughtIdType } from "api/conceptApi";
import { Audio, Image, Percentage } from "data/CellComponent";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithDataWithContent,
  InstructionWithTextarea,
} from "../BlockComponent";
import { AutomaticThought, LastCard } from "./common";

export const Content_5_3_A = (automaticThoughtId: automaticThoughtIdType) => [
  Instruction(`typography_0_0_0`),
  InstructionWithContent(
    `typography_1_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),
  Instruction(`typography_2_0_0`),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),

  InstructionWithContent("typography_10_0_0", [
    Image("0"),
    Audio("observing_thoughts_15min"),
  ]),
  // InstructionWithTextarea(`typography_11_0_0`),
  InstructionWithDataWithContent(
    { A: { taskId: `4-2-A_${automaticThoughtId}`, id: "영향받는 정도" } },
    "typographyFromData_12_0_0",
    [[Percentage({ id: "영향받는 정도" })]]
  ),
  Instruction(`typography_13_0_0`),
  ...LastCard,
];

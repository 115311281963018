import { memo } from "react";
import TaskItemTemplate from "./TaskItemTemplate";
import TaskItemWithModal from "./TaskItemWithModal";

function TaskItem({
  taskKey,
  isOpen,
  isDone,
  isLocked,
  hasMissingValue,
  isPreviousTaskUndone,
  linkedThoughtRecordKey,
  setNumber,
  hasConnectionLine,
  connectionTop,
  connectionHeight,
  isFirstOfSet,
  isLastOfSet,
}: {
  taskKey: string;
  isOpen: boolean;
  isDone: boolean;
  isLocked?: boolean;
  hasMissingValue?: boolean;
  isPreviousTaskUndone?: boolean;
  linkedThoughtRecordKey?: string;
  setNumber?: number;
  hasConnectionLine?: boolean;
  connectionTop?: number;
  connectionHeight?: number;
  isFirstOfSet?: boolean;
  isLastOfSet?: boolean;
}) {
  return isPreviousTaskUndone ? (
    <TaskItemWithModal
      key={taskKey}
      taskKey={taskKey}
      isOpen={isOpen}
      isDone={isDone}
      isLocked={isLocked}
      hasMissingValue={hasMissingValue}
      setNumber={setNumber}
      hasConnectionLine={hasConnectionLine}
      connectionTop={connectionTop}
      connectionHeight={connectionHeight}
      isFirstOfSet={isFirstOfSet}
      isLastOfSet={isLastOfSet}
    />
  ) : (
    <TaskItemTemplate
      key={taskKey}
      taskKey={taskKey}
      isOpen={isOpen}
      isDone={isDone}
      isLocked={isLocked}
      hasMissingValue={hasMissingValue}
      linkedThoughtRecordKey={linkedThoughtRecordKey}
      setNumber={setNumber}
      hasConnectionLine={hasConnectionLine}
      connectionTop={connectionTop}
      connectionHeight={connectionHeight}
      isFirstOfSet={isFirstOfSet}
      isLastOfSet={isLastOfSet}
    />
  );
}

export default memo(TaskItem);

export const translation_6_7_A = {
  en: {
    v1: {
      typography_0_0_0:
        "People who've discovered their core beliefs often think like this:",
      typography_1_0_0: `Why? Why do these thoughts have such a strong hold in my mind?`,
      typography_2_0_0:
        "It's not rare for someone to go through a powerful event at least once in their life that leads to forming these core beliefs.",
      typography_3_0_0:
        "Such life experiences that shape our core beliefs are called 'core experiences.'",
      typography_4_0_0: "They're often things that happened when we were kids.",
      typography_5_0_0:
        "For instance, someone who felt ignored or rejected by their parents from a young age might end up believing,",
      typography_6_0_0: `"I am worthless."`,
      typography_7_0_0:
        "Or someone who always tried to meet their parents' high expectations might think,",
      typography_8_0_0: `"I am not enough" or "I am incompetent."`,
      typography_9_0_0:
        "And someone who was bullied repeatedly as a child might believe,",
      typography_10_0_0: `"People are threatening."`,
      typography_11_0_0:
        "On the other hand, growing up in a pessimistic and skeptical environment might lead someone to think,",
      typography_12_0_0: `"The world is a bad place."`,
      typography_13_0_0: `But not all core experiences stem from childhood.`,
      typography_14_0_0: `Adult experiences that are repetitive or shocking can also become core experiences.`,
      typography_15_0_0: `Someone who pours everything into starting a business only to fail might think,`,
      typography_16_0_0: `"I am a failure."`,
      typography_17_0_0: `Or someone whose family falls apart due to a partner's affair might become cynical,`,
      typography_18_0_0: `believing "The world is a worthless place."`,
      typography_19_0_0: `Core experiences that shape our beliefs can vary greatly depending on our life's circumstances.`,
      typography_20_0_0:
        "However, it's the same process where core experiences create core beliefs,",
      typography_21_0_0:
        "which then lead to intermediate beliefs and automatic thoughts,",
      typography_22_0_0: `forming our Thought Maps,`,
      typography_23_0_0: `and these maps get activated over and over in our daily lives, causing us distress.`,
      typography_24_0_0: `When looking at core experiences, some might say,`,
      typography_25_0_0: `"So what now? It's already happened."`,
      typography_26_0_0: "That's right. It has happened.",
      typography_27_0_0:
        "And anyone in that situation might have thought and acted the same way.",
      typography_28_0_0: `It's not our fault.`,
      typography_29_0_0:
        "But what's important is that while we can't avoid pain in life,",
      typography_30_0_0:
        "whether we spend our lives suffering in response to that pain is a choice we can make.",
      typography_31_0_0: `Like the saying goes, "Pain is inevitable, but suffering is optional."`,
      typography_32_0_0: `To do that, we need to explore our core experiences to understand where our core beliefs come from.`,
      typography_33_0_0: `Only by identifying and understanding our core experiences can we fully understand ourselves.`,
      typography_34_0_0: `Then, we can understand our depression and anxiety,`,
      typography_35_0_0:
        "and from there, we can learn to distance ourselves from these feelings.",
      typography_36_0_0: `So, let's take a moment to reflect on the core experiences that have shaped our beliefs.`,
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "핵심 믿음을 발견한 사람들은 종종 다음과 같이 생각하곤 해요.",
      typography_1_0_0: `"대체 왜? 왜 내 마음속에 그런 생각들이 강하게 자리잡은 걸까?"`,
      typography_2_0_0:
        "많은 사람들은 살면서 한두 번 쯤은 핵심 믿음을 만들어낼 만한 강렬한 사건을 경험하기도 하는데요.",
      typography_3_0_0:
        "이처럼 핵심 믿음을 만들어내는 삶의 경험을 '핵심 경험'이라고 부른답니다.",
      typography_4_0_0: "핵심 경험은 주로 어릴적 경험일 때가 많아요.",
      typography_5_0_0:
        "예를 들면 어렸을 때부터 부모님으로부터 외면 받고 거절 당했던 경험이 있는 사람은,",
      typography_6_0_0: `"나는 무가치하다"라는 핵심 믿음을 가질 수 있겠죠.`,
      typography_7_0_0:
        "아니면 어렸을 적부터 부모님의 너무 높은 기준에 부합하려고 노력해왔던 사람은,",
      typography_8_0_0: `"나는 부족하다" 또는 "나는 무능하다"라는 핵심 믿음을 가질 수도 있을 거예요.`,
      typography_9_0_0: "혹은 어릴 적부터 지속적인 괴롭힘을 받아왔던 사람은,",
      typography_10_0_0: `"사람들은 위협적이다"라는 핵심 믿음을 가질 수도 있을 테죠.`,
      typography_11_0_0:
        "반면 비관적이고 회의적인 분위기 속에서 자라온 사람은,",
      typography_12_0_0: `"세상은 나쁘게 흘러간다"라는 핵심 믿음을 가질 수도 있어요.`,
      typography_13_0_0: `한편 모든 핵심 경험이 반드시 어릴 적 경험으로부터 만들어지는 건 아니랍니다.`,
      typography_14_0_0: `성인기의 경험도 반복적이거나 충격적이라면 충분히 핵심 경험이 될 수 있죠.`,
      typography_15_0_0: `인생의 모든 것을 쏟아부어 사업을 시작했다가 실패한 사람은,`,
      typography_16_0_0: `"나는 실패자다"라는 핵심 믿음을 가질 수 있을 거예요.`,
      typography_17_0_0: `갑자기 배우자의 외도로 가정이 무너진 경험을 한 사람은,`,
      typography_18_0_0: `"세상은 가치 없는 곳이다"라는 회의적인 시각을 가질 수 있을 거예요.`,
      typography_19_0_0: `이처럼 핵심 믿음을 만들어내는 핵심 경험은 저마다의 삶의 모습에 따라 매우 다양할 수 있답니다.`,
      typography_20_0_0: "하지만 핵심 경험이 핵심 믿음을 만들어내고,",
      typography_21_0_0: "다시 핵심 믿음은 중간 믿음과 자동적 사고를 만들어내,",
      typography_22_0_0: `생각 그물을 만들고,`,
      typography_23_0_0: `그 생각 그물이 반복적으로 일상 속에서 활성화되어 우리를 괴롭히는 건 동일하답니다.`,
      typography_24_0_0: `핵심 경험을 살펴볼 때면 이렇게 이야기하는 사람도 있어요.`,
      typography_25_0_0: `"그래서 어떻게 하자고? 이미 벌어진 일이잖아."`,
      typography_26_0_0: "맞아요. 이미 벌어진 일이지요.",
      typography_27_0_0:
        "그리고 그러한 경험을 했다면 누구라도 그렇게 생각하고 그렇게 행동했을 수 있을 거예요.",
      typography_27: `지금 이 시점에서 나의 반응은 현재 삶의 맥락이나 상황에서 충분히 그럴 만하고 이해할 수 있어요.`,
      typography_28_0_0: `그건 우리의 잘못이 아니랍니다.`,
      typography_29_0_0:
        "하지만 중요한 건 삶에서 그런 고통을 만나는 건 피할 수 없겠지만,",
      typography_30_0_0:
        "우리가 남은 생을 그 고통에 반응하며 괴로워할지는 선택할 수 있다는 점이랍니다.",
      typography_31_0_0: `"고통은 불가피하지만 괴로움은 선택이다"라는 말처럼요.`,
      typography_32_0_0: `그렇게 하기 위해서는 우리의 핵심 믿음이 어디서부터 기원했는지 핵심 경험을 탐색할 필요가 있어요.`,
      typography_33_0_0: `핵심 경험을 찾고 이해했을 때 비로소 나 자신에 대해 온전하게 이해할 수 있을 거랍니다.`,
      typography_34_0_0: `그렇다면 나를 괴롭히는 우울과 불안을 이해할 수 있고,`,
      typography_35_0_0:
        "그로부터 우리는 우울과 불안으로부터 거리두기를 할 수 있을 거랍니다.",
      typography_36_0_0: `자, 그러면 핵심 믿음을 만들었던 핵심 경험을 정리해 볼까요?`,
    },
  },
};

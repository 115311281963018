import { automaticThoughtIdList } from "api/conceptApi";
import { seqPostfix_ko } from "data/prefixData";
import { translation_meditation } from "./meditation";

export const translation_6_12_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `지금부터는 나의 자동적 사고가 생각 그물로부터 어떤 영향을 받고 있었는지 알아볼게요.`,
      typography_1: `우선 함께 발견한 자동적 사고와 생각함정을 먼저 살펴보죠.`,
      ...automaticThoughtIdList.reduce((accumulator, value, index) => {
        return {
          ...accumulator,
          [`typography_2_${value}`]: `${seqPostfix_ko[value]} 번째 자동적 사고예요.`,
        };
      }, {}),
      typography_3: `앞서 우리는 위의 생각이 $A$ 생각함정에 해당한다는 것을 알아냈어요.`,
      typography_4: `모든 자동적 사고와 믿음은 ‘나' 그 자체인 것 같아 괴로울 수도 있겠지만,`,
      typography_5: `결국엔 이렇게 생각 그물 속에서 작동하며 마음속에 떠오르고 있을 뿐이라는 점을 기억하세요.`,
      typography_6: `이제 이 생각을 하나의 심리적 사건처럼 바라보며 분석해 볼게요.`,
      typography_7: `악순환 살펴보기`,
      typography_8: `나의 자동적 사고는 아래 중간 믿음 중,`,
      typography_9: `어떤 내용과 관련이 깊어 보이나요?`,
      typography_10: `그 중간 믿음은 아래 핵심 믿음의 위협으로부터,`,
      typography_11: `어떻게 대처하고 있는 것 같나요?`,
      typography_12: `그렇게 튀어 오른 나의 자동적 사고는,`,
      typography_13: `다시 나의 핵심 믿음을 더 강화하고 있나요? 그렇다면 어떤 방식으로 핵심 믿음이 더 '나' 그 자체라고 믿도록 만들고 있나요?`,
      typography_14: `핵심 믿음으로부터 자동적 사고까지 이어지는 나의 생각 그물은 악순환을 돌고 있나요? 그렇다면 어떠한 방식으로 악순환을 만들고 있나요?`,
      typography_15: `생각 그물과 거리두기`,
      typography_16: `자, 이제 생각 그물과 거리를 두는 명상 훈련을 진행할 텐데요.`,
      typography_17: `이 훈련은 제법 길고, 또 힘들 수도 있어요.`,
      typography_18: `과거의 경험을 다시 떠올리기 때문인데요.`,
      typography_19: `혹시나 지금 감정이 불안정한 상태라면 지금은 건너뛰어도 좋아요. 다음에 또 기회가 있을 거랍니다.`,
      typography_20: `그게 아니라 지금은 조금 안정되었고, 나의 생각 그물을 온전하게 바라볼 수 있을 것 같다면 시도해보아도 좋아요.`,
      typography_21: `도중에 힘이 든다면 얼마든지 중단해도 좋아요. 무리하지 않는 게 좋답니다.`,
      typography_22: `나에겐 언제든 훈련을 중단할 자유가 있습니다.`,
      typography_23: `훈련을 진행해볼까요?`,
      typography_24: `좋아요. 훈련은 25분 정도 소요될 거예요.`,
      ...translation_meditation.ko.v2,
      typography_25: `가치 선택하기`,
      typography_26: `자, 지금까지 생각 그물과 거리두는 연습을 했습니다.`,
      typography_27: `생각 그물은 나의 마음속 깊은 곳에 자리 잡은 하나의 시스템과도 같아요.`,
      typography_28: `하지만 그것 자체가 ‘나'는 아니랍니다.`,
      typography_29: `나는 그것과 함께하면서도 나의 가치에 맞는 선택을 해나갈 수 있어요.`,
      typography_30: `거리를 둔 공간 사이에서 가치를 향해 선택할 수 있답니다.`,
      typography_31: `내게는 이런 가치가 있어요.`,
      typography_32: `만약 위와 같은 생각 그물에 반응하지 않기로 선택할 수 있다면, 나는 그 에너지와 시간을 어떤 가치를 위해 사용하고 싶나요?`,
      typography_33: `좋아요. 지금 바로 그러한 가치를 향한 행동들을 시작해보는 건 어떨까요?`,
      typography_34: `작은 것이라도 괜찮답니다.`,
      typography_35: `행동해보고 느낀 점들은 <생각기록지>에 기록하면 더 좋아요.`,
      typography_36: `잊지 마세요.`,
      typography_37: `나에겐 ‘선택할 자유'가 있답니다.`,
    },
  },
};

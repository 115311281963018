import { translation6_97_A_common } from "./6-97-A";

export const translation_6_8_A = {
  en: {
    v1: {
      네: "Yes",
      아니요: "No",
      typography_0_0_0:
        "Let's now organize the core experiences that shaped our core beliefs.",
      typography_1_0_0: "We shared the following in our last activity:",
      ...translation6_97_A_common.en.v1.part1,
      typography_2_0_0: "Summarizing",
      typography_3_0_0:
        "Based on the above, our core experiences might look something like this.",
      typography_4_0_0:
        "What do you think? Do these experiences seem to have played a crucial role in shaping your core beliefs?",
      typography_5_0_0: "Where do you feel this isn't quite right?",
      typography_6_0_0: `Okay, let's stop here for today. I'll review and get back to you soon.`,
      typography_7_0_0: "Great. We've now completed the thought map.",
      typography_7_0_0_1: "Shall we take a look at the big picture?",
      typography_8_0_0: "What comes to mind when you review your thought map?",
      typography_9_0_0: `Okay, then in our next activity, we'll take time to understand your thought map. You've done great work today.`,
    },
  },
  ko: {
    v1: {
      네: "네",
      아니요: "아니요",
      typography_0_0_0: "이제 핵심 믿음을 만들었던 핵심 경험을 정리해 볼게요.",
      typography_1_0_0: "지난 활동에서 다음과 같은 이야기를 나누었어요.",
      ...translation6_97_A_common.ko.v1.part1,
      typography_2_0_0: "정리하기",
      typography_3_0_0:
        "위의 내용을 토대로 핵심 경험을 정리해보면 다음과 같을 거예요.",
      typography_4_0_0:
        "어떤가요? 나의 위와 같은 핵심 경험이 핵심 믿음을 만드는 데에 중요한 역할을 했던 것 같나요?",
      typography_5_0_0: "어떤 부분에서 그렇지 않다고 생각하시나요?",
      typography_6_0_0: `좋아요, 그럼 오늘은 여기까지 하도록 해요. 제가 확인 후 다시 말씀드릴게요.`,
      typography_7_0_0: "좋아요. 이제 생각 그물의 모습이 완전히 드러났네요.",
      typography_7_0_0_1: "전체 그림을 살펴볼까요?",
      typography_8_0_0:
        "내 마음속 깊이 엮여있는 생각 그물을 살펴보면 어떤 생각이 드나요?",
      typography_9_0_0: `좋아요. 그러면 다음 활동에서는 나의 생각 그물을 이해해보는 시간을 가져볼게요. 오늘도 고생 많았어요.`,
    },
  },
};

import { ProgramType } from "data/programData";
import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const TASK_DOMAIN = "task";

export const TASK_LOAD_ENDPOINT = "data/load";
export const TASK_SAVE_ENDPOINT = "data/save";
export const TASK_DONE_ENDPOINT = "done";
export const TASK_UNDONE_ENDPOINT = "undone";
export const TASK_CLOSE_ENDPOINT = "close";
export const TASK_LIST_ENDPOINT = "list";
export const TASK_LISTALL_ENDPOINT = "list-all";
export const TASK_OPENLIST_SAVE_ENDPOINT = "list-for-open/save";
export const TASK_RESET_ENDPOINT = "reset";
export const TASK_PRESET_DETAIL_ENDPOINT = "preset/detail";
export const TASK_PRESET_UPDATE_ENDPOINT = "preset/update";
export const TASK_FEEDBACK_SUBMIT_ENDPOINT = "feedback/submit";

export interface LoadedDataType {
  data: ProgramType;
}

export type PresetKeyType =
  | "concern" //1-2-A
  | "goal" //1-2-A
  | "myContribution" //4-5-1-A
  | "statementSubject" //4-5-2-A
  | "statement" //4-5-2-A
  | "statementFlipped" //4-5-2-A
  | "negativePrediction" //4-5-3-A
  | "catastrophicEnding" //4-5-3-A
  | "predictionPreventBecomingMoreNegative"; //4-5-3-A

export type PresetDataType = {
  [key in PresetKeyType]?: string;
};

export interface TaskType {
  taskKey: string;
  taskId: string;
  isDone: boolean;
  isOpen: boolean;
  hasMissingValue: boolean;
  isLocked?: boolean;
  lastDoneAt?: string;
  linkedThoughtRecordKey?: string;
  setNumber?: number;
}

export const taskApi = {
  getData: (tk: string) =>
    axiosInstance.get<CustomResponseFormat<LoadedDataType>>(
      `${TASK_DOMAIN}/${TASK_LOAD_ENDPOINT}`,
      { params: { tk: tk } }
    ),
  saveData: (tk: string, body?: ProgramType) =>
    axiosInstance.post<void>(
      `${TASK_DOMAIN}/${TASK_SAVE_ENDPOINT}`,
      { data: body },
      {
        params: { tk: tk },
      }
    ),
  done: (tk: string) =>
    axiosInstance.post<void>(
      `${TASK_DOMAIN}/${TASK_DONE_ENDPOINT}`,
      undefined,
      { params: { tk: tk } }
    ),
  undone: (tk: string) =>
    axiosInstance.post<void>(
      `${TASK_DOMAIN}/${TASK_UNDONE_ENDPOINT}`,
      undefined,
      { params: { tk: tk } }
    ),
  close: (tk: string) =>
    axiosInstance.post<void>(
      `${TASK_DOMAIN}/${TASK_CLOSE_ENDPOINT}`,
      undefined,
      { params: { tk: tk } }
    ),

  getList: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<TaskType[]>>(
      `${TASK_DOMAIN}/${TASK_LIST_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  getAllList: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<TaskType[]>>(
      `${TASK_DOMAIN}/${TASK_LISTALL_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  saveOpenList: (pid: string, taskKeyList: string[]) =>
    axiosInstance.post<CustomResponseFormat>(
      `${TASK_DOMAIN}/${TASK_OPENLIST_SAVE_ENDPOINT}`,
      { taskKeyList: taskKeyList },
      { params: { pid: pid } }
    ),
  reset: (tk: string) =>
    axiosInstance.post<void>(
      `${TASK_DOMAIN}/${TASK_RESET_ENDPOINT}`,
      undefined,
      { params: { tk: tk } }
    ),
  getPresetDetail: (tk: string) =>
    axiosInstance.get<CustomResponseFormat<PresetDataType>>(
      `${TASK_DOMAIN}/${TASK_PRESET_DETAIL_ENDPOINT}`,
      { params: { tk: tk } }
    ),
  updatePreset: (tk: string, data: PresetDataType & { reason: string }) =>
    axiosInstance.post<void>(
      `${TASK_DOMAIN}/${TASK_PRESET_UPDATE_ENDPOINT}`,
      data,
      { params: { tk: tk } }
    ),
  submitFeedback: (tk: string, feedback: string) =>
    axiosInstance.post<void>(
      `${TASK_DOMAIN}/${TASK_FEEDBACK_SUBMIT_ENDPOINT}`,
      { feedback: feedback },
      { params: { tk: tk } }
    ),
};

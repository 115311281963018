export const translation_6_13_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `자, 이제 마지막 여정이에요.`,
      typography_1: `그동안 정말 고생 많았어요.`,
      typography_2: `지금까지 배운 것을 단 한 문장으로 정리하면 다음과 같답니다.`,
      typography_3: `“생각과 거리두고 가치로 나아가기”`,
      typography_4: `생각을 하나의 심리적 사건으로 바라보고,`,
      typography_5: `마음속에 떠오르는 것들을 기꺼이 경험하며,`,
      typography_6: `내가 원하는 삶의 방향을 향한 선택을 해 나가는 것이죠.`,
      typography_7: `“생각과 거리두고 가치로 나아가기”에 대해서는 각자의 이야기가 있을 거예요.`,
      typography_8: `저마다 다양한 괴로움들로부터 거리를 두려고 노력하고,`,
      typography_9: `또 저마다 다른 가치를 좇아 전념하기도 하죠.`,
      typography_10: `하지만 한 가지 공통점이 있답니다.`,
      typography_11: `모두가 비슷하다는 것이에요.`,
      typography_12: `사람들이 서로에게 보여주는 삶은,`,
      typography_13: `온라인상에서 드러나는 삶은,`,
      typography_14: `대중 매체에서 전하는 삶은,`,
      typography_15: `늘 행복하고 기쁜 일만 가득하지만`,
      typography_16: `자세히 들여다보면 우리는 모두 저마다 나름대로 괴로움에 대한 투쟁의 역사를 지니고 있답니다.`,
      typography_17: `그러니 나만 문제가 있고,`,
      typography_18: `나만 무언가가 잘못 흘러가고 있다고 생각하지 않아도 좋아요.`,
      typography_19: `지금 내가 느끼는 생각과 감정들은 현재 삶의 맥락에 비추어보면 충분히 그럴 법한 일들이랍니다.`,
      typography_20: `기쁜 소식은 이제 우리가 그것들을 어떻게 다뤄야 할지 힌트를 얻었다는 것이에요.`,
      typography_21: `디스턴싱은 그 힌트에 대한 거의 모든 정보를 드리고자 노력했어요.`,
      typography_22: `아마 다른 문헌들, 다른 이론들, 다른 방법들을 찾더라도 더 획기적인 새로운 답은 없을 거랍니다.`,
      typography_23: `이제는 ‘앎’에 매달리기보다 행동할 때예요.`,
      typography_24: `앞으로도 <생각기록지>와 <명상훈련지>는 꾸준히 활용이 가능하답니다.`,
      typography_25: `디스턴싱을 시작하며 정해둔 목표가 달성될 때까지,`,
      typography_26: `또 디스턴싱의 과정에서 발견한 나의 가치에 다른 사람, 다른 서비스, 심지어는 디스턴싱의 도움 없이도 스스로 전념할 수 있을 때까지,`,
      typography_27: `그동안 배웠던 것들을 삶에 꾸준히 적용하고 연습해 보세요.`,
      typography_28: `부정적인 경험과 긍정적인 경험을 마주했을 때,`,
      typography_29: `더 적극적으로 경험을 관찰하며,`,
      typography_30: `분리하고,`,
      typography_31: `자동적 사고와 생각함정을 찾아 생각 그물의 관점에서 해석하고 거리를 두고,`,
      typography_32: `그 과정에서 마음속에 떠오르는 것들을 기꺼이 경험하고,`,
      typography_33: `다양한 삶의 영역에서 내가 원하는 가치를 명료하게 하고,`,
      typography_34: `가치를 향한 단계적인 행동들을 실천하고,`,
      typography_35: `가치를 향해 가는 길목에서 마주할 수밖에 없는 고통은 기꺼이 경험하기로 하며,`,
      typography_36: `온전한 가치를 향한 삶에 전념하는 거예요.`,
      typography_37: `디스턴싱의 전체 과정은 결코 쉬운 일이 아니랍니다.`,
      typography_38: `우리는 여기까지 도달한 여러분들은 충분히 그럴 자원과 잠재력이 있다고 생각해요.`,
      typography_39: `필요하다면 그 과정이 자연스러워질 때까지 코치 선생님이 끝까지 도울게요.`,
      typography_40: `앞으로 코치 선생님은 <생각기록지>와 <명상훈련지>에 대한 피드백을 위주로 도움을 주실 거랍니다.`,
      typography_41: `특히 생각을 관찰하고 기꺼이 경험하는 일을 돕고,`,
      typography_42: `그것이 어려울 때 함께 함정과 그물을 파악하고,`,
      typography_43: `긍정적인 기록들로부터 가치를 도출해,`,
      typography_44: `가치를 향한 행동을 해나갈 수 있도록 도울 거랍니다.`,
      typography_45: `자, 이제 ‘나'의 시간이에요.`,
    },
  },
};

import { Box, Option, Select, Typography } from "@mui/joy";
import {
  intermediateBeliefKeyMapping,
  IntermediateBeliefKeyType,
} from "api/conceptApi";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import { setProgramContentData } from "logic/logics";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { conceptAtom } from "recoil/conceptAtom";

export default function SelectIntermediateBeliefCell({
  selectedIndex,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  readOnly,
}: {
  selectedIndex?: number;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  readOnly?: boolean;
}) {
  const [values, setValues] = useState<string[]>();

  //코치 프리셋
  useEffect(() => {
    if (values && selectedIndex !== undefined && selectedIndex > -1) {
      const selected = values[selectedIndex];

      handleChange(selected, selectedIndex);
    }
  }, [values, selectedIndex]);

  const conceptState = useRecoilValue(conceptAtom);

  useEffect(() => {
    if (conceptState) {
      setValues(
        Object.entries(conceptState.intermediateBelief || {})
          .filter(([key, value]) => !!value)
          .map(
            ([key, value]) =>
              `${value}(${
                intermediateBeliefKeyMapping[key as IntermediateBeliefKeyType]
              })`
          )
      );
    }
  }, [conceptState]);

  const handleChange = (selected: string, selectedIndex: number) => {
    setProgramContentData({
      setData,
      blockIndex,
      lineIndex,
      cellIndex,
      newlyAddedData: { value: selected, selectedIndex: selectedIndex },
    });
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(500);

  useEffect(() => {
    if (containerRef.current && containerRef.current.offsetWidth > 0) {
      setWidth(containerRef.current?.offsetWidth);
    }
  }, []);

  return (
    <Box
      ref={containerRef}
      className={`w-full ${readOnly && "pointer-events-none"}`}
    >
      {values && (
        <Select
          sx={{
            "--joy-palette-background-surface": "white",
            "--joy-palette-neutral-outlinedHoverBg": "white",
            height: "48px",
            width: "100%",
          }}
          defaultValue={
            selectedIndex !== undefined ? values[selectedIndex] : ""
          }
          onChange={(e) => {
            const selected = (e?.target as HTMLElement)?.innerText;

            const selectedIndex = values.findIndex(
              (element) => element === selected
            );

            if (selected && selectedIndex > -1) {
              handleChange(selected, selectedIndex);
            }
          }}
          renderValue={(value) => (
            <Typography
              level={"body-md"}
              textAlign={"left"}
              className="w-full overflow-hidden whitespace-normal"
            >
              {selectedIndex !== undefined ? values[selectedIndex] : ""}
            </Typography>
          )}
        >
          {values.map((option, optionIndex) => (
            <Option
              key={`select_option_${blockIndex}_${lineIndex}_${cellIndex}_${optionIndex}`}
              value={option}
              sx={{
                width: width,
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {option}
              </Typography>
            </Option>
          ))}
        </Select>
      )}
    </Box>
  );
}

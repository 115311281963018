import { Box, Stack, Typography } from "@mui/joy";
import {
  ImpactScoreHistoryListType,
  IndividualThoughtType,
} from "api/conceptApi";
import _ from "lodash";
import { ThoughtMapText } from "./ThoughtMapText";
import LineChart from "components/common/LineChart";
import { useState } from "react";
import IconButton from "components/common/IconButton";
import { LeftArrow, RightArrow } from "static/image/common/SvgAssets";
import useOpenTask from "hooks/useOpenTask";
import { getTaskIdFromTaskKey } from "logic/logics";
import { extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";
import { getTaskTitleFromTaskId } from "logic/logics";

function ThoughtTrapItem({
  step,
  text,
  taskKey,
  impactScoreChange,
}: {
  step?: number;
  text: string;
  taskKey: string;
  impactScoreChange?: number;
}) {
  const openTask = useOpenTask(`${taskKey}`);
  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      alignItems={"center"}
      sx={{
        py: "12px",
        px: "10px",
        backgroundColor: "background.level1",
        borderRadius: "8px",
        cursor: "pointer",
      }}
      onClick={openTask}
    >
      <Stack direction={"row"} alignItems={"center"} spacing="10px">
        {step !== undefined && (
          <Stack
            sx={{
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "common.black",
            }}
            justifyContent="center"
            alignItems={"center"}
          >
            <Typography
              sx={{ fontSize: "10px", fontWeight: 500, color: "white" }}
            >
              {step}
            </Typography>
          </Stack>
        )}
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
          {text}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems={"center"} spacing="4px">
        {impactScoreChange !== undefined && (
          <Typography
            sx={{ fontSize: "14px", fontWeight: 700 }}
            startDecorator={
              <Box
                sx={{
                  ...(impactScoreChange > 0 && { transform: "rotate(180deg)" }),
                }}
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6403 14.1318C10.2491 14.7618 9.33239 14.7618 8.94121 14.1318L4.52999 7.02751C4.11636 6.36136 4.59542 5.5 5.37954 5.5L14.202 5.5C14.9861 5.5 15.4652 6.36136 15.0515 7.02751L10.6403 14.1318Z"
                    fill={
                      impactScoreChange > 0
                        ? "#B7B9BC"
                        : extractHexColor(theme.vars.palette.primary.solid)
                    }
                  />
                </svg>
              </Box>
            }
          >
            {`${Math.abs(impactScoreChange)}`}
          </Typography>
        )}
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="20"
            y="0.500061"
            width="20"
            height="20"
            rx="3"
            transform="rotate(90 20 0.500061)"
            fill="#F6F9FA"
          />
          <path
            d="M8 15.5001L12 10.5001L8 5.50006"
            stroke="#20242B"
            strokeOpacity="0.3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Stack>
    </Stack>
  );
}

export default function AutomaticThoughtByThoughtTrap({
  thoughtList,
  impactScoreHistoryList,
}: {
  thoughtList: IndividualThoughtType[];
  impactScoreHistoryList?: ImpactScoreHistoryListType[];
}) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  function goPrevious() {
    setSelectedIndex((index) => index - 1);
  }
  function goNext() {
    setSelectedIndex((index) => index + 1);
  }

  const currentImpactScoreHistoryList = (impactScoreHistoryList || []).filter(
    (element) => element.thought === thoughtList[selectedIndex].thought
  );
  return (
    <Stack sx={{ width: "100%" }} spacing="20px">
      <Stack direction="row" alignItems={"center"} sx={{ py: "2px" }}>
        <IconButton
          iconNode={<LeftArrow />}
          {...(selectedIndex > 0 && {
            onClick: goPrevious,
          })}
        />

        <Stack
          sx={{ flex: 1 }}
          spacing="6px"
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
            {`생각함정: ${thoughtList[selectedIndex].thoughtTrap || "찾는 중"}`}
          </Typography>
        </Stack>
        <IconButton
          iconNode={<RightArrow />}
          {...(selectedIndex < thoughtList.length - 1 && { onClick: goNext })}
        />
      </Stack>
      <ThoughtMapText text={thoughtList[selectedIndex].thought} />
      {currentImpactScoreHistoryList && (
        <Box>
          <Stack direction={"row"} justifyContent="space-between">
            <ThoughtMapText color={"primary.solid"} text={"영향 정도"} />
            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
              {`${
                currentImpactScoreHistoryList[
                  currentImpactScoreHistoryList.length - 1
                ]?.score
              }`}
              <Typography sx={{ fontWeight: 400 }}>{`/100`}</Typography>
            </Typography>
          </Stack>
          <Box sx={{ pt: "20px" }}>
            <LineChart
              id={`impaceScoreChange`}
              data={currentImpactScoreHistoryList.map((each) => each.score)}
              maxScore={100}
              abnormalScore={20}
            />
          </Box>
          {currentImpactScoreHistoryList.length > 0 && (
            <Stack spacing="8px">
              {currentImpactScoreHistoryList?.map(
                ({ taskKey, score }, index) => (
                  <ThoughtTrapItem
                    key={`thoughtTrap_${taskKey}`}
                    text={getTaskTitleFromTaskId(getTaskIdFromTaskKey(taskKey))}
                    taskKey={`${taskKey}`}
                    {...(index > 0 && {
                      impactScoreChange:
                        score - currentImpactScoreHistoryList[index - 1].score,
                    })}
                  />
                )
              )}
            </Stack>
          )}
        </Box>
      )}
    </Stack>
  );
}

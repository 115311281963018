import { automaticThoughtIdType } from "api/conceptApi";
import {
  DimmedLabelLine,
  DividerLine,
  GuideTypographyLine,
  Label,
  Percentage,
  PieChart,
  Select,
  SolidLabelLine,
  TextareaLine,
  TypographyFromData,
  TypographyFromDataLine,
  TypographyLine,
} from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithDataWithPreset,
  InstructionWithDataWithTextarea,
} from "../BlockComponent";
import { Content_4_5_A_end, Content_4_5_A_start } from "./4-5-A";
import { AutomaticThought } from "./common";

export const Content_4_5_1_A = (automaticThoughtId: automaticThoughtIdType) => [
  ...Content_4_5_A_start(automaticThoughtId),

  Header1(`typography_0_0_0`),

  InstructionWithContent(`typography_1_0_0`, [
    TypographyFromDataLine({ A: { id: "상황" } }, "$A$"),
  ]),
  InstructionWithContent(`typography_2_0_0`, [
    TypographyFromDataLine({ A: { id: "생각" } }, "$A$"),
  ]),

  // InstructionWithDataWithCoachTextarea(
  //   { A: { id: `상황` } },
  //   `typographyFromData_3_0_0`,
  //   {
  //     id: "나의 요인",
  //     placeholder: "내담자가 생각하는 자신의 기여를 적어주세요",
  //   }
  // ),
  InstructionWithDataWithPreset(
    { A: { id: `상황` } },
    `typographyFromData_3_0_0`,
    {
      id: "나의 요인",
      placeholder: "내담자가 생각하는 자신의 기여를 적어주세요",
      presetKey: "myContribution",
    }
  ),

  InstructionWithData({ A: { id: `상황` } }, `typographyFromData_4_0_0`),
  InstructionWithContent(`typography_5_0_0`, [
    TypographyLine(`typography_5_2_0`),
  ]),
  InstructionWithContent(`typography_6_0_0`, [
    TypographyLine(`typography_6_2_0`),
  ]),
  InstructionWithContent(`typography_7_0_0`, [
    TypographyLine(`typography_7_2_0`),
  ]),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  Instruction(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),

  InstructionWithData({ A: { id: "상황" } }, `typography_14_0_0`),
  Instruction(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),

  InstructionWithDataWithContent(
    { A: { id: "상황" } },
    `typographyFromData_17_0_0`,
    [
      SolidLabelLine("나의 요인"),
      TypographyFromDataLine({ A: { id: "나의 요인" } }, "$A$"),
      DividerLine(),
      ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(`요인`, { labelIndex: each }),
        TextareaLine({ id: `요인 ${each}` }),
        ...(each < 5 ? [DividerLine()] : []),
      ]),
    ]
  ),

  Header1(`typography_18_0_0`),
  Instruction(`typography_19_0_0`),
  InstructionWithDataWithContent(
    { A: { id: `상황` } },
    `typographyFromData_20_0_0`,
    [
      ...Array.from({ length: 6 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(each === 6 ? "나의 요인" : `요인`, {
          requiredId: each === 6 ? "나의 요인" : `요인 ${each}`,
          ...(each < 6 && { labelIndex: each }),
        }),
        TypographyFromDataLine(
          { A: { id: each === 6 ? "나의 요인" : `요인 ${each}` } },
          "$A$",
          { requiredId: each === 6 ? "나의 요인" : `요인 ${each}` }
        ),
        DimmedLabelLine("기여도", {
          requiredId: each === 6 ? "나의 요인" : `요인 ${each}`,
        }),
        [
          Percentage({
            id: each === 6 ? "나의 요인 확률" : `요인 ${each} 확률`,
            width: 0.4,
            requiredId: each === 6 ? "나의 요인" : `요인 ${each}`,
            ...(each === 6 && {
              complementPercentageIdList: Array.from(
                { length: 5 },
                (v, i) => i + 1
              ).map((each) => `요인 ${each} 확률`),
            }),
          }),
        ],

        ...(each < 6
          ? [
              DividerLine({
                requiredId: each === 6 ? "나의 요인" : `요인 ${each}`,
              }),
            ]
          : []),
      ]),

      GuideTypographyLine("typography_20_32_0", {
        width: 0.6,
        percentageIdList: Array.from({ length: 6 }, (v, i) => i + 1).map(
          (each) => (each === 6 ? "나의 요인 확률" : `요인 ${each} 확률`)
        ),
      }),
    ]
  ),
  Instruction(`typography_21_0_0`),
  Instruction(`typography_22_0_0`),
  Instruction(`typography_23_0_0`),
  InstructionWithContent(`typography_24_0_0`, [
    ...Array.from({ length: 6 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(each === 6 ? "나의 요인" : `요인`, {
        requiredId: each === 6 ? "나의 요인" : `요인 ${each}`,
        ...(each < 6 && { labelIndex: each }),
      }),
      TypographyFromDataLine(
        { A: { id: each === 6 ? "나의 요인" : `요인 ${each}` } },
        "$A$",
        {
          pieChartDataId: `chart2_label_${each === 6 ? 0 : each}`,
          requiredId: each === 6 ? "나의 요인" : `요인 ${each}`,
        }
      ),
      [
        Label("기여도", "dimmed", {
          width: 0.4,
          requiredId: each === 6 ? "나의 요인" : `요인 ${each}`,
        }),
        Label("통제 가능 여부", "dimmed", {
          requiredId: each === 6 ? "나의 요인" : `요인 ${each}`,
        }),
      ],

      [
        TypographyFromData(
          {
            A: {
              id: each === 6 ? "나의 요인 확률" : `요인 ${each} 확률`,
            },
          },
          "$A$",
          {
            width: 0.4,
            pieChartDataId: `chart1_value_${each}/chart2_value_${
              each === 6 ? 0 : each
            }`,
            requiredId: each === 6 ? "나의 요인" : `요인 ${each}`,
          }
        ),
        Select(
          [
            { translationKey: "통제 불가능" },
            {
              translationKey: "통제 가능",
            },
          ],
          {
            pieChartDataId: `chart1_label_${each}`,
            requiredId: each === 6 ? "나의 요인" : `요인 ${each}`,
          }
        ),
      ],

      ...(each < 6
        ? [
            DividerLine({
              requiredId: each === 6 ? "나의 요인" : `요인 ${each}`,
            }),
          ]
        : []),
    ]),
  ]),

  Instruction(`typography_25_0_0`),

  Header1(`typography_26_0_0`),
  InstructionWithContent(`typography_27_0_0`, [PieChart("chart1")]),

  InstructionWithContent(
    `typography_28_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "chart1_통제 불가능" } },
    "typographyFromData_29_0_0"
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "chart1_통제 불가능" } },
    "typographyFromData_30_0_0"
  ),

  Instruction("typography_31_0_0"),
  InstructionWithContent(`typography_32_0_0`, [PieChart("chart2")]),

  InstructionWithContent(
    `typography_33_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),

  InstructionWithDataWithTextarea(
    { A: { id: "나의 요인 확률" } },
    "typographyFromData_34_0_0"
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "나의 요인 확률" } },
    "typographyFromData_35_0_0"
  ),

  ...Content_4_5_A_end(automaticThoughtId, 1),
];

import { automaticThoughtIdType } from "api/conceptApi";
import { Percentage } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
  InstructionWithTextarea,
} from "../BlockComponent";

import { AutomaticThought, LastCard } from "./common";

export const Content_4_2_A = (automaticThoughtId: automaticThoughtIdType) => [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0"),
  InstructionWithContent(
    `typography_3_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  Instruction(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),
  Instruction(`typography_14_0_0`),
  Instruction(`typography_15_0_0`),

  Header1(`typography_16_0_0`),
  InstructionWithTextarea("typography_17_0_0", { id: "인물1" }),
  InstructionWithDataWithTextarea(
    { A: { id: "인물1" }, B: { id: "상황" }, C: { id: "생각" } },
    `typographyFromData_18_0_0`,
    { id: "다른 생각" }
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "인물1" } },
    `typographyFromData_19_0_0`
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "다른 생각" } },
    `typographyFromData_20_0_0`
  ),
  InstructionWithTextarea("typography_21_0_0", { id: "인물2" }),
  InstructionWithDataWithTextarea(
    { A: { id: "상황" }, B: { id: "인물2" } },
    `typographyFromData_22_0_0`,
    { id: "다른 생각2" }
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "다른 생각2" } },
    `typographyFromData_23_0_0`
  ),

  Header1(`typography_24_0_0`),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_25_0_0`
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_26_0_0`
  ),
  InstructionWithTextarea("typography_27_0_0"),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_28_0_0`
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_29_0_0`
  ),
  InstructionWithTextarea("typography_30_0_0"),

  Header1(`typography_31_0_0`),

  InstructionWithContent(
    `typography_32_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),

  InstructionWithDataWithContent(
    { A: { taskId: `3-3-A_${automaticThoughtId}`, id: "영향받는 정도" } },
    "typographyFromData_33_0_0",
    [[Percentage({ id: "영향받는 정도" })]]
  ),

  Instruction("typography_34_0_0"),
  ...LastCard,
];

export const translation_3_2_A = {
  en: {
    v1: {
      typography_0_0_0:
        "Pop, pop. The popcorn machine inside our minds never stops working, constantly churning out various thoughts.  ",
      typography_1_0_0:
        "There's a hierarchy in the thoughts produced by this popcorn machine. Some are surface-level, while others are more core thoughts.",
      typography_2_0_0: "Surface and core thoughts are strongly linked.",
      typography_3_0_0:
        "Core thoughts often give rise to surface thoughts in various ways.",
      typography_4_0_0:
        "We call this cognitive system that links core thoughts to surface thoughts a 'thought map.'",
      typography_5_0_0:
        "We're going to draw our own thought maps moving forward.",
      typography_6_0_0:
        "By completing your thought map, you'll better understand why such popcorn thoughts pop up in your mind and how to maintain a healthy distance from them.",
      typography_7_0_0:
        "As you progress through the program, keep an eye on your thought map filling up in <My Thought Map>.",
      typography_8_0_0:
        "Today, let's start by understanding 'automatic thoughts,' the most surface-level thoughts on our thought map.",
      typography_9_0_0: "What are automatic thoughts?",
      typography_10_0_0:
        "Automatic thoughts are 'thoughts that spontaneously and unconsciously arise in our minds in specific situations.'",
      typography_11_0_0:
        "Essentially, these are the internal monologues that pass through our minds in particular situations, like those we wrote down in our <Thought Log>.",
      typography_12_0_0:
        "Because they almost automatically and reflexively pop into our minds, they are called 'automatic' thoughts.",
      typography_13_0_0:
        "While filling out the <Thought Log>, you might notice certain types of thoughts appearing repeatedly.",
      typography_14_0_0:
        "We'll focus on these repetitive, troubling automatic thoughts.",
      typography_15_0_0: "But why are thoughts 'automatically' generated?",
      typography_16_0_0:
        "Why does our mind create various popcorn-like thoughts automatically without our consent?",
      typography_17_0_0: "Automatic thoughts for survival",
      typography_18_0_0:
        "Let's travel back to prehistoric times. Imagine walking across a desolate plain. Suddenly, a loud noise comes from behind.",
      typography_19_0_0:
        "Without even thinking, you reflexively perceive it as danger,",
      typography_20_0_0: "and run away.",
      typography_21_0_0:
        "What happened to creatures that processed everything consciously? They went extinct.",
      typography_22_0_0:
        "Automatic thoughts help us judge situations without expending much energy,",
      typography_23_0_0:
        "allowing us to respond to dangers and prevent our brains from being overloaded with information.",
      typography_24_0_0:
        "Our brains produce the thoughts we need, even without our conscious involvement.",
      typography_25_0_0: "Reacting automatically to thoughts",
      typography_26_0_0:
        "Just like that, we automatically react to thoughts that arise in our minds.",
      typography_27_0_0:
        "The problem is, among these thoughts are harmful ones that aren't worth reacting to.",
      typography_28_0_0:
        "Humans, with their advanced cognitive abilities, reminisce, empathize, and worry about the future.",
      typography_29_0_0:
        "We quickly recall past memories in similar situations, re-experiencing similar emotions to avoid making the same mistakes.",
      typography_30_0_0: "But sometimes, this ability can backfire.",
      typography_31_0_0:
        "For example, you've been coldly broken up with in the past, leaving a deep wound. It lingered for a long time.",
      typography_32_0_0:
        "Time has passed, and now you're facing someone new. You like this person and start a conversation.",
      typography_33_0_0:
        "They respond somewhat indifferently. In that moment, you recall past hurts.",
      typography_34_0_0:
        "An automatic thought pops up: 'This person dislikes me too.'",
      typography_35_0_0:
        "Feeling down, you decide not to talk to them anymore, and you drift apart.",
      typography_36_0_0:
        "If we don't distance ourselves from such thoughts, we suffer.",
      typography_37_0_0: "Creating distance and space",
      typography_38_0_0:
        "Had we not reacted reflexively to the thought 'This person might dislike me,' we might have acted differently.",
      typography_39_0_0:
        "Maybe the thought itself was inaccurate. Perhaps the person wasn't indifferent but just shy.",
      typography_40_0_0:
        "Or maybe the thought 'This person might dislike me' wasn't helpful at all.",
      typography_41_0_0:
        "If we step back and see that a thought is inaccurate or unhelpful, we can choose not to react to it and consider other options.",
      typography_42_0_0:
        "If we valued confidence in relationships, we might have stopped reacting to 'This person might dislike me' and acted in a way that aligned with our values.",
      typography_43_0_0: "The perspective matters",
      typography_44_0_0:
        "If we're led by every automatic thought, we're not free. We'll think, feel, and act as the thoughts dictate.",
      typography_45_0_0:
        "But here's the good news. We humans are thinking creatures.",
      typography_46_0_0: "We've recognized what automatic thoughts are.",
      typography_47_0_0:
        "Now, when an automatic thought arises, we can examine if it's accurate. If not, there's no need to react to it.",
      typography_48_0_0:
        "Of course, some thoughts might be accurate. But if they're unhelpful, we can choose not to react to them.",
      typography_49_0_0:
        "This way, we can become somewhat more 'free-thinking beings.'",
      typography_50_0_0:
        "Now, shall we explore the automatic thoughts we need to distance ourselves from?",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "탁, 탁. 우리 내면의 팝콘 기계는 쉬지 않고 돌아갑니다. 다양한 생각들을 만들어내죠.",
      typography_1_0_0:
        "팝콘 기계가 만드는 생각에는 위계가 있어요. 표면적인 생각도 있고, 더 핵심적인 생각도 있죠.",
      typography_2_0_0:
        "표면적인 생각과 핵심적인 생각은 강하게 연결되어 있는데요.",
      typography_3_0_0:
        "핵심적인 생각은 다양한 방식을 통해 표면적인 생각들을 만들어내곤 해요.",
      typography_4_0_0:
        "이처럼 핵심적인 생각부터 표면적인 생각까지 이어지는 일련의 인지 체계를 '생각 그물'이라고 불러요.",
      typography_5_0_0: "우리는 앞으로 나만의 생각 그물을 파악해 나갈 거예요.",
      typography_6_0_0:
        "생각 그물을 파악하면 대체 왜 나의 마음속에서는 이런 팝콘이 튀어오르는지 더 잘 이해할 수 있고, 그로부터 거리두기를 더 잘할 수 있을 거랍니다.",
      typography_7_0_0:
        "프로그램을 진행하며 <생각 그물>에서 하나씩 채워지는 생각들을 확인해보세요.",
      typography_8_0_0:
        "오늘은 생각 그물에서 가장 표면적인 생각인 '자동적 사고'에 대해 먼저 알아보도록 할게요.",
      typography_9_0_0: "자동적 사고란?",
      typography_10_0_0:
        "자동적 사고는 '특정한 상황에서 마음속에 자동적이고 무의식적으로 떠오르는 생각'을 뜻해요.",
      typography_11_0_0:
        "즉, 우리가 <생각기록지>에 작성했던 '특정한 상황에서 마음속을 스쳐지나가는 독백과 같은 생각'이 바로 자동적 사고인 것이죠.",
      typography_12_0_0:
        "자동적 사고는 거의 자동적이고 반사적으로 마음속에 떠오르기 때문에 '자동적' 사고라고 불려요.",
      typography_13_0_0:
        "<생각기록지>를 작성하며 관찰하다 보면 특정한 형태의 생각들이 반복해서 나타난다는 것을 알 수 있는데요.",
      typography_14_0_0:
        "우리는 이처럼 반복적으로 나타나며 우리를 괴롭히는 자동적 사고에 집중해 볼 거예요.",
      typography_15_0_0:
        "그런데 도대체 생각은 왜 ‘자동적으로’ 만들어지는 것일까요?",
      typography_16_0_0:
        "마음은 왜 나의 동의를 구하지도 않은 채 마음대로 다양한 팝콘들을 자동적으로 만들어내는 것일까요?",
      typography_17_0_0: "생존을 위한 자동적 사고",
      typography_18_0_0:
        "잠시 원시시대로 돌아가볼까요? 당신은 황량한 들판을 걷고 있습니다. 그런데 뒤에서 갑자기 큰 소리가 들리네요.",
      typography_19_0_0:
        "당신은 ‘생각할 겨를도 없이’ 반사적으로 위험하다고 판단해요.",
      typography_20_0_0: "그리고 냅다 도망치죠.",
      typography_21_0_0:
        "모든 일을 의식적으로 생각하고 진행했던 생명체는 어떻게 되었을까요? 멸종하고 말았답니다.",
      typography_22_0_0:
        "이처럼 자동적 사고는 우리가 어떤 상황을 판단하는 데에 큰 에너지를 들이지 않도록 도와줘요",
      typography_23_0_0:
        "그래서 우리는 위험에 대처할 수 있고, 모든 정보를 처리하느라 뇌를 과부하시키지 않아도 되죠.",
      typography_24_0_0:
        "우리가 의식적으로 관여하지 않아도 뇌는 우리에게 필요한 생각들을 만들어주는 것이죠.",
      typography_25_0_0: "생각에 자동적으로 반응하는 우리",
      typography_26_0_0:
        "이처럼 우리는 마음속에 떠오르는 생각에 자동적으로 반응해요.",
      typography_27_0_0:
        "문제는 그러한 생각 중에는 우리가 반응해서 좋을 것이 하나도 없는 해가 되는 생각들이 있다는 것이죠.",
      typography_28_0_0:
        "뛰어난 인지 능력을 가진 인간은 지난 기억을 회상하기도 하고, 상대의 마음을 추측하기도 하고, 앞날을 걱정하기도 해요.",
      typography_29_0_0:
        "지난 일들과 비슷한 상황이 있다면 빠르게 그 기억을 떠올려 비슷한 감정을 다시 느끼곤 하죠. 똑같은 실수를 두 번 하면 안 되니까요.",
      typography_30_0_0:
        "하지만 때론 그러한 능력이 부작용을 만들어내기도 해요.",
      typography_31_0_0:
        "예를 들어볼까요? 당신은 과거에 연인으로부터 냉정하게 이별을 통보 받은 적이 있어요. 마음에 큰 상처가 되었죠. 그 상처는 제법 오래 갑니다.",
      typography_32_0_0:
        "이제 시간이 지났어요. 당신은 지금 새로운 사람을 마주하고 있네요. 당신은 그 사람이 좋아요. 그래서 그 사람에게 말을 걸었죠.",
      typography_33_0_0:
        "상대는 다소 무심한 듯 대답하네요. 그 순간은 당신은 과거의 상처가 떠올랐죠.",
      typography_34_0_0:
        "마음속엔 다음과 같은 자동적 사고가 떠올랐어요. ‘저 사람도 역시 나를 싫어하네.’",
      typography_35_0_0:
        "당신은 침울해졌고 더이상 상대에게 말을 걸지 않기로 해요. 그렇게 상대와는 멀어졌어요.",
      typography_36_0_0:
        "이처럼 마음속에 떠오른 생각으로부터 거리를 두지 못하면 우리는 괴로움을 겪게 돼요.",
      typography_37_0_0: "거리두고 공간 만들기",
      typography_38_0_0:
        "생각과 거리를 두고 마음속에 떠오른 생각에 반사적으로 반응하지 않았더라면 다르게 행동했을지도 몰라요.",
      typography_39_0_0:
        "‘저 사람이 나를 싫어할 것 같아’라는 생각 자체가 부정확했을 수도 있죠. 상대가 무심한 게 아니라 쑥쓰러웠을 수도 있는 것이니까요.",
      typography_40_0_0:
        "아니면 ‘저 사람이 나를 싫어할 것 같아’라는 생각이 나에게 전혀 도움이 되지 않는 것이었을 수도 있어요.",
      typography_41_0_0:
        "이처럼 마음속에 떠오른 생각을 곧이 곧대로 믿지 않을 수 있다면, 우리는 한 걸음 떨어져서 생각을 바라 본 뒤, 그 생각에 반응하지 않고 내가 원하는 선택지를 고를 수도 있답니다.",
      typography_42_0_0:
        "인간 관계에서 자신감 있는 모습을 가지는 데에 가치를 두고 있었다면, ‘저 사람이 나를 싫어할 것 같다’는 생각에 반응하는 것을 멈추고, 나의 가치에 맞는 방식으로 행동했을 수도 있겠죠.",
      typography_43_0_0: "중요한 건 생각을 보는 관점",
      typography_44_0_0:
        "자동적 사고가 떠올랐을 때 무의식적으로 끌려가기만 한다면 우리는 자유롭지 못할 거예요. 떠오르는 대로 생각하고, 감정을 느끼고, 행동하겠죠.",
      typography_45_0_0:
        "하지만 반가운 소식이 있어요. 우리 인간은 사고할 수 있는 동물이라는 점이죠.",
      typography_46_0_0: "우리는 자동적 사고라는 것을 인지했어요.",
      typography_47_0_0:
        "이제 자동적 사고가 떠올랐을 때 그 생각이 정확한지 살펴볼 수 있을 거예요. 정확하지 않다면 그에 반응할 필요가 없겠죠.",
      typography_48_0_0:
        "물론 그 생각이 정확할 수도 있어요. 하지만 우리에게 도움이 되지 않는 생각이라면 역시 그에 반응하지 않을 수는 있을 거예요.",
      typography_47:
        "그렇다면 자동적 사고가 떠올랐을 때 한 걸음 떨어져서 그 생각을 바라볼 수 있을 거예요.",
      typography_48:
        "거리를 두고 찬찬히 바라보면 생각이라는 게 꼭 정확하지도, 늘 도움이 되는 것도 아니라는 사실을 발견하게 될 거랍니다.",
      typography_49:
        "우리는 앞으로 반복적으로 자동적 사고를 찾아나가며 위 사실을 확인해 나갈 텐데요.",
      typography_50:
        "물론 때로는 부정적인 생각도 제법 정확해보일 때가 있고, 가끔은 나름대로 도움이 되는 것 같아 보일 때도 있답니다.",
      typography_51: "상관없어요. 중요한 건 생각의 내용이 아니랍니다.",
      typography_52:
        "자동적인 생각이 매번 정확한 것도 아니고, 무조건 따라야 할 만큼 늘 도움이 되는 것도 아니란 사실을 이해하는 게 중요하답니다.",
      typography_53:
        "생각이 그토록 임의적이라면 그것에 그렇게 강하게 영향을 받을 필요가 무엇이겠어요.",
      typography_54:
        "날이 추우면 추위를 느끼고, 햇볕을 쬐면 따뜻함을 느끼는 것처럼, 생각 또한 마음속에서 떠오르고 지나가는 심리적 사건으로 보지 않을 이유가 무엇이겠어요.",
      typography_55:
        "앞으로 우리는 많은 자동적 사고를 찾아나가며 생각과 다시 관계 맺는 관점을 얻게 될 거랍니다.",
      typography_49_0_0:
        "이를 통해 우리는 어느 정도는 ‘자유롭게 생각하는 존재’에 더 가까워질 수 있을 거예요.",
      typography_50_0_0:
        "자, 그러면 이제 거리를 둘 나의 자동적 사고를 살펴볼까요?",
    },
  },
};

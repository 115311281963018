import { Content_6_9_A } from "data/task/6-9-A";
import getConfirmedThoughtmapState from "./getConfirmedThoughtMapState";
import getThoughtmapState from "./getThoughtMapState";

export default async function get_6_9_A_RawData(patientId: string) {
  try {
    // const response = await getCopingStrategyState(patientId);

    const thoughtmapState = await getThoughtmapState(patientId);
    const confirmedThoughtmapState = await getConfirmedThoughtmapState(
      patientId
    );
    // console.log("[TEST]", thoughtmapState);
    return {
      taskId: `6-9-A`,
      content: Content_6_9_A(
        confirmedThoughtmapState?.intermediateBeliefKeyList
      ),
    };
  } catch (error) {
    // 에러 핸들링
    console.error("Error in get_6_5_A_RawData:", error);
    throw error; // 또는 에러에 대한 처리를 진행할 수 있음
  }
}

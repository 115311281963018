import * as _ from "lodash";
import { Input } from "@mui/joy";
import { useEffect, useState } from "react";
import { ProgramContentType } from "data/BlockComponent";
import { useTranslation } from "react-i18next";
import { setProgramContentData } from "logic/logics";
import { theme } from "styles/theme";
import { extractHexColor, hexToRgba } from "styles/hexOpacity";

export default function PercentageCell({
  id,
  initialPercentage,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  inputKey,
  min = 0,
  max = 100,
  step = 5,
}: {
  id?: string;
  initialPercentage?: number;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  inputKey?: string;
  min?: number;
  max?: number;
  step?: number;
}) {
  const { t: t_common } = useTranslation("translation", {
    keyPrefix: "task.activity.common",
  });

  const handleChange = (percentage: number) => {
    setProgramContentData({
      setData,
      blockIndex,
      lineIndex,
      cellIndex,
      newlyAddedData: {
        value:
          percentage !== undefined && !isNaN(percentage)
            ? `${Math.max(min, Math.min(percentage, max))}`
            : undefined,
        percentage:
          percentage !== undefined && !isNaN(percentage)
            ? Math.max(min, Math.min(percentage, max))
            : undefined,
      },
    });
  };

  useEffect(() => {
    if (initialPercentage !== undefined) {
      handleChange(initialPercentage);
    }
  }, [initialPercentage]);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <Input
      key={inputKey}
      value={initialPercentage}
      {...((id?.startsWith("영향받는 정도") || id === "percentageThought") && {
        placeholder: t_common("영향 정도 placeholder"),
      })}
      type={min < 0 ? "number" : "tel"}
      onChange={(e) => {
        const percentage = parseInt(e.target.value);
        handleChange(percentage);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (isFocused) {
            (document.activeElement as HTMLInputElement)?.blur();
            setIsFocused(false);
          }
        }
      }}
      slotProps={{ input: { min: min || 0, max: max || 100, step: step || 5 } }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      sx={{
        backgroundColor: "white",
        borderRadius: "6px",
        borderWidth: 0,
        boxShadow: isFocused
          ? `inset 0 0 0 1.5px ${theme.vars.palette.primary.solid}`
          : `inset 0 0 0 1px ${theme.vars.palette.divider}`,
        px: "10px",
        py: "12px",
        "--Input-minHeight": 0,
        "--joy-focus-thickness": "0px",
      }}
    />
  );
}

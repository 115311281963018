import ContentTemplate from "./ContentTemplate";
import ValueCompass from "components/value/ValueCompass";

export default function MyValueCompassContent() {
  return (
    <ContentTemplate>
      <ValueCompass />
    </ContentTemplate>
  );
}

import { useEffect, useState } from "react";
import { Box, Option, Select, Stack, Textarea, Typography } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import {
  conceptApi,
  ConceptKeyType_simple,
  ConceptNoteType,
  ConceptType,
  IndividualThoughtKeyType,
  IndividualThoughtNoteType,
  IndividualThoughtType,
  ReferenceLinkType,
} from "api/conceptApi";
import { useMutation } from "@tanstack/react-query";
import * as _ from "lodash";
import Link from "components/common/Link";
import ButtonWithOptionalModal from "components/common/ButtonWithOptionalModal";
import {
  DataId1Type,
  DataId2Type,
  Id2Type,
  Id3Type,
  IdType,
  NoteDataId1Type,
  NoteDataId2Type,
} from "./InsideC";
import { debounce } from "lodash";
import SaveConceptNoteWithReasonButton from "./SaveConceptNoteWithReasonButton";
import ReferenceLink from "components/conceptNote/ReferenceLink";
import ReferenceList from "./ReferenceList";

function updateNoteData({
  setNoteData,
  id1,
  id2,
  index,
  id3,
  text,
}: {
  setNoteData: React.Dispatch<
    React.SetStateAction<ConceptNoteType | undefined>
  >;
  id1: IdType;
  id2?: Id2Type;
  index?: number;
  id3?: Id3Type;
  text?: string;
}) {
  if (id2) {
    setNoteData((data) => {
      if (data && id2 !== "thoughtRecordKey") {
        let data_temp = _.cloneDeep(data);
        if (id2 !== "thoughtTrapList" && id2 !== "thoughtList") {
          (data_temp[id1] as NoteDataId1Type)[id2] = {
            value: text,
            isHumanCheckRequired: false,
          };
        } else if (index !== undefined) {
          if (id3 && id2 === "thoughtList") {
            (
              ((data_temp[id1] as NoteDataId1Type)[id2] as NoteDataId2Type[])[
                index
              ] as IndividualThoughtNoteType
            )[id3] = {
              value: text,
              isHumanCheckRequired: false,
            };
          } else {
            (data_temp[id1] as NoteDataId1Type)[id2]?.splice(index, 1, {
              value: text,
              isHumanCheckRequired: false,
            });
          }
        }
        return data_temp;
      }
      return data;
    });
  } else {
    setNoteData((data) => {
      if (data) {
        let data_temp = _.cloneDeep(data);
        data_temp[id1 as ConceptKeyType_simple] = {
          value: text,
          isHumanCheckRequired: false,
        };
        return data_temp;
      }
      return data;
    });
  }
}

function setHumanText({
  setData,
  id1,
  id2,
  index,
  id3,
  text,
}: {
  setData: React.Dispatch<React.SetStateAction<ConceptType | undefined>>;
  id1: IdType;
  id2?: Id2Type;
  index?: number;
  id3?: Id3Type;
  text: string;
}) {
  if (id2) {
    setData((data) => {
      if (data) {
        let data_temp = _.cloneDeep(data);
        if (id2 !== "thoughtTrapList" && id2 !== "thoughtList") {
          if (data_temp[id1]) {
            (data_temp[id1] as DataId1Type)[id2] = text;
          }
        } else if (index !== undefined) {
          if (
            id2 === "thoughtList" &&
            (data_temp[id1] as DataId1Type)[id2] &&
            id3
          ) {
            const thoughtList = (data_temp[id1] as DataId1Type)[
              id2
            ] as DataId2Type[];
            const individualThought = thoughtList[
              index
            ] as IndividualThoughtType;
            if (!individualThought) {
              thoughtList[index] = {
                thought: "",
                thoughtTrap: "",
              };
            }
            thoughtList[index][id3] = text;
          } else {
            (data_temp[id1] as DataId1Type)[id2]?.splice(index, 1, text);
          }
        }
        return data_temp;
      }
      return data;
    });
  } else {
    setData((data) => {
      if (data) {
        let data_temp = _.cloneDeep(data);
        data_temp[id1 as ConceptKeyType_simple] = text;
        return data_temp;
      }
      return data;
    });
  }
}
export default function InputWithAiRecommendation({
  type,
  thoughtList,
  initialValue,
  isHumanCheckRequired,
  setData,
  setNoteData,
  id1,
  id2,
  index,
  id3,
  options,
  isRequireModificationReason,
  referenceList,
  showReferenceList,
  readOnly,
}: {
  type: "textarea" | "select";
  thoughtList?: IndividualThoughtType[];
  initialValue?: string;
  isHumanCheckRequired?: boolean;
  setData: React.Dispatch<React.SetStateAction<ConceptType | undefined>>;
  setNoteData: React.Dispatch<
    React.SetStateAction<ConceptNoteType | undefined>
  >;
  id1: IdType;
  id2?: Id2Type;
  index?: number;
  id3?: Id3Type;
  options?: string[];
  isRequireModificationReason?: boolean;
  referenceList?: ReferenceLinkType[];
  showReferenceList?: boolean;
  readOnly?: boolean;
}) {
  const patientState = useRecoilValue(patientAtom);
  const [showRedBorder, setShowRedBorder] = useState<boolean | undefined>(
    isHumanCheckRequired
  );

  const [value, setValue] = useState<string | undefined>(
    initialValue || undefined
  );

  useEffect(() => {
    setValue(initialValue || undefined);
  }, [initialValue]);

  const ignoreNull = (value?: string) => {
    return value === "null" ? "" : (value || "").trim();
  };

  const [isEdited, setIsEdited] = useState<boolean>(
    !_.isEqual(ignoreNull(initialValue), ignoreNull(value))
  );

  useEffect(() => {
    setIsEdited(!_.isEqual(ignoreNull(initialValue), ignoreNull(value)));
  }, [initialValue, value]);

  const [modificationReason, setModificationReason] = useState<string>("");

  useEffect(() => {
    setShowRedBorder(isHumanCheckRequired);
  }, [isHumanCheckRequired]);

  const finalValue = value || "";
  const finalUpdateValue = finalValue?.length === 0 ? "none" : finalValue;

  const finalUpdateThoughtList = _.cloneDeep(thoughtList);

  if (index !== undefined && thoughtList) {
    finalUpdateThoughtList?.splice(index, 1, {
      ...thoughtList[index],
      [id3 as IndividualThoughtKeyType]: finalUpdateValue,
    });
  }

  const updateData = id2
    ? {
        [id1]: {
          [id2]: finalUpdateThoughtList
            ? finalUpdateThoughtList
            : finalUpdateValue,
        },
        reason: modificationReason,
      }
    : { [id1]: finalUpdateValue, reason: modificationReason };
  const { mutate: noteUpdate } = useMutation(
    () =>
      conceptApi.noteUpdate(
        `${patientState?.patientId}`,
        updateData as ConceptType
      ),
    {
      onSuccess: () => {
        updateNoteData({
          setNoteData,
          id1,
          id2,
          index,
          id3,
          text: finalValue,
        });
        setModificationReason("");
      },
    }
  );

  const debouncedChangeHandler = debounce(setHumanText, 500);

  useEffect(() => {
    debouncedChangeHandler({
      setData,
      id1,
      id2,
      index,
      id3,
      text: value || "",
    });
  }, [value]);

  return (
    <Stack
      spacing={0.5}
      key={`${id1}_${id2}`}
      sx={{ ...(readOnly && { pointerEvents: "none" }) }}
    >
      {type === "textarea" ? (
        <Textarea
          // key={`${id1}_${id2}_textarea`}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          sx={{
            width: "100%",
            borderWidth: "2px",
            borderColor: showRedBorder
              ? "danger.outlinedColor"
              : "background.level2",
            backgroundColor: "background.level2",
            "--joy-focus-thickness": "0px",
          }}
        ></Textarea>
      ) : type === "select" && options ? (
        <Select
          key={`${id1}_${id2}_select`}
          className="w-full"
          sx={{
            borderWidth: "2px",
            borderColor: showRedBorder
              ? "danger.outlinedColor"
              : "background.level2",
            backgroundColor: "background.level2",
            "--joy-focus-thickness": "0px",
          }}
          value={value || ""}
          onChange={(e) => {
            const selected = (e?.target as HTMLElement)?.innerText;
            setValue(selected === "잘 모르겠어요" ? "" : selected);
          }}
        >
          {options.map((option, optionIndex) => (
            <Option key={`${id1}_${id2}_${option}`} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      ) : (
        <></>
      )}
      <Stack direction={"row"} sx={{ width: "100%" }} justifyContent="end">
        <Stack sx={{ flex: 1 }}>
          {showReferenceList && (initialValue || "").length > 0 && (
            <ReferenceList
              referenceList={referenceList}
              conceptType={id2 ? `${id1}_${id2}` : `${id1}`}
            />
          )}
        </Stack>

        {isEdited && (
          <SaveConceptNoteWithReasonButton
            type={type}
            aiValue={initialValue}
            value={value}
            isRequireModificationReason={isRequireModificationReason}
            modificationReason={modificationReason}
            setModificationReason={setModificationReason}
            updateAction={noteUpdate}
          />
        )}
      </Stack>
    </Stack>
  );
}

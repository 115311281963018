import { Option, Select, Sheet, Typography } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import { SingleSelectionType } from "data/CellComponent";
import * as _ from "lodash";
import { setReferenceCellAnswer } from "logic/logics";
import React, { useEffect } from "react";

const SelectCell = ({
  defaultValue,
  selectedIndex,
  blockIndex,
  options,
  setData,
  lineIndex,
  cellIndex,
  isCoach,
  isCoachField,
}: {
  defaultValue?: string;
  selectedIndex?: number;
  blockIndex: number;
  options: string[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  isCoach?: boolean;
  isCoachField?: boolean;
}) => {
  //코치 프리셋
  useEffect(() => {
    if (isCoachField && selectedIndex !== undefined && selectedIndex > -1) {
      const selected = options[selectedIndex];
      handleChange(selected, selectedIndex);
    }
  }, [selectedIndex]);

  const handleChange = (selected: string, selectedIndex: number) => {
    setData((data) => {
      if (data) {
        const originalCurrentContent = (data[blockIndex].lines[lineIndex][
          cellIndex
        ].content || {}) as SingleSelectionType;

        const data_temp = _.cloneDeep(data);

        if (
          selected &&
          selectedIndex !== undefined &&
          originalCurrentContent.options
        ) {
          if (isCoachField && originalCurrentContent.id?.includes("-answer")) {
            const quizId = originalCurrentContent.id.split("-")[0];
            setReferenceCellAnswer(data_temp, quizId, selectedIndex);
          }

          const selectedOption = originalCurrentContent.options[selectedIndex];

          const showFromIndex = data_temp.findIndex(
            (element) =>
              element.blockId && element.blockId === selectedOption?.showFromId
          );

          const hideFromIndex = data_temp.findIndex(
            (element) =>
              element.blockId && element.blockId === selectedOption?.hideFromId
          );

          if (showFromIndex > -1) {
            data_temp.forEach((e, i) => {
              if (i > blockIndex && i < showFromIndex) {
                data_temp[i].isHidden = true;
              }
              if (hideFromIndex > -1) {
                if (i >= Math.min(showFromIndex, hideFromIndex)) {
                  if (
                    (showFromIndex < hideFromIndex && i >= hideFromIndex) ||
                    (showFromIndex > hideFromIndex && i < showFromIndex)
                  ) {
                    data_temp[i].isHidden = true;
                  } else {
                    data_temp[i].isHidden = false;
                  }
                }
              }
            });
          }
          data_temp[blockIndex].nextBlockId =
            selectedOption?.showFromId || `select_option_${selected}`;
        } else {
          data_temp.forEach((e, i) => {
            if (i > blockIndex) {
              if (!isCoach) {
                data_temp[i].isHidden = true;
              }
            }
          });
        }

        const currentContent =
          data_temp[blockIndex].lines[lineIndex][cellIndex].content || {};
        data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
          ...currentContent,
          value: selected,
          selectedIndex: selectedIndex,
        };

        return data_temp;
      }

      return data;
    });
  };

  return (
    <Select
      sx={{
        "--joy-palette-background-surface": "white",
        "--joy-palette-neutral-outlinedHoverBg": "white",
        height: "48px",
        width: "100%",
      }}
      key={defaultValue}
      defaultValue={defaultValue}
      onChange={(e) => {
        const selected = (e?.target as HTMLElement)?.innerText;
        const selectedIndex = options.findIndex(
          (element) => element === selected
        );

        if (selected && selectedIndex > -1) {
          handleChange(selected, selectedIndex);
        }
      }}
      renderValue={(value) => (
        <Typography
          level={"body-md"}
          textAlign={"left"}
          className="w-full overflow-hidden whitespace-normal "
        >
          {defaultValue}
        </Typography>
      )}
    >
      {options.map((option, optionIndex) => (
        <Option
          key={`select_option_${blockIndex}_${lineIndex}_${cellIndex}_${optionIndex}`}
          value={option}
        >
          {option}
        </Option>
      ))}
    </Select>
  );
};

export default React.memo(SelectCell);

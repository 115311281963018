import { automaticThoughtIdType } from "api/conceptApi";
import {
  ButtonGroup,
  DividerLine,
  LineWithSolidLabel,
  Percentage,
  SolidLabelLine,
  TypographyFromDataLine,
} from "data/CellComponent";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
} from "../BlockComponent";
import { AutomaticThought, CoachActionBlock, LastCard } from "./common";

export const Content_4_10_A = (
  automaticThoughtId: automaticThoughtIdType,
  step: number
) => {
  const textDataMapping_title = {
    A: { id: `제목`, taskId: `4-8-A_${automaticThoughtId}` },
  };
  const textDataMapping_hypothesis = {
    A: { id: `가설`, taskId: `4-8-A_${automaticThoughtId}` },
  };
  const textDataMapping_content = {
    A: { id: `step_${step}`, taskId: `4-8-A_${automaticThoughtId}` },
  };
  return [
    InstructionWithContent(`typography_0_0_0`, [
      ...LineWithSolidLabel(
        "실험 제목",
        TypographyFromDataLine(textDataMapping_title, "$A$")
      ),
      DividerLine(),
      ...LineWithSolidLabel(
        "실험 가설",
        TypographyFromDataLine(textDataMapping_hypothesis, "$A$", {
          id: "가설",
        })
      ),
      DividerLine(),
      SolidLabelLine(`단계 실험 내용`, { prefixLabelIndex: step }),
      TypographyFromDataLine(textDataMapping_content, "$A$", {
        id: "실험내용",
      }),
      DividerLine(),
      SolidLabelLine(`언제`),
      TypographyFromDataLine(
        {
          A: { id: "언제", taskId: `4-9-A_${automaticThoughtId}#${step}` },
        },
        "$A$"
      ),
      DividerLine(),
      ...[
        SolidLabelLine(`가설이 맞다면`),
        ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
          TypographyFromDataLine(
            {
              A: {
                id: `가설 일치 예상 ${each}`,
                taskId: `4-8-A_${automaticThoughtId}`,
              },
            },
            "$A$"
          ),
        ]),
      ],
      DividerLine(),

      ...[
        SolidLabelLine(`가설이 틀렸다면`),
        ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
          TypographyFromDataLine(
            {
              A: {
                id: `가설 기각 예상 ${each}`,
                taskId: `4-8-A_${automaticThoughtId}`,
              },
            },
            "$A$"
          ),
        ]),
      ],
      DividerLine(),
      ...LineWithSolidLabel(
        "안전행동",
        TypographyFromDataLine(
          { A: { id: "안전행동", taskId: `4-8-A_${automaticThoughtId}` } },
          "$A$"
        )
      ),
      ...LineWithSolidLabel(
        "대처방안",
        TypographyFromDataLine(
          {
            A: {
              id: "안전행동 대처방안",
              taskId: `4-9-A_${automaticThoughtId}#${step}`,
            },
          },
          "$A$"
        )
      ),
      DividerLine(),
      ...LineWithSolidLabel(
        "어려움",
        TypographyFromDataLine(
          {
            A: { id: "어려움", taskId: `4-9-A_${automaticThoughtId}#${step}` },
          },
          "$A$"
        )
      ),
      ...LineWithSolidLabel(
        "대처방안",
        TypographyFromDataLine(
          {
            A: {
              id: "어려움 대처방안",
              taskId: `4-9-A_${automaticThoughtId}#${step}`,
            },
          },
          "$A$"
        )
      ),
    ]),
    InstructionWithDataWithContent(
      { A: { id: "실험내용" } },
      `typography_1_0_0`,
      [
        [
          ButtonGroup(
            [
              {
                translationKey: "실행했어요",
                showFromId: "성공",
                hideFromId: "실패",
              },
              {
                translationKey: "실행하지 못했어요",
                showFromId: "실패",
                hideFromId: "성공",
              },
            ],
            { id: "실험_결과_실행_여부" }
          ),
        ],
      ]
    ),
    Instruction("typography_2_0_0", { blockId: "성공" }),
    InstructionWithDataWithTextarea(
      { A: { id: "실험내용" } },
      "typography_3_0_0"
    ),
    InstructionWithDataWithContent(
      {
        A: { id: `실험내용` },
        B: { id: `가설`, taskId: `4-8-A_${automaticThoughtId}` },
      },
      `typography_4_0_0`,
      [
        [
          ButtonGroup(
            [
              {
                translationKey: "일치했어요",
                showFromId: "가설 일치",
                hideFromId: "가설 기각",
              },
              {
                translationKey: "일치하지 않았어요",
                showFromId: "가설 기각",
                hideFromId: "가설 일치",
              },
            ],
            { id: "실험_결과_가설_일치_여부" }
          ),
        ],
      ]
    ),
    InstructionWithData({ A: { id: "실험내용" } }, "typography_5_0_0", {
      blockId: "가설 일치",
    }),
    Instruction("typography_6_0_0"),
    Instruction("typography_7_0_0"),
    Instruction("typography_8_0_0"),
    Instruction("typography_9_0_0"),
    Instruction("typography_10_0_0"),
    InstructionWithDataWithTextarea(
      { A: { id: "실험내용" } },
      "typography_11_0_0"
    ),
    InstructionWithDataWithTextarea(
      { A: { id: "실험내용" } },
      "typography_12_0_0",
      undefined,
      { nextBlockId: "공통 질문" }
    ),

    InstructionWithDataWithTextarea(
      { A: { id: "실험내용" } },
      "typography_11_0_0",
      undefined,
      {
        blockId: "가설 기각",
      }
    ),

    InstructionWithDataWithTextarea(
      { A: { id: "실험내용" } },
      "typography_13_0_0",
      undefined,
      { blockId: "공통 질문" }
    ),
    InstructionWithContent(
      `typography_14_0_0`,
      AutomaticThought({ id: automaticThoughtId })
    ),
    InstructionWithDataWithTextarea(
      { A: { id: "실험내용" } },
      "typography_15_0_0"
    ),
    InstructionWithDataWithContent(
      { A: { taskId: `4-2-A_${automaticThoughtId}`, id: "영향받는 정도" } },
      "typography_16_0_0",
      [[Percentage({ id: "영향받는 정도" })]],
      { nextBlockId: "마무리" }
    ),
    Instruction("typography_17_0_0", { blockId: "실패", isHidden: true }),
    Instruction("typography_18_0_0", { isHidden: true }),
    Instruction("typography_19_0_0", { isHidden: true }),
    Instruction("typography_20_0_0", { isHidden: true }),
    InstructionWithDataWithTextarea(
      { A: { id: "실험내용" } },
      "typography_21_0_0",
      undefined,
      { isHidden: true }
    ),
    ...CoachActionBlock,
    Instruction("typography_22_0_0", { blockId: "마무리" }),

    ...LastCard,
  ];
};

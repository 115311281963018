import ButtonWithModal from "components/common/ButtonWithModal";
import TaskItemTemplate from "./TaskItemTemplate";

export default function TaskItemWithModal({
  taskKey,
  isOpen,
  isDone,
  isLocked,
  hasMissingValue,
  setNumber,
  hasConnectionLine,
  connectionTop,
  connectionHeight,
  isFirstOfSet,
  isLastOfSet,
}: {
  taskKey: string;
  isOpen: boolean;
  isDone: boolean;
  isLocked?: boolean;
  hasMissingValue?: boolean;
  setNumber?: number;
  hasConnectionLine?: boolean;
  connectionTop?: number;
  connectionHeight?: number;
  isFirstOfSet?: boolean;
  isLastOfSet?: boolean;
}) {
  return (
    <ButtonWithModal
      buttonText={""}
      title={"디스턴싱 시작하기를 먼저 마쳐주세요"}
      textAlign="center"
      actionText="확인"
      render={
        <TaskItemTemplate
          taskKey={taskKey}
          isOpen={isOpen}
          isDone={isDone}
          isLocked={isLocked}
          hasMissingValue={hasMissingValue}
          disableDirectAction
          setNumber={setNumber}
          hasConnectionLine={hasConnectionLine}
          connectionTop={connectionTop}
          connectionHeight={connectionHeight}
          isFirstOfSet={isFirstOfSet}
          isLastOfSet={isLastOfSet}
        />
      }
    ></ButtonWithModal>
  );
}

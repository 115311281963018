export const translation_6_1_A = {
  en: {
    v1: {
      typography_0_0_0:
        "We've looked at automatic thoughts and the traps they can create from different angles.",
      typography_1_0_0:
        "You might have realized some thoughts that pop into your head aren't accurate or helpful.",
      typography_2_0_0:
        "You've practiced viewing thoughts just as they are, without judgment.",
      typography_3_0_0: "Let's revisit the idea:",
      typography_4_0_0:
        "Automatic thoughts come to our minds almost unconsciously, that's why they're called 'automatic'.",
      typography_5_0_0:
        "They appear reflexively in certain situations, not necessarily as a result of deep thinking.",
      typography_6_0_0: "They seem to emerge on their own, and quite quickly.",
      typography_7_0_0: "We might not even be aware of these thoughts.",
      typography_8_0_0:
        "We simply think, feel, and act based on what comes to mind.",
      typography_9_0_0:
        "In this way, we react automatically to our automatic thoughts.",
      typography_10_0_0: "Roots of Automatic Thoughts",
      typography_11_0_0: "Why do these automatic thoughts occur?",
      typography_12_0_0:
        "To understand this, we need to explore the 'Thought Map'.",
      typography_13_0_0:
        "We briefly mentioned this in the context of <Automatic Thoughts>. Let's delve deeper today.",
      typography_14_0_0:
        "Some scholars have discovered that people have a structure of thoughts ranging from core to superficial.",
      typography_15_0_0: "This structure is what we call a Thought Map.",
      typography_16_0_0:
        "A Thought Map is like a circuit established in our mind.",
      typography_17_0_0:
        "Circuits formed by strong synaptic connections are activated repeatedly over a lifetime, creating specific patterns of thought.",
      typography_18_0_0: "There are four components to a Thought Map:",
      typography_19_0_0:
        "Core Experiences, Core Beliefs, Coping Strategies, and Automatic Thoughts. Let's look at each.",
      typography_20_0_0: "Core Experience",
      typography_21_0_0: "First, 'Core Experience'.",
      typography_22_0_0:
        "This refers to experiences that had a significant impact on our lives.",
      typography_23_0_0:
        "These experiences often happen in childhood, but can also occur in adulthood.",
      typography_24_0_0:
        "For example, Jordi grew up with a mother who disciplined him with high standards.",
      typography_25_0_0: `He always felt the pressure to "do better than others".`,
      typography_26_0_0:
        "Jordi didn't resent his mother; she was a respected person.",
      typography_27_0_0:
        "He studied hard to gain her approval, but felt like he could never measure up.",
      typography_28_0_0: "Core Belief",
      typography_29_0_0: "Core Experiences create 'Core Beliefs'.",
      typography_30_0_0:
        "These are fundamental thoughts about oneself, others, and the world.",
      typography_31_0_0:
        "Core Beliefs are deep in the unconscious, so people often aren't aware of them.",
      typography_32_0_0:
        "They usually form from repeated experiences in early life.",
      typography_33_0_0:
        "They're like unconscious self-images about 'me, others, and the world'. For instance:",
      typography_34_0_0: "Returning to Jordi's case:",
      typography_35_0_0:
        "Growing up with a demanding mother, Jordi naturally developed thoughts like:",
      typography_36_0_0: `"I'm not enough. I'm lacking. Being insufficient, I can't get attention or be liked."`,
      typography_37_0_0:
        "Jordi wasn't aware of these thoughts until adulthood, through cognitive therapy.",
      typography_38_0_0: "Coping Strategy",
      typography_39_0_0:
        "The Core Beliefs, settled in the unconscious, were threatening to Jordi.",
      typography_40_0_0:
        "He started feeling painful emotions like inadequacy, shame, and loneliness.",
      typography_41_0_0: `Facing the thought "I'm not enough" was too painful.`,
      typography_42_0_0:
        "Jordi's mind naturally developed 'Coping Strategies' to handle these Core Beliefs.",
      typography_43_0_0:
        "Coping Strategies are responses to the threat of Core Beliefs.",
      typography_44_0_0:
        "There are three main strategies: Surrender, Overcompensation, and Avoidance.",
      typography_45_0_0:
        "Surrender means following the content of the Core Belief.",
      typography_46_0_0:
        "Overcompensation involves acting as if the opposite of the Core Belief is true, to prove it wrong.",
      typography_47_0_0:
        "Avoidance means living as if the Core Belief doesn't exist and avoiding thoughts about it.",
      typography_48_0_0:
        "We'll explore more later. Let's continue with Jordi's example.",
      typography_49_0_0: "Jordi's coping strategy was Overcompensation.",
      typography_50_0_0: `That is, trying to prove "I am enough" in response to "I am lacking".`,
      typography_51_0_0:
        "As a result of overcompensating, Jordi developed perfectionism.",
      typography_52_0_0: "Automatic Thought",
      typography_53_0_0:
        "Remember how we described automatic thoughts as 'thoughts that spontaneously and unconsciously arise in certain situations'?",
      typography_54_0_0: "These aren't random;",
      typography_55_0_0: "they stem from Core Beliefs and Coping Strategies.",
      typography_56_0_0: "Let's revisit Jordi's case:",
      typography_57_0_0: "While preparing for a presentation (situation),",
      typography_58_0_0: `Jordi automatically thought, "It has to be perfect", "Any mistake could be disastrous. Focus!" (automatic thought).`,
      typography_59_0_0:
        "Feeling anxious and jittery, Jordi experienced headaches (emotions/body).",
      typography_60_0_0:
        "He kept reviewing his presentation until bed (behavior).",
      typography_61_0_0: "Jordi barely slept that night.",
      typography_62_0_0: "Cycle of reinforcement",
      typography_63_0_0: "Here's an interesting fact:",
      typography_64_0_0:
        "Automatic thoughts, created as a strategy against Core Beliefs, often end up reinforcing those very beliefs.",
      typography_65_0_0: "Looking back at Jordi:",
      typography_66_0_0: `His perfectionism, a counteraction to "I am lacking", slowly consumed him.`,
      typography_67_0_0:
        "Striving for perfection led to burnout, depression, and intense anxiety.",
      typography_68_0_0: `This reinforced his Core Belief of "I am lacking", starting a vicious cycle.`,
      typography_69_0_0: "Deeper understanding",
      typography_70_0_0:
        "Now, do you understand how Core Experiences lead to Core Beliefs, which then form Coping Strategies and Automatic Thoughts?",
      typography_71_0_0:
        "This sequence from Core Experience to Automatic Thought is the Thought Map.",
      typography_72_0_0:
        "Going forward, we'll try to find our own Thought Maps, understand their functions, and create distance from them.",
      typography_73_0_0:
        "We'll dive deeper beyond the surface, exploring our Automatic Thoughts, their underlying Core Beliefs, and developed Coping Strategies.",
      typography_74_0_0: `Discovering your Thought Map can be fascinating, surprising, and even comforting.`,
      typography_75_0_0: `But it can also feel painful, scary, sad, and worrying.`,
      typography_76_0_0: `Remember the goal of Distancing:`,
      typography_77_0_0: `We learned how to distance ourselves from distressing automatic thoughts.`,
      typography_78_0_0: `We practiced not reacting reflexively to them, reducing discomfort.`,
      typography_79_0_0: `The same applies to exploring our Thought Maps.`,
      typography_80_0_0: `It's about gaining freedom from more fundamental and core thoughts.`,
      typography_81_0_0: `Of course, this requires a step-by-step approach.`,
      typography_82_0_0: `It's challenging to distance oneself from Core Beliefs immediately.`,
      typography_83_0_0: `So, let's start by examining our Coping Strategies, Core Beliefs, and the Core Experiences that formed them, gradually mapping out our thoughts together.`,
    },
    v2: {
      typography_0_0_0:
        "We've looked at automatic thoughts and the traps they can create from different angles.",
      typography_1_0_0:
        "You might have realized some thoughts that pop into your head aren't accurate or helpful.",
      typography_2_0_0:
        "You've practiced viewing thoughts just as they are, without judgment.",
      typography_3_0_0: "Let's revisit the idea:",
      typography_4_0_0:
        "Automatic thoughts come to our minds almost unconsciously, that's why they're called 'automatic'.",
      typography_5_0_0:
        "They appear reflexively in certain situations, not necessarily as a result of deep thinking.",
      typography_6_0_0: "They seem to emerge on their own, and quite quickly.",
      typography_7_0_0: "We might not even be aware of these thoughts.",
      typography_8_0_0:
        "We simply think, feel, and act based on what comes to mind.",
      typography_9_0_0:
        "In this way, we react automatically to our automatic thoughts.",
      typography_10_0_0: "Roots of Automatic Thoughts",
      typography_11_0_0: "Why do these automatic thoughts occur?",
      typography_12_0_0:
        "To understand this, we need to explore the 'Thought Map'.",
      typography_13_0_0:
        "We briefly mentioned this in the context of <Automatic Thoughts>. Let's delve deeper today.",
      typography_14_0_0:
        "Some scholars have discovered that people have a structure of thoughts ranging from core to superficial.",
      typography_15_0_0: "This structure is what we call a Thought Map.",
      typography_16_0_0:
        "A Thought Map is like a circuit established in our mind.",
      typography_17_0_0:
        "Circuits formed by strong synaptic connections are activated repeatedly over a lifetime, creating specific patterns of thought.",
      typography_18_0_0: "There are four components to a Thought Map:",
      typography_19_0_0:
        "Core experience, core belief, intermediate belief, and automatic thought. Let's check each out.",
      typography_20_0_0: "Core Experience",
      typography_21_0_0: "First off, 'Core Experiences'.",
      typography_22_0_0:
        "This refers to experiences that had a significant impact on our lives.",
      typography_23_0_0:
        "These experiences often happen in childhood, but can also occur in adulthood.",
      typography_24_0_0:
        "Like, let's say Jordi grew up with a mom who was all about high standards.",
      typography_25_0_0: `He always felt the pressure to "do better than others".`,
      typography_26_0_0:
        "Jordi didn't resent his mother; she was a respected person.",
      typography_27_0_0:
        "He studied hard to gain her approval, but felt like he could never measure up.",
      typography_28_0_0: "Core Belief",
      typography_29_0_0: "From those big-deal experiences come 'Core Beliefs'.",
      typography_30_0_0:
        "These are fundamental thoughts about oneself, others, and the world.",
      typography_31_0_0:
        "They're buried deep and we don't usually know they're there.",
      typography_32_0_0:
        "They usually form from repeated experiences in early life.",
      typography_33_0_0:
        "They're like unconscious self-images about 'me, others, and the world'. For instance:",
      typography_34_0_0: "Returning to Jordi's case:",
      typography_35_0_0:
        "Growing up with a demanding mother, Jordi naturally developed thoughts like:",
      typography_36_0_0: `"I'm not enough. I'm lacking. People won't notice me because I'm lacking, they won't like me."`,
      typography_37_0_0:
        "Jordi wasn't aware of these thoughts until adulthood, through cognitive therapy.",
      typography_38_0_0: "Intermediate Belief",
      typography_39_0_0:
        "These deep-down core beliefs felt threatening to Jordi.",
      typography_40_0_0:
        "He started feeling painful emotions like inadequacy, shame, and loneliness.",
      typography_41_0_0: `Facing the thought "I'm not enough" was too painful.`,
      typography_42_0_0:
        "So, Jordi's mind came up with ways to handle these core beliefs.",
      typography_43_0_0: "We call these 'Intermediate Beliefs'.",
      typography_44_0_0:
        "They're rules, attitudes, and assumptions we make to deal with core beliefs.",
      typography_45_0_0: `Rules are like life principles: "I must do this. I must not do that".`,
      typography_46_0_0: `Attitudes are how we see life: "people are bad".`,
      typography_47_0_0: `Assumptions are beliefs about what will happen in certain situations: "If this, then that".`,
      typography_48_0_0:
        "We'll explore more later. Let's continue with Jordi's example.",
      typography_49_0_0: "Jordi developed intermediate beliefs like:",
      typography_50_0_0: `A rule that "I must be perfect",`,
      typography_51_0_0: `An attitude that "failure is awful",`,
      typography_52_0_0: `And an assumption that "if I mess up, everything's ruined".`,
      typography_53_0_0: `These thoughts were too focused on being perfect, but they helped Jordi deal with his core beliefs.`,

      typography_54_0_0: "Automatic Thought",
      typography_55_0_0:
        "Remember how we described automatic thoughts as 'thoughts that spontaneously and unconsciously arise in certain situations'?",
      typography_56_0_0: "These aren't random;",
      typography_57_0_0: "They're built on our core and intermediate beliefs.",
      typography_58_0_0: "Let's revisit Jordi's case:",
      typography_59_0_0:
        "Jordi was prepping for a work presentation (situation).",
      typography_60_0_0: 'With his core belief "I am lacking",',
      typography_61_0_0: `And living with the intermediate beliefs "I must be perfect, failure is awful, if I mess up, everything's ruined",`,
      typography_62_0_0: `Jordi thought: "What if I get asked something I don't know? What if I can't answer? I need to focus." (automatic thought)`,
      typography_63_0_0: "Feeling nervous and headachy (emotions/body),",
      typography_64_0_0:
        "Jordi kept reviewing his presentation until bedtime (behavior).",
      typography_65_0_0: "Jordi barely slept that night.",
      typography_66_0_0: "A Never-ending cycle",
      typography_67_0_0: "Here's an interesting fact:",
      typography_68_0_0:
        "The thoughts from our core and intermediate beliefs often end up making our core beliefs even stronger.",
      typography_69_0_0: "Looking back at Jordi:",
      typography_70_0_0: `His perfectionism, made to handle "I am lacking", slowly wore him down.`,
      typography_71_0_0:
        "Striving for perfection led to burnout, depression, and intense anxiety.",
      typography_72_0_0: `In the end, "I am lacking" got even stronger. And so, the cycle continues.`,
      typography_73_0_0: "Deeper understanding",
      typography_74_0_0:
        "Now, do you get how our core experiences lead to core beliefs, which then lead to intermediate beliefs and automatic thoughts?",
      typography_75_0_0:
        "This sequence from core experiences to automatic thoughts is the Thought Map.",
      typography_76_0_0:
        "Going forward, we'll try to find our own Thought Maps, understand their functions, and create distance from them.",
      typography_77_0_0: "We're going beyond just the surface, diving deeper.",
      typography_78_0_0:
        "We'll explore what core beliefs lead to our automatic thoughts, and what intermediate beliefs we've developed to handle those beliefs.",
      typography_79_0_0: `Discovering your Thought Map can be fascinating, surprising, and even comforting.`,
      typography_80_0_0: `But it can also feel painful, scary, sad, and worrying.`,
      typography_81_0_0: `Remember the goal of Distancing:`,
      typography_82_0_0: `We learned how to distance ourselves from distressing automatic thoughts.`,
      typography_83_0_0: `We practiced not reacting reflexively to them, reducing discomfort.`,
      typography_84_0_0: `The same applies to exploring our Thought Maps.`,
      typography_85_0_0: `It's about gaining freedom from more fundamental and core thoughts.`,
      typography_86_0_0: `Of course, this requires a step-by-step approach.`,
      typography_87_0_0: `Starting with stepping back from core beliefs can be hard.`,
      typography_88_0_0: `So, let's first figure out what our intermediate beliefs are, what our core beliefs are, and what experiences created those core beliefs, one step at a time.`,
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "우리는 앞서 자동적 사고에 포함된 생각함정을 다양한 측면에서 살펴봤어요.",
      typography_1_0_0:
        "머리속에 떠오른 생각 중 어떠한 생각들은 정확하지 않거나 도움되지 않는다는 것을 확인해보았을 거예요.",
      typography_2_0_0: "생각을 그저 생각으로 바라보는 연습도 해보셨을 테죠.",
      typography_3_0_0: "자, 다시 한번 떠올려볼까요?",
      typography_4_0_0:
        "자동적 사고는 마음속에 거의 무의식적으로 떠오르는 것이기 때문에 ‘자동적’이라고 불렀어요.",
      typography_5_0_0:
        "이러한 생각은 특정 상황에서 거의 반사적으로 나타나요. 숙고가 아니죠.",
      typography_6_0_0:
        "오히려 이러한 생각은 저절로 생겨나는 것 같기도 해요. 그것도 매우 빠르게 말이에요.",
      typography_7_0_0:
        "우리는 이러한 생각을 거의 인지하지 못했을 수도 있어요.",
      typography_8_0_0:
        "그저 마음속에 떠오른대로 생각하고, 느끼고, 행동했을 뿐이죠.",
      typography_9_0_0: "이렇듯 우리는 자동적 사고에 자동적으로 반응해요.",
      typography_10_0_0: "자동적 사고의 뿌리를 찾아서",
      typography_11_0_0: "그런데 이 자동적 사고는 대체 왜 만들어지는 걸까요?",
      typography_12_0_0: "이것을 이해하려면 '생각 지도'를 이해해야 해요.",
      typography_13_0_0:
        "앞서 <자동적인 생각>에서 생각 지도에 대해 잠깐 언급하였는데요. 오늘은 조금 더 깊이 살펴볼게요.",
      typography_14_0_0:
        "몇몇 위대한 학자들은 사람들이 핵심적인 생각부터 표면적인 생각까지 이어지는 일련의 생각 구조를 가진다는 것을 발견했는데요.",
      typography_15_0_0: "그러한 생각 구조를 생각 지도라고 부른답니다.",
      typography_16_0_0:
        "생각 지도는 머릿속에서 자리 잡은 일종의 회로라고도 볼 수 있어요.",
      typography_17_0_0:
        "강력한 시냅스 결합으로 만들어진 회로는 평생에 걸쳐 반복적으로 활성화되며 특정한 형태의 생각들을 만들어내죠.",
      typography_18_0_0: "생각 지도에는 네 가지 구성 요소가 있어요.",
      typography_19_0_0:
        "핵심 경험, 핵심 믿음, 대처 전략, 그리고 자동적 사고인데요. 하나씩 살펴보도록 할게요.",
      typography_20_0_0: "핵심 경험",
      typography_21_0_0: "먼저 '핵심 경험'이에요.",
      typography_22_0_0: "핵심 경험은 '삶에서 큰 영향이 있었던 경험'을 뜻해요.",
      typography_23_0_0:
        "이때 경험은 주로 어릴 적에 겪었던 일일 때가 많지만, 경우에 따라 성인기에 핵심 경험이 발생하기도 한답니다.",
      typography_24_0_0:
        "예를 들어볼게요. 조르디는 어릴 적부터 높은 기준을 가지고 훈육하는 엄마 밑에서 자랐어요.",
      typography_25_0_0: `항상 "남들보다 더 잘 해야 한다"라는 부모님의 압박을 느껴왔죠.`,
      typography_26_0_0:
        "조르디는 엄마의 말에 큰 반감을 가지진 않았어요. 엄마는 어디서나 인정 받는 멋진 사람이었거든요.",
      typography_27_0_0:
        "조르디는 엄마에게 인정 받고 싶어 더 열심히 공부했어요. 하지만 그럼에도 엄마는 항상 내가 닳을 수는 없는 존재인 것만 같았죠.",
      typography_28_0_0: "핵심 믿음",
      typography_29_0_0: "핵심 경험은 '핵심 믿음'을 만들어내요.",
      typography_30_0_0:
        "핵심 믿음은 나, 타인, 세상에 대한 근본적인 생각을 뜻해요.",
      typography_31_0_0:
        "핵심 믿음은 무의식의 아주 깊은 곳에 있어서 사람들은 자신에게 어떤 믿음이 있는지 쉽게 알지 못하죠.",
      typography_32_0_0:
        "핵심 믿음은 주로 어릴 적부터 반복적으로 누적되어온 경험에 의해서 형성될 때가 많아요. ",
      typography_33_0_0:
        "가랑비에 옷이 젖듯이 무의식에 새겨진 ‘나, 타인, 세상’을 향한 자화상이라고 할 수 있죠. 예를 들어볼까요?",
      typography_34_0_0: "다시 조르디의 예를 살펴볼게요.",
      typography_35_0_0:
        "높은 기준을 가지고 있는 엄마 밑에서 자란 조르디는 자연스럽게 다음과 같은 생각을 가지게 되었어요.",
      typography_36_0_0: `"나는 충분하지 않아. 나는 부족해. 부족한 나는 관심을 받을 수 없어, 사람들은 날 좋아해주지 않아"`,
      typography_37_0_0:
        "물론 조르디가 그 생각을 인지하고 있었던 건 아니랍니다. 성인이 된 이후 인지치료를 통해서 알게 된 사실이죠.",
      typography_38_0_0: "대처 전략",
      typography_39_0_0:
        "무의식 중에 자리 잡은 핵심 믿음은 조르디에게 위협적이었어요.",
      typography_40_0_0:
        "조르디의 마음에 위와 같은 생각이 자리 잡은 뒤로부터 고통스러운 감정이 느껴지기 시작합니다. 부적절감, 수치심, 외로움과 같은 감정이죠.",
      typography_41_0_0: `"나는 부족하다"라는 생각을 마주하며 고통스러운 감정을 느끼기에는 너무 괴로웠어요.`,
      typography_42_0_0:
        "자연스럽게 조르디의 마음은 그러한 핵심 믿음에 대한 '대처 전략'을 마련했어요.",
      typography_43_0_0:
        "대처 전략은 '핵심 믿음의 위협에 대처하기 위해 보이는 반응'이라고 이야기 할 수 있어요.",
      typography_44_0_0:
        "크게 '굴복', '과잉보상', '회피' 세 가지 대처 전략이 있답니다.",
      typography_45_0_0: "굴복은 핵심 믿음의 내용에 그대로 따르는 것이고",
      typography_46_0_0:
        "과잉보상은 핵심 믿음이 틀렸음을 증명하기 위해 노력하며, 마치 핵심 믿음의 정반대가 진실인 것처럼 반응하는 것이에요.",
      typography_47_0_0:
        "회피는 핵심 믿음이 마치 존재하지 않는 것처럼 지내면서 이에 대한 생각을 피하는 것이에요.",
      typography_48_0_0:
        "더 자세한 내용은 뒤에서 알아볼게요. 우선은 조르디의 예시를 살펴보죠.",
      typography_49_0_0: "조르디의 대처 전략은 과잉보상이었어요.",
      typography_50_0_0: `즉, "나는 부족하다"라는 생각이이 틀렸음을 증명하고자 "나는 충분하다"라고 이야기하려고 했던 것이죠.`,
      typography_51_0_0: `"나는 부족하다"는 생각에 대한 과잉보상으로 조르디는 완벽주의를 얻게 되었어요.`,
      typography_52_0_0: "자동적 사고",
      typography_53_0_0:
        "앞서 <자동적인 생각>에서 자동적 사고를 '특정한 상황에서 마음속에 자동적이고 무의식적으로 떠오르는 생각'라고 설명했던 것, 기억하시나요?",
      typography_54_0_0: "이 자동적 사고는 아무렇게나 만들어진 건 아니에요.",
      typography_55_0_0:
        "자동적 사고는 핵심 믿음과 대처 전략으로 만들어진 생각이기 때문이에요",
      typography_56_0_0: "조르디의 예를 살펴볼까요?",
      typography_57_0_0: `"나는 부족하다"라는 핵심 믿음에 대해 과잉보상으로 대처하고 있던 조르디는 책상 앞에 앉아 회사 발표를 준비하고 있었어요(상황).`,
      typography_58_0_0: `조르디는 순간 자동적으로 생각했죠. "완벽하게 해야 해", "실수라도 했다간 끝장이야. 집중하자"(자동적 사고)`,
      typography_59_0_0:
        "불안하고 초조해진 조르디는 두통을 느꼈어요(감정/신체).",
      typography_60_0_0:
        "하지만 침대에 눕기 직전까지 발표 자료를 검토하였답니다(행동).",
      typography_61_0_0: "아, 물론 그날 밤 조르디는 거의 한숨도 못 잤어요.",
      typography_62_0_0: "악순환의 연속",
      typography_63_0_0: "한 가지 흥미로운 사실이 있어요.",
      typography_64_0_0:
        "핵심 믿음에 대한 대처 전략으로 만들어낸 자동적 사고는 흔히 다시 핵심 믿음을 더욱 강화한다는 것이죠.",
      typography_65_0_0: "조르디의 예를 살펴볼까요?",
      typography_66_0_0: `"나는 부족하다"라는 생각에 대해 과잉보상하며 만들어낸 완벽주의는 조금씩 조르디를 갉아먹게 돼요.`,
      typography_67_0_0:
        "강박적으로 완벽에 집착하다 보니 번아웃과 우울, 또 강렬한 불안에 빠지기도 하죠.",
      typography_68_0_0: `결국 "나는 부족하다"라는 핵심 믿음은 더욱 강해지죠. 그렇게 악순환이 시작돼요.`,
      typography_69_0_0: "더 깊은 내면으로",
      typography_70_0_0:
        "자, 이제 핵심 경험으로부터 만들어진 핵심 믿음과 이에 대한 대처 전략으로 자동적 사고가 만들어진다는 것, 잘 이해가 되셨을까요?",
      typography_71_0_0:
        "이와 같이 핵심 경험으로부터 자동적 사고로 이어지는 생각 구조가 바로 생각 지도예요.",
      typography_72_0_0:
        "앞으로는 나의 생각 지도를 찾고, 그 생각 지도의 기능에 대해 잘 이해한 후, 그로부터 거리를 두는 연습을 해보려고 해요.",
      typography_73_0_0:
        "이제는 표면을 지나, 좀 더 깊은 내면으로 내려가보는 거예요. 나의 자동적 사고는 어떤 핵심 믿음으로부터 발생했으며, 어떤 대처 전략들을 발전시켜왔는지요.",
      typography_74_0_0:
        "미처 깨닫지 못했던 나의 생각 지도를 발견하는 일은 재밌고, 신기하고, 반갑기도 할 거예요. ",
      typography_75_0_0:
        "한편으로는 상처를 헤집는 것처럼 느껴져서 아프고, 무섭고, 슬프고, 걱정이 될 수 있어요. ",
      typography_76_0_0:
        "자, 여기서 디스턴싱의 목적을 다시 한번 기억해볼게요. ",
      typography_77_0_0:
        "우리는 앞에서 괴로웠던 자동적 사고로부터 거리를 둘 수 있는 방법을 배웠어요.",
      typography_78_0_0:
        "자동적 사고에 반사적으로 반응하지 않고, 괴로움을 줄일 수 있는 연습을 해왔죠. ",
      typography_79_0_0: "생각 지도를 확인해가는 일도 동일해요.",
      typography_80_0_0:
        "더 근원적이고 핵심적인 생각으로부터 거리를 두고, 자유로워지기 위함이죠.  ",
      typography_81_0_0:
        "물론 그렇게 하기 위해서는 단계적인 접근이 필요합니다.",
      typography_82_0_0:
        "처음부터 핵심 믿음으로부터 거리를 두기란 어려운 일이기 때문이에요.",
      typography_83_0_0:
        "그러니 우선 나의 대처 전략은 무엇인지, 핵심 믿음은 무엇인지, 그리고 핵심 믿음을 만든 핵심 경험은 무엇인지, 하나씩 살펴보며 생각 지도를 함께 그려나가보아요.",
    },
    v2: {
      typography_0_0_0:
        "우리는 앞서 자동적 사고에 포함된 생각함정을 다양한 측면에서 살펴봤어요.",
      typography_1_0_0:
        "머리속에 떠오른 생각 중 어떠한 생각들은 정확하지 않거나 도움되지 않는다는 것을 확인해보았을 거예요.",
      typography_2_0_0: "생각을 그저 생각으로 바라보는 연습도 해보셨을 테죠.",
      typography_3_0_0: "자, 다시 한번 떠올려볼까요?",
      typography_4_0_0:
        "자동적 사고는 마음속에 거의 무의식적으로 떠오르는 것이기 때문에 ‘자동적’이라고 불렀어요.",
      typography_5_0_0:
        "이러한 생각은 특정 상황에서 거의 반사적으로 나타나요. 숙고가 아니죠.",
      typography_6_0_0:
        "오히려 이러한 생각은 저절로 생겨나는 것 같기도 해요. 그것도 매우 빠르게 말이에요.",
      typography_7_0_0:
        "우리는 이러한 생각을 거의 인지하지 못했을 수도 있어요.",
      typography_8_0_0:
        "그저 마음속에 떠오른대로 생각하고, 느끼고, 행동했을 뿐이죠.",
      typography_9_0_0: "이렇듯 우리는 자동적 사고에 자동적으로 반응해요.",
      typography_10_0_0: "자동적 사고의 뿌리를 찾아서",
      typography_11_0_0: "그런데 이 자동적 사고는 대체 왜 만들어지는 걸까요?",
      typography_12_0_0: "이것을 이해하려면 '생각 지도'를 이해해야 해요.",
      typography_13_0_0:
        "앞서 <자동적인 생각>에서 생각 지도에 대해 잠깐 언급하였는데요. 오늘은 조금 더 깊이 살펴볼게요.",
      typography_14_0_0:
        "몇몇 위대한 학자들은 사람들이 핵심적인 생각부터 표면적인 생각까지 이어지는 일련의 생각 구조를 가진다는 것을 발견했는데요.",
      typography_15_0_0: "그러한 생각 구조를 생각 지도라고 부른답니다.",
      typography_16_0_0:
        "생각 지도는 머릿속에서 자리 잡은 일종의 회로라고도 볼 수 있어요.",
      typography_17_0_0:
        "강력한 시냅스 결합으로 만들어진 회로는 평생에 걸쳐 반복적으로 활성화되며 특정한 형태의 생각들을 만들어내죠.",
      typography_18_0_0: "생각 지도에는 네 가지 구성 요소가 있어요.",
      typography_19_0_0:
        "핵심 경험, 핵심 믿음, 중간 믿음, 그리고 자동적 사고인데요. 하나씩 살펴보도록 할게요.",
      typography_20_0_0: "핵심 경험",
      typography_21_0_0: "먼저 '핵심 경험'이에요.",
      typography_22_0_0: "핵심 경험은 '삶에서 큰 영향이 있었던 경험'을 뜻해요.",
      typography_23_0_0:
        "이때 경험은 주로 어릴 적에 겪었던 일일 때가 많지만, 경우에 따라 성인기에 핵심 경험이 발생하기도 한답니다.",
      typography_24_0_0:
        "예를 들어볼게요. 조르디는 어릴 적부터 높은 기준을 가지고 훈육하는 엄마 밑에서 자랐어요.",
      typography_25_0_0: `항상 "남들보다 더 잘 해야 한다"라는 부모님의 압박을 느껴왔죠.`,
      typography_26_0_0:
        "조르디는 엄마의 말에 큰 반감을 가지진 않았어요. 엄마는 어디서나 인정 받는 멋진 사람이었거든요.",
      typography_27_0_0:
        "조르디는 엄마에게 인정 받고 싶어 더 열심히 공부했어요. 하지만 그럼에도 엄마는 항상 내가 닳을 수는 없는 존재인 것만 같았죠.",
      typography_28_0_0: "핵심 믿음",
      typography_29_0_0: "핵심 경험은 '핵심 믿음'을 만들어내요.",
      typography_30_0_0:
        "핵심 믿음은 나, 타인, 세상에 대한 근본적인 생각을 뜻해요.",
      typography_31_0_0:
        "핵심 믿음은 무의식의 아주 깊은 곳에 있어서 사람들은 자신에게 어떤 믿음이 있는지 쉽게 알지 못하죠.",
      typography_32_0_0:
        "핵심 믿음은 주로 어릴 적부터 반복적으로 누적되어온 경험에 의해서 형성될 때가 많아요. ",
      typography_33_0_0:
        "가랑비에 옷이 젖듯이 무의식에 새겨진 ‘나, 타인, 세상’을 향한 자화상이라고 할 수 있죠. 예를 들어볼까요?",
      typography_34_0_0: "다시 조르디의 예를 살펴볼게요.",
      typography_35_0_0:
        "높은 기준을 가지고 있는 엄마 밑에서 자란 조르디는 자연스럽게 다음과 같은 생각을 가지게 되었어요.",
      typography_36_0_0: `"나는 충분하지 않아. 나는 부족해. 부족한 나는 관심을 받을 수 없어, 사람들은 날 좋아해주지 않아"`,
      typography_37_0_0:
        "물론 조르디가 그 생각을 인지하고 있었던 건 아니랍니다. 성인이 된 이후 인지치료를 통해서 알게 된 사실이죠.",
      typography_38_0_0: "중간 믿음",
      typography_39_0_0:
        "무의식 중에 자리 잡은 핵심 믿음은 조르디에게 위협적이었어요.",
      typography_40_0_0:
        "조르디의 마음에 위와 같은 생각이 자리 잡은 뒤로부터 고통스러운 감정이 느껴지기 시작합니다. 부적절감, 수치심, 외로움과 같은 감정이죠.",
      typography_41_0_0: `"나는 부족하다"라는 생각을 마주하며 고통스러운 감정을 느끼기에는 너무 괴로웠어요.`,
      typography_42_0_0:
        "자연스럽게 조르디의 마음은 그러한 핵심 믿음에 대한 대처 전략을 마련했어요.",
      typography_43_0_0:
        "우리는 그러한 대처 전략을 '중간 믿음'이라고 부른답니다.",
      typography_44_0_0:
        "중간 믿음은 핵심 믿음의 위협에 대처하기 위해 만든 규칙, 태도, 가정이라고 이야기 할 수 있어요.",
      typography_45_0_0: `규칙은 삶에 대한 원칙으로 "~해야 한다. 하지 말아야 한다" 등이에요.`,
      typography_46_0_0:
        '태도는 삶에 대한 입장이나 시선으로 "사람들은 나쁘다"와 같은 것들이죠.',
      typography_47_0_0:
        '가정은 특정한 상황 속에서 적용되는 명제를 뜻해요. 주로 "~라면 ~일 것이다"라는 형태를 띠고 있죠.',
      typography_48_0_0:
        "더 자세한 내용은 뒤에서 알아볼게요. 우선은 조르디의 예시를 살펴보죠.",
      typography_49_0_0: "조르디는 다음과 같은 중간 믿음을 가지게 되었어요.",
      typography_50_0_0: `"완벽하게 해야 한다"라는 규칙과,`,
      typography_51_0_0: `"실패하는 건 끔찍하다"라는 태도와,`,
      typography_52_0_0: `"실수한다면 모든 게 무너지고 말 것이다"라는 가정이었죠.`,
      typography_53_0_0: `지나치게 완벽주의적인 생각들이었지만, 조르디는 이런 생각들을 기반으로 핵심 믿음에 대처해 올 수 있었어요.`,

      typography_54_0_0: "자동적 사고",
      typography_55_0_0:
        "앞서 <자동적인 생각>에서 자동적 사고를 '특정한 상황에서 마음속에 자동적이고 무의식적으로 떠오르는 생각'라고 설명했던 것, 기억하시나요?",
      typography_56_0_0: "이 자동적 사고는 아무렇게나 만들어진 건 아니에요.",
      typography_57_0_0:
        "자동적 사고는 핵심 믿음과 중간 믿음으로 만들어진 생각이기 때문이에요.",
      typography_58_0_0: "조르디의 예를 살펴볼까요?",
      typography_59_0_0: `조르디는 책상 앞에 앉아 회사 발표를 준비하고 있었어요(상황).`,
      typography_60_0_0: `"나는 부족하다"라는 핵심 믿음을 가지고 있던 조르디는`,
      typography_61_0_0: `"완벽하게 해야해, 실패는 정말 끔찍해, 실수하면 모든 게 무너질 거야"라는 중간 믿음을 갖고 살아왔죠`,
      typography_62_0_0: `그런 조르디는 순간 자동적으로 생각했죠. "내가 모르는 질문을 받으면 어떻게 하지? 만약 답변을 못하면? 안 돼. 집중하자."(자동적 사고)`,
      typography_63_0_0:
        "불안하고 초조해진 조르디는 두통을 느꼈어요(감정/신체).",
      typography_64_0_0:
        "하지만 침대에 눕기 직전까지 발표 자료를 검토하였답니다(행동).",
      typography_65_0_0: "아, 물론 그날 밤 조르디는 거의 한숨도 못 잤어요.",
      typography_66_0_0: "악순환의 연속",
      typography_67_0_0: "한 가지 흥미로운 사실이 있어요.",
      typography_68_0_0:
        "핵심 믿음과 중간 믿음이 만들어낸 자동적 사고는 흔히 다시 핵심 믿음을 더욱 강화한다는 것이죠.",
      typography_69_0_0: "조르디의 예를 살펴볼까요?",
      typography_70_0_0: `"나는 부족하다"라는 믿음에 대처하기 위해 만들어낸 완벽주의적인 규칙, 태도, 가정은 조금씩 조르디를 갉아먹게 돼요.`,
      typography_71_0_0:
        "강박적으로 완벽에 집착하다 보니 번아웃과 우울, 또 강렬한 불안에 빠지기도 하죠.",
      typography_72_0_0: `결국 "나는 부족하다"라는 핵심 믿음은 더욱 강해지죠. 그렇게 악순환이 시작돼요.`,
      typography_73_0_0: "더 깊은 내면으로",
      typography_74_0_0:
        "자, 이제 핵심 경험으로부터 만들어진 핵심 믿음이 중간 믿음을 만들어내고 그것은 다시 자동적 사고를 만들어 낸다는 것, 잘 이해가 되셨을까요?",
      typography_75_0_0:
        "이와 같이 핵심 경험으로부터 자동적 사고로 이어지는 생각 구조가 바로 생각 지도예요.",
      typography_76_0_0:
        "앞으로는 나의 생각 지도를 찾고, 그 생각 지도의 기능에 대해 잘 이해한 후, 그로부터 거리를 두는 연습을 해보려고 해요.",
      typography_77_0_0:
        "이제는 표면을 지나, 좀 더 깊은 내면으로 내려가보는 거예요.",
      typography_78_0_0:
        "나의 자동적 사고는 어떤 핵심 믿음으로부터 발생했으며, 그러한 믿음에 대한 대처로 어떤 중간 믿음을 발전시켜왔는지 알아보는 거예요.",
      typography_79_0_0:
        "미처 깨닫지 못했던 나의 생각 지도를 발견하는 일은 재밌고, 신기하고, 반갑기도 할 거예요. ",
      typography_80_0_0:
        "한편으로는 상처를 헤집는 것처럼 느껴져서 아프고, 무섭고, 슬프고, 걱정이 될 수 있어요. ",
      typography_81_0_0:
        "자, 여기서 디스턴싱의 목적을 다시 한번 기억해볼게요. ",
      typography_82_0_0:
        "우리는 앞에서 괴로웠던 자동적 사고로부터 거리를 둘 수 있는 방법을 배웠어요.",
      typography_83_0_0:
        "자동적 사고에 반사적으로 반응하지 않고, 괴로움을 줄일 수 있는 연습을 해왔죠. ",
      typography_84_0_0: "생각 지도를 확인해가는 일도 동일해요.",
      typography_85_0_0:
        "더 근원적이고 핵심적인 생각으로부터 거리를 두고, 자유로워지기 위함이죠.  ",
      typography_86_0_0:
        "물론 그렇게 하기 위해서는 단계적인 접근이 필요합니다.",
      typography_87_0_0:
        "처음부터 핵심 믿음으로부터 거리를 두기란 어려운 일이기 때문이에요.",
      typography_88_0_0:
        "그러니 우선 나의 중간 믿음은 무엇인지, 핵심 믿음은 무엇인지, 그리고 핵심 믿음을 만든 핵심 경험은 무엇인지, 하나씩 살펴보며 생각 지도를 함께 그려나가보아요.",
    },
    v3: {
      typography_0:
        "우리는 앞서 자동적 사고에 포함된 생각함정을 다양한 측면에서 살펴봤어요.",
      typography_1: "자, 다시 한번 떠올려볼까요?",
      typography_2:
        "자동적 사고는 마음속에 거의 무의식적으로 떠오르는 것이기 때문에 ‘자동적’이라고 불렀어요.",
      typography_3:
        "이러한 생각은 특정 상황에서 거의 반사적으로 나타나요. 숙고가 아니죠.",
      typography_4:
        "오히려 이러한 생각은 저절로 생겨나는 것 같기도 해요. 그것도 매우 빠르게 말이에요.",
      typography_5: "우리는 이러한 생각을 거의 인지하지 못했을 수도 있어요.",
      typography_6:
        "그저 마음속에 떠오른대로 생각하고, 느끼고, 행동했을 뿐이죠.",
      typography_7: "이렇듯 우리는 자동적 사고에 자동적으로 반응해요.",
      typography_8: "자동적 사고의 뿌리를 찾아서",
      typography_9: "그런데 이 자동적 사고는 대체 왜 만들어지는 걸까요?",
      typography_10:
        "사실 우리의 생각들은 보다 복잡하게 얽혀있답니다. 마치 그물처럼 말이죠.",
      typography_11:
        "앞서 <자동적인 생각>에서 생각 그물에 대해 잠깐 언급하였는데요. 오늘은 조금 더 깊이 살펴볼게요.",
      typography_12:
        "몇몇 위대한 학자들은 사람들이 핵심적인 생각부터 표면적인 생각까지 이어지는 일련의 생각 구조를 가진다는 것을 발견했는데요.",
      typography_13:
        "그러한 생각 구조를 디스턴싱에서는 생각 그물이라고 부른답니다.",
      typography_14:
        "생각 그물은 머릿속에서 자리 잡은 일종의 회로라고도 볼 수 있어요.",
      typography_15:
        "강력한 시냅스 결합으로 만들어진 회로는 평생에 걸쳐 반복적으로 활성화되며 특정한 형태의 생각들을 만들어내죠.",
      typography_16: `이 일련의 생각들은 마치 그물처럼 우리의 발을 묶어서 옴짝달싹 못 하게 만든답니다.`,
      typography_17: `가치를 향해 나아가지 못하도록 만들죠.`,
      typography_18: "생각 그물에는 네 가지 구성 요소가 있어요.",
      typography_19:
        "핵심 경험, 핵심 믿음, 중간 믿음, 그리고 자동적 사고인데요. 하나씩 살펴보도록 할게요.",
      typography_20: "핵심 경험",
      typography_21: "먼저 '핵심 경험'이에요.",
      typography_22: "핵심 경험은 '삶에서 큰 영향이 있었던 경험'을 뜻해요.",
      typography_23:
        "이때 경험은 주로 어릴 적에 겪었던 일일 때가 많지만, 경우에 따라 성인기에 핵심 경험이 발생하기도 한답니다.",
      typography_24:
        "예를 들어볼게요. 조르디는 어릴 적부터 높은 기준을 가지고 훈육하는 엄마 밑에서 자랐어요.",
      typography_25: `항상 "남들보다 더 잘 해야 한다"라는 부모님의 압박을 느껴왔죠.`,
      typography_26:
        "조르디는 엄마의 말에 큰 반감을 가지진 않았어요. 엄마는 어디서나 인정 받는 멋진 사람이었거든요.",
      typography_27:
        "조르디는 엄마에게 인정 받고 싶어 더 열심히 공부했어요. 하지만 그럼에도 엄마는 항상 내가 닳을 수는 없는 존재인 것만 같았죠.",
      typography_28: "핵심 믿음",
      typography_29: "핵심 경험은 '핵심 믿음'을 만들어내요.",
      typography_30:
        "핵심 믿음은 나, 타인, 세상에 대한 근본적인 생각을 뜻해요.",
      typography_31:
        "핵심 믿음은 마음속의 아주 깊은 곳에 있어서 사람들은 자신에게 어떤 믿음이 있는지 쉽게 알지 못하죠.",
      typography_32:
        "핵심 믿음은 주로 어릴 적부터 반복적으로 누적되어온 경험에 의해서 형성될 때가 많아요. ",
      typography_33:
        "가랑비에 옷이 젖듯이 마음속 깊은 곳에 새겨진 ‘나, 타인, 세상’을 향한 자화상이라고 할 수 있죠. 예를 들어볼까요?",
      typography_34: "다시 조르디의 예를 살펴볼게요.",
      typography_35:
        "높은 기준을 가지고 있는 엄마 밑에서 자란 조르디는 자연스럽게 다음과 같은 생각을 가지게 되었어요.",
      typography_36: `"나는 충분하지 않아. 나는 부족해. 부족한 나는 관심을 받을 수 없어, 사람들은 날 좋아해주지 않아"`,
      typography_37:
        "물론 조르디가 그 생각을 인지하고 있었던 건 아니랍니다. 성인이 된 이후 인지치료를 통해서 알게 된 사실이죠.",
      typography_38: "중간 믿음",
      typography_39:
        "무의식 중에 자리 잡은 핵심 믿음은 조르디에게 위협적이었어요.",
      typography_40:
        "조르디의 마음에 위와 같은 생각이 자리 잡은 뒤로부터 고통스러운 감정이 느껴지기 시작합니다. 부적절감, 수치심, 외로움과 같은 감정이죠.",
      typography_41: `"나는 부족하다"라는 생각을 마주하며 고통스러운 감정을 느끼기에는 너무 괴로웠어요.`,
      typography_42:
        "자연스럽게 조르디의 마음은 그러한 핵심 믿음에 대한 대처 전략을 마련했어요.",
      typography_43: "우리는 그러한 대처 전략을 '중간 믿음'이라고 부른답니다.",
      typography_44:
        "중간 믿음은 핵심 믿음의 위협에 대처하기 위해 만든 규칙, 태도, 가정이라고 이야기 할 수 있어요.",
      typography_45: `규칙은 삶에 대한 원칙으로 "~해야 한다. 하지 말아야 한다" 등이에요.`,
      typography_46:
        '태도는 삶에 대한 입장이나 시선으로 "사람들은 나쁘다"와 같은 것들이죠.',
      typography_47:
        '가정은 특정한 상황 속에서 적용되는 명제를 뜻해요. 주로 "~라면 ~일 것이다"라는 형태를 띠고 있죠.',
      typography_48:
        "더 자세한 내용은 뒤에서 알아볼게요. 우선은 조르디의 예시를 살펴보죠.",
      typography_49: "조르디는 다음과 같은 중간 믿음을 가지게 되었어요.",
      typography_50: `"완벽하게 해야 한다"라는 규칙과,`,
      typography_51: `"실패하는 건 끔찍하다"라는 태도와,`,
      typography_52: `"실수한다면 모든 게 무너지고 말 것이다"라는 가정이었죠.`,
      typography_53: `지나치게 완벽주의적인 생각들이었지만, 조르디는 이런 생각들을 기반으로 핵심 믿음에 대처해 올 수 있었어요.`,

      typography_54: "자동적 사고",
      typography_55:
        "앞서 <자동적인 생각>에서 자동적 사고를 '특정한 상황에서 마음속에 자동적이고 무의식적으로 떠오르는 생각'라고 설명했던 것, 기억하시나요?",
      typography_56: "이 자동적 사고는 아무렇게나 만들어진 건 아니에요.",
      typography_57:
        "자동적 사고는 핵심 믿음과 중간 믿음으로 만들어진 생각이기 때문이에요.",
      typography_58: "조르디의 예를 살펴볼까요?",
      typography_59: `조르디는 책상 앞에 앉아 회사 발표를 준비하고 있었어요(상황).`,
      typography_60: `"나는 부족하다"라는 핵심 믿음을 가지고 있던 조르디는`,
      typography_61: `"완벽하게 해야해, 실패는 정말 끔찍해, 실수하면 모든 게 무너질 거야"라는 중간 믿음을 갖고 살아왔죠`,
      typography_62: `그런 조르디는 순간 자동적으로 생각했죠. "내가 모르는 질문을 받으면 어떻게 하지? 만약 답변을 못하면? 안 돼. 집중하자."(자동적 사고)`,
      typography_63:
        "불안하고 초조해진 조르디는 두통을 느꼈어요(감정/신체 반응).",
      typography_64:
        "하지만 침대에 눕기 직전까지 발표 자료를 검토하였답니다(행동).",
      typography_65: "아, 물론 그날 밤 조르디는 거의 한숨도 못 잤어요.",
      typography_66: "악순환의 연속",
      typography_67: "한 가지 흥미로운 사실이 있어요.",
      typography_68:
        "핵심 믿음과 중간 믿음이 만들어낸 자동적 사고는 흔히 다시 핵심 믿음을 더욱 강화한다는 것이죠.",
      typography_69: "조르디의 예를 살펴볼까요?",
      typography_70: `"나는 부족하다"라는 믿음에 대처하기 위해 만들어낸 완벽주의적인 규칙, 태도, 가정은 조금씩 조르디를 갉아먹게 돼요.`,
      typography_71:
        "강박적으로 완벽에 집착하다 보니 번아웃과 우울, 또 강렬한 불안에 빠지기도 하죠.",
      typography_72: `결국 "나는 부족하다"라는 핵심 믿음은 더욱 강해지죠. 그렇게 악순환이 시작돼요.`,
      typography_73: "더 깊은 내면으로",
      typography_74:
        "자, 이제 핵심 경험으로부터 만들어진 핵심 믿음이 중간 믿음을 만들어내고 그것은 다시 자동적 사고를 만들어 낸다는 것, 잘 이해가 되셨을까요?",
      typography_75:
        "이와 같이 핵심 경험으로부터 자동적 사고로 이어지는 생각 구조가 바로 생각 그물이에요.",
      typography_76:
        "앞으로는 나의 생각 그물을 파악하고, 그 생각 그물의 기능에 대해 잘 이해한 후, 그로부터 거리를 두는 연습을 해보려고 해요.",
      typography_77:
        "이제는 표면을 지나, 좀 더 깊은 내면으로 내려가보는 거예요.",
      typography_78:
        "나의 자동적 사고는 어떤 핵심 믿음으로부터 발생했으며, 그러한 믿음에 대한 대처로 어떤 중간 믿음을 발전시켜왔는지 알아보는 거예요.",
      typography_79:
        "미처 깨닫지 못했던 나의 생각 그물을 파악하는 일은 재밌고, 신기하고, 반갑기도 할 거예요. ",
      typography_80:
        "한편으로는 상처를 헤집는 것처럼 느껴져서 아프고, 무섭고, 슬프고, 걱정이 될 수 있어요. ",
      typography_81: "자, 여기서 디스턴싱의 목적을 다시 한번 기억해볼게요. ",
      typography_82:
        "우리는 앞에서 괴로웠던 자동적 사고로부터 거리를 둘 수 있는 방법을 배웠어요.",
      typography_83:
        "자동적 사고에 반사적으로 반응하지 않고, 괴로움을 줄일 수 있는 연습을 해왔죠. ",
      typography_84: "생각 그물을 파악해가는 일도 동일해요.",
      typography_85:
        "더 근원적이고 핵심적인 생각으로부터 거리를 두고, 자유로워지기 위함이죠.  ",
      typography_86: "물론 그렇게 하기 위해서는 단계적인 접근이 필요합니다.",
      typography_87:
        "처음부터 핵심 믿음으로부터 거리를 두기란 어려운 일이기 때문이에요.",
      typography_88:
        "그러니 우선 나의 중간 믿음은 무엇인지, 핵심 믿음은 무엇인지, 그리고 핵심 믿음을 만든 핵심 경험은 무엇인지, 하나씩 살펴보며 생각 그물을 함께 파악해 나가보아요.",
    },
  },
};

export const translation_6_11_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `이제 앞으로 우리는 가치를 향해 걸어 나가며,`,
      typography_1: `그 순간 마주하는 생각 그물들을 알아차리고,`,
      typography_2: `그로부터 거리를 두는 연습을 해나갈 텐데요.`,
      typography_3: `그 전에 한 가지 되짚어보아야 할 게 있어요.`,
      typography_4: `가치랍니다.`,
      typography_5: `지금 이 시점에서 가치를 되짚어보는 일은 매우 중요한데요.`,
      typography_6: `사실 사람들이 자신의 가치라고 믿고 있는 것들 중 상당 부분이 생각 그물에서 만들어진 것일 때가 많기 때문이에요.`,
      typography_7: `더 자세히 이야기 나눠볼게요.`,
      typography_8: `남을 만족시키기 위한 가치`,
      typography_9: `사실 많은 사람들은 자신이 어디로 가고 있는지 모호한 상태로 인생을 살아가고 있답니다.`,
      typography_10: `삶을 이끌어나갈 방향성을 모르는 것이죠.`,
      typography_11: `이건 골대가 어딘지 모르면서 축구 경기를 하는 것과 같고,`,
      typography_12: `목적지도 모른 채 비행기를 타는 것과 같아요.`,
      typography_13: `사실 가치를 찾는 건 쉽지 않은 일이에요.`,
      typography_14: `그렇기 때문에 많은 경우 남을 만족시키기 위한 가치를 자신의 가치로 착각하기도 한답니다.`,
      typography_15: `부모님을 만족시키거나,`,
      typography_16: `지인들에게 더 잘 보이거나,`,
      typography_17: `그저 사회에서 보이기에 그럴듯하기 때문에 수동적으로 그 가치를 좇고 있는 거죠.`,
      typography_18: `믿음에 대처하기 위한 가치`,
      typography_19: `특히 그러한 가치들은 나의 핵심 믿음에 대처하기 위한 것일 때가 많아요.`,
      typography_20: `“나는 부족하다”라는 핵심 믿음을 가지고 있는 사람은,`,
      typography_21: `그 믿음과 맞서 싸우고자 ‘사회적 성취와 성장을 좇는 삶’을 자신의 가치로 내세우기도 하고,`,
      typography_22: `그 믿음을 피하고자 ‘무조건 남을 배려하고 수용하는 삶’을 자신의 가치로 내세우기도 하며,`,
      typography_23: `그 믿음에 굴복하고자 ‘안전하고 안빅낙도적인 삶’을 자신의 가치로 내세우기도 해요.`,
      typography_24: `각각의 가치는 그 내용만 보아서는 큰 문제가 없지만`,
      typography_25: `“나는 부족하다"라는 핵심 믿음, 그리고 그것에서 출발한 생각 그물을 이해하고 바라보면`,
      typography_26: `진정한 가치가 아니라 임시방편의 대처 전략이었다는 걸 알 수 있어요.`,
      typography_27: `목표나 결과로서의 가치`,
      typography_28: `믿음에 대처하기 위한 가치는 특히 ‘방향’이 아니라 ‘목표'나 ‘결과'로 오해될 때가 많은데요.`,
      typography_29: `그것만 달성하면 나를 힘들게 하는 믿음을 극복하고 나의 연약한 자아가 바뀔 것이라고 믿기 때문이에요.`,
      typography_30: `그렇기 때문에 방향이 아니라 목표나 결과를 좇기 쉽답니다.`,
      typography_31: `돈을 얼마나 벌기,`,
      typography_32: `어느 대학교에 가기,`,
      typography_33: `어느 회사에 취직하기,`,
      typography_34: `책 100권 읽기,`,
      typography_35: `인플루언서 되기.`,
      typography_36: `모두 어떤 가치를 좇는 과정에서 단계적인 목표가 될 수 있겠지만,`,
      typography_37: `믿음에 대처하기 위한 가치는 종종 위와 같은 단편적이면서도 목적 지향적인 형태를 띨 때가 많답니다.`,
      typography_38: `가치는 내가 선택한 삶의 방향`,
      typography_39: `다시 한번 강조하고 싶어요.`,
      typography_40: `가치는`,
      typography_41: `‘내가’ (남이 아닌)`,
      typography_42: `‘선택한’ (대처가 아닌)`,
      typography_43: `삶의 ‘방향'(목표나 결과가 아닌)이에요.`,
      typography_44: `자, 이제 생각 그물을 옆에 두고 가치를 다시 한번 더 바라볼까 해요.`,
      typography_45: `우선 나의 생각 그물을 다시 한번 찬찬히 읽어볼까요?`,
      typography_46: `이제 나의 가치를 하나씩 살펴보며, 그것이 ‘내가 선택한 삶의 방향'이 아닌, 믿음에 대한 대처 전략은 아닌지 점검해볼게요.`,
      typography_47: `아래 가치는 진정으로 스스로가 선택한 삶의 방향에 부합하나요?`,
      typography_47_no_1: `어떤 부분에서 대처 전략이라고 생각하시나요?`,
      typography_48: `좋아요. 앞으로도 가치를 살펴볼 때면, 그것이 나의 생각 그물에서 출발하지 않았는지 거리를 두고 생각해보세요.`,
      typography_49: `생각 그물과는 어떻게 거리를 두냐고요?`,
      typography_50: `그건 다음 활동에서 더 다뤄보기로 해요.`,
    },
  },
};

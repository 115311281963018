import { Box, Grid, Stack } from "@mui/joy";
import {
  AutomaticThoughtBeliefType,
  ConceptKeyType_automaticThought,
  ConceptType,
  ImpactScoreHistoryListType,
  IndividualThoughtType,
} from "api/conceptApi";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { ThoughtMapText } from "./ThoughtMapText";
import ThoughtMapCard from "./ThoughtMapCard";
import { useRef, useState } from "react";
import AutomaticThoughtByThoughtTrap from "./AutomaticThoughtByThoughtTrap";

function AutomaticThoughtPattern({
  situation,
  thought,
  thoughtList,
  emotion,
  physicalResponse,
  behavior,
  impactScoreHistoryList,
  onAutomaticThoughtClick,
  belief,
}: {
  situation?: string;
  thought?: string;
  thoughtList?: IndividualThoughtType[];
  emotion?: string;
  physicalResponse?: string;
  behavior?: string;
  impactScoreHistoryList?: ImpactScoreHistoryListType[];
  belief?: AutomaticThoughtBeliefType;
  onAutomaticThoughtClick: () => void;
}) {
  const { t: t_thoughtmap } = useTranslation("translation", {
    keyPrefix: "thoughtmap",
  });
  return (
    <Stack spacing={2} sx={{ textAlign: "left" }}>
      {[
        { id2: "situation", label: "상황", content: situation },

        {
          id2: "thought",
          label: "자동적 사고",
          content: thoughtList?.map((each) => each.thought),
        },
        {
          id2: "emotion",
          label: "감정",
          content: emotion,
        },
        {
          id2: "physicalResponse",
          label: "신체",
          content: physicalResponse,
        },
        { id2: "behavior", label: "행동", content: behavior },
      ].map(({ label, content }) => (
        <Stack key={`${label}${content}`} spacing={"8px"}>
          <ThoughtMapText
            text={t_thoughtmap(label)}
            dimmed
            color="primary.solid"
          />
          {typeof content === "string" ? (
            <ThoughtMapText text={content || ""} />
          ) : (
            content
              ?.filter((element) => (element || "").length > 0)
              ?.map((thought) => (
                <ThoughtMapText
                  key={thought}
                  text={thought || ""}
                  // hasLink
                  // action={onAutomaticThoughtClick}
                />
              ))
          )}
        </Stack>
      ))}
      {thoughtList && thoughtList.length > 0 && (
        <>
          <Box
            sx={{
              height: "1px",
              width: "100%",
              backgroundColor: "divider",
            }}
          />
          <AutomaticThoughtByThoughtTrap
            impactScoreHistoryList={impactScoreHistoryList}
            thoughtList={thoughtList.filter((element) => element.thought)}
          />
        </>
      )}
      {belief && (
        <>
          <Box
            sx={{
              height: "1px",
              width: "100%",
              backgroundColor: "divider",
            }}
          />
          {[
            {
              id: "intermediateBelief",
              label: "관련 중간 믿음",
              content: belief.intermediateBelief,
            },
            {
              id: "copingStrategy",
              label: "대처 전략",
              content: belief.copingStrategy,
            },
          ].map(({ id, label, content }) => (
            <Stack key={`${id}`} spacing={"8px"}>
              <ThoughtMapText text={label} dimmed color="primary.solid" />
              <ThoughtMapText text={content || ""} />
            </Stack>
          ))}
        </>
      )}
    </Stack>
  );
}

export default function AutomaticThought({
  data,
  idList,
  includeAutomaticThoughtDetail,
}: {
  data: ConceptType;
  idList: ConceptKeyType_automaticThought[];
  includeAutomaticThoughtDetail?: boolean;
}) {
  const detailRef = useRef<HTMLDivElement>(null);
  const { t: t_thoughtmap } = useTranslation("translation", {
    keyPrefix: "thoughtmap",
  });

  // const labelPostFixList = ["①", "②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨", ];
  const automaticThoughtList = idList?.map((id) => {
    const automaticThoughtNum = parseInt(id.split("automaticThought")[1]);
    return {
      id: id,
      label: "부정 패턴",
      labelPostfix: `${automaticThoughtNum}`,
      content: data[id],
    };
  });

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  return (
    <>
      <Grid container direction={"row"} spacing={2} flexGrow={1}>
        {automaticThoughtList.map(
          ({ id, label, labelPostfix, content }, index) => (
            <Grid
              key={id}
              sm={12 / Math.min(automaticThoughtList.length, 2)}
              xs={12}
              sx={{ display: "flex" }}
            >
              <ThoughtMapCard
                sx={{ flexGrow: 1 }}
                key={id}
                label={label}
                labelPostfix={labelPostfix}
                content={
                  Object.values(content || {}).every(
                    (each) =>
                      !each ||
                      (typeof each === "string" && each.trim().length === 0)
                  ) ? (
                    <ThoughtMapText
                      text={"일상에서 내가 겪는 불편은?"}
                      dimmed
                    />
                  ) : (
                    <AutomaticThoughtPattern
                      {...content}
                      onAutomaticThoughtClick={() => {
                        setSelectedIndex(index);
                        detailRef.current?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }}
                    />
                  )
                }
                dimmed={Object.values(content || {}).every(
                  (each) =>
                    !each ||
                    (typeof each === "string" && each.trim().length === 0)
                )}
              />
            </Grid>
          )
        )}
      </Grid>
      {/* {(
        (includeAutomaticThoughtDetail &&
          automaticThoughtList[0].content?.impactScoreHistoryList) ||
        []
      ).length > 0 && (
        <Box ref={detailRef}>
          <AutomaticThoughtImpactScoreChange
            data={data}
            idList={idList}
            defaultIndex={selectedIndex}
          />
        </Box>
      )} */}
    </>
  );
}

import { Box, Sheet, Stack } from "@mui/joy";
import {
  ConceptKeyType,
  ConceptKeyType_automaticThought,
  ConceptType,
  coreBeliefKeyMapping,
  CoreBeliefKeyType,
  intermediateBeliefKeyMapping,
  IntermediateBeliefKeyType,
} from "api/conceptApi";
import _ from "lodash";
import ThoughtMapCard from "./ThoughtMapCard";
import { ThoughtMapText } from "./ThoughtMapText";
import AutomaticThought from "./AutomaticThought";
import BeliefTemplete from "./BeliefTemplate";

export function ThoughtmapDownArrow() {
  return (
    <Box sx={{ my: "20px", mx: "auto" }}>
      <svg
        width="20"
        height="40"
        viewBox="0 0 20 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 25.1229L10 40L0 25.1229H6.09712V-5.34058e-05H14.1337V25.1229H20Z"
          fill="url(#paint0_linear_1389_20202)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1389_20202"
            x1="10.0001"
            y1="38.9885"
            x2="10.0001"
            y2="4.98184e-05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#14A191" />
            <stop offset="1" stopColor="#14A191" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

export default function ThoughtMap({
  data,
  from,
  to,
  fixedValue,
  includeAutomaticThoughtDetail,
}: {
  data: ConceptType;
  from?: ConceptKeyType;
  to?: ConceptKeyType;
  fixedValue?: { [key in ConceptKeyType]?: string };
  includeAutomaticThoughtDetail?: boolean;
}) {
  const keyList = [
    "coreExperience",
    "coreBelief",
    "intermediateBelief",
    "copingStrategy",
    "automaticThought",
  ];

  const visibleKeyList = keyList.filter(
    (each, index) =>
      (!from || index >= keyList.findIndex((element) => element === from)) &&
      (!to || index <= keyList.findIndex((element) => element === to))
  );

  const automaticThoughtKeyList: ConceptKeyType_automaticThought[] =
    Object.keys(data)
      .filter(
        (element) =>
          element.startsWith("automaticThought") &&
          !element.startsWith("automaticThoughtForValue")
      )
      .map((each) => each as ConceptKeyType_automaticThought);

  const coreExperienceData = [
    {
      id: "coreExperience",
      label: "핵심 경험",
      placeholder: "지금의 나를 만든 과거의 경험은?",
      content: data?.coreExperience,
    },
  ];

  return (
    <Sheet
      sx={{
        height: "100%",
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {visibleKeyList.includes("coreExperience") &&
        coreExperienceData.map(({ id, label, content, placeholder }, index) => (
          <Stack key={id} spacing={0} alignItems="center" sx={{ p: 0 }}>
            <ThoughtMapCard
              label={label}
              content={
                <ThoughtMapText
                  text={
                    (content && content?.trim().length > 0
                      ? content
                      : placeholder) as string
                  }
                  dimmed={!content || content?.trim().length === 0}
                />
              }
              dimmed={!content || content?.trim().length === 0}
            />
          </Stack>
        ))}
      {visibleKeyList.includes("coreBelief") && (
        <BeliefTemplete
          title={"핵심 믿음"}
          placeholder={
            fixedValue?.coreBelief
              ? fixedValue?.coreBelief
              : "내 마음속 깊이 자리 잡은 생각은?"
          }
          data={Object.entries(data?.coreBelief || {})
            .filter(([label, value]) => value?.length > 0)
            .map(([label, value]) => ({
              label: coreBeliefKeyMapping[label as CoreBeliefKeyType],
              value: value,
            }))}
          hasConnectionLine={visibleKeyList.includes("coreExperience")}
        />
      )}
      {visibleKeyList.includes("intermediateBelief") && (
        <BeliefTemplete
          title={"중간 믿음"}
          placeholder={"오늘날 내가 삶을 해쳐나가는 방식은?"}
          data={Object.entries(data?.intermediateBelief || {})
            .filter(([label, value]) => value?.length > 0)
            .map(([label, value]) => ({
              label:
                intermediateBeliefKeyMapping[
                  label as IntermediateBeliefKeyType
                ],
              value: value,
            }))}
          hasConnectionLine={visibleKeyList.includes("coreBelief")}
        />
      )}

      {visibleKeyList.includes("automaticThought") && (
        <>
          <ThoughtmapDownArrow />
          <AutomaticThought
            data={data}
            idList={automaticThoughtKeyList}
            includeAutomaticThoughtDetail={includeAutomaticThoughtDetail}
          />
        </>
      )}
    </Sheet>
  );
}

import { Box, Sheet, Stack, Typography } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";
import React from "react";
import { extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";

export default function ThoughtMapCard({
  label,
  labelPostfix,
  content,
  dimmed,
  sx,
}: {
  label: string;
  labelPostfix?: string;
  content?: React.ReactNode;
  dimmed: boolean;
  sx?: SxProps;
}) {
  const cardColor = dimmed
    ? "#C2E2DC"
    : extractHexColor(theme.vars.palette.primary.solid);
  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "20px",
        boxShadow: `inset 0 0 0 2px ${cardColor}`,
        overflow: "hidden",
        backgroundColor: "white",
      }}
    >
      <Sheet
        sx={{
          backgroundColor: cardColor,
          py: "8px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <Typography fontWeight="md" color={"negative"}>
          {label}
        </Typography>
        {labelPostfix && (
          <Stack
            sx={{
              width: "15px",
              height: "15px",
              borderRadius: "10px",
              backgroundColor: "white",
            }}
            justifyContent="center"
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontSize: "8px",
                fontWeight: 700,
                color: cardColor,
              }}
            >
              {labelPostfix}
            </Typography>
          </Stack>
        )}
      </Sheet>
      <Sheet
        sx={{
          backgroundColor: "transparent",
          p: "16px",
          textAlign: "center",
          height: "100%",
        }}
      >
        {content}
      </Sheet>
    </Box>
  );
}

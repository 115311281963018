import { SolidLabelLine, TextareaLine, Thoughtmap } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../BlockComponent";

import { AutomaticThought, LastCard } from "./common";

export const Surrender = [
  Instruction(`part2_typography_1_0_0`),
  InstructionWithContent("part2_typography_2_0_0", [
    Thoughtmap("coreBelief", undefined, {
      coreBelief: "?",
      copingStrategy: "굴복",
    }),
  ]),
  InstructionWithTextarea(`part2_typography_3_0_0`),
];

export const Overcompensation = [
  Instruction(`part2_typography_4_0_0`),
  InstructionWithContent("part2_typography_5_0_0", [
    Thoughtmap("coreBelief", undefined, {
      coreBelief: "?",
      copingStrategy: "과잉보상",
    }),
  ]),
  InstructionWithTextarea(`part2_typography_6_0_0`),
];

export const Avoidance = [
  Instruction(`part2_typography_7_0_0`),
  InstructionWithContent("part2_typography_8_0_0", [
    Thoughtmap("coreBelief", undefined, {
      coreBelief: "?",
      copingStrategy: "회피",
    }),
  ]),
  InstructionWithTextarea(`part2_typography_9_0_0`),
];

export const Content_6_5_A = (
  hasSurrender: boolean,
  hasOvercompensation: boolean,
  hasAvoidance: boolean
) => [
  Instruction(`typography_0_0_0`),
  Instruction(`typography_1_0_0`),
  Instruction(`typography_2_0_0`),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),

  Header1(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  InstructionWithContent(`typography_7_0_0`, AutomaticThought({ id: "1" })),
  InstructionWithContent(`typography_8_0_0`, AutomaticThought({ id: "2" })),
  InstructionWithContent(`typography_9_0_0`, AutomaticThought({ id: "3" })),

  Instruction(`part1_typography_0_0_0`, { blockId: "common1_start" }),
  Instruction(`part1_typography_1_0_0`),
  InstructionWithContent(`part1_typography_2_0_0`, [
    SolidLabelLine("스스로에 대해"),
    TextareaLine(),
    SolidLabelLine("다른 사람에 대해"),
    TextareaLine(),
    SolidLabelLine("이 세상에 대해"),
    TextareaLine(),
  ]),
  InstructionWithTextarea(`part1_typography_3_0_0`),

  Instruction(`typography_10_0_0`, { blockId: "common1_end" }),
  Header1(`typography_11_0_0`),
  InstructionWithContent("typography_12_0_0", [Thoughtmap("copingStrategy")]),

  Instruction(`part2_typography_0_0_0`, { blockId: "common2_start" }),
  ...(hasSurrender ? Surrender : []),
  ...(hasOvercompensation ? Overcompensation : []),
  ...(hasAvoidance ? Avoidance : []),

  Instruction(`typography_13_0_0`, { blockId: "common2_end" }),
  Instruction(`typography_14_0_0`),

  ...LastCard,
];

import { automaticThoughtIdType } from "api/conceptApi";
import {
  DimmedLabelLine,
  DividerLine,
  LineWithSolidLabel,
  SolidLabelLine,
  TextareaLine,
  TypographyFromDataLine,
  TypographyLine,
} from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
  InstructionWithTextarea,
} from "../BlockComponent";
import {
  Content_4_5_A_end,
  Content_4_5_A_end_type2,
  Content_4_5_A_start,
} from "./4-5-A";
import { AutomaticThought } from "./common";

export const Content_4_5_5_A = (automaticThoughtId: automaticThoughtIdType) => [
  ...Content_4_5_A_start(automaticThoughtId),
  Header1(`typography_0_0_0`),

  InstructionWithContent(
    `typography_1_0_0`,
    [`typography_1_3_0`, `typography_1_5_0`, `typography_1_7_0`].flatMap(
      (each, index) => [
        ...LineWithSolidLabel(`단계`, TypographyLine(each), {
          labelIndex: index + 1,
        }),
      ]
    )
  ),
  InstructionWithContent(
    `typography_2_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),

  Instruction(`typography_3_0_0`),
  InstructionWithData(
    { A: { id: "생각" }, B: { id: "감정" } },
    `typographyFromData_4_0_0`
  ),
  InstructionWithTextarea(`typography_5_0_0`),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_6_0_0`
  ),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  InstructionWithData({ A: { id: "생각" } }, `typographyFromData_12_0_0`),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_13_0_0`
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_14_0_0`
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "감정" }, B: { id: "생각" } },
    `typographyFromData_15_0_0`
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_16_0_0`
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_17_0_0`
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_18_0_0`
  ),

  InstructionWithDataWithContent(
    { A: { id: "생각" } },
    `typographyFromData_19_0_0`,
    [
      ...[
        [`typography_19_4_0`, `typography_19_6_0`],
        [`typography_19_10_0`, `typography_19_12_0`],
      ].flatMap((each, index) => [
        SolidLabelLine(`예시`, { labelIndex: index + 1 }),
        DimmedLabelLine(`마음속에 떠오른 개념화`),
        TypographyLine(each[0]),
        DimmedLabelLine(`거리두기를 통해 바라본 개념화`),
        TypographyLine(each[1]),
        DividerLine(),
      ]),
      SolidLabelLine(`나의 개념화`),
      DimmedLabelLine(`마음속에 떠오른 개념화`),
      TypographyFromDataLine({ A: { id: "생각" } }, "$A$"),
      DimmedLabelLine(`거리두기를 통해 바라본 개념화`),
      TextareaLine(),
    ]
  ),

  Instruction(`typography_20_0_0`),

  // ...Content_4_5_A_end_type2(automaticThoughtId, 5),
  ...Content_4_5_A_end(automaticThoughtId, 5),
];

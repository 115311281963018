import Typography from "@mui/joy/Typography";
import { memo } from "react";
import { useRecoilValue } from "recoil";
import useOpenTask from "hooks/useOpenTask";
import { userAtom } from "recoil/userAtom";
import { IncompleteChip, LockChip } from "./TaskChip";
import { Box, Stack } from "@mui/joy";
import useGetTaskTitleFromTaskKey from "hooks/useGetTaskTitleFromTaskKey";
import useOpenThoughtRecord from "hooks/useOpenThoughtRecord";
import { getTaskIdFromTaskKey } from "logic/logics";
import useOpenNewThoughtRecord from "hooks/useOpenNewThoughtRecord";
import { isDev } from "config";

const CheckIcon = () => {
  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3.5L4 6.5L9.5 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export function Status({
  isDone,
  isOpen,
}: {
  isDone: boolean;
  isOpen: boolean;
}) {
  return (
    <Stack
      sx={{
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        ...(!isDone
          ? {
              borderWidth: "1.6px",
              borderColor: isOpen ? "primary.solid" : "#B6B9BC",
            }
          : {
              backgroundColor: "#B6B9BC",
            }),
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isDone && <CheckIcon />}
    </Stack>
  );
}

export function LockIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8V6C14 3.79086 12.2091 2 10 2V2C7.79086 2 6 3.79086 6 6V8"
        stroke="#14A191"
        stroke-width="2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 8C2.89543 8 2 8.89543 2 10V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V10C18 8.89543 17.1046 8 16 8H4ZM12 12C12 12.9319 11.3626 13.715 10.5 13.937V15.5C10.5 15.7761 10.2761 16 10 16C9.72386 16 9.5 15.7761 9.5 15.5V13.937C8.63739 13.715 8 12.9319 8 12C8 10.8954 8.89543 10 10 10C11.1046 10 12 10.8954 12 12Z"
        fill="#14A191"
      />
    </svg>
  );
}

function TaskItemTemplate({
  taskKey,
  isOpen,
  isDone,
  isLocked,
  hasMissingValue,
  disableDirectAction,
  linkedThoughtRecordKey,
  setNumber,
  hasConnectionLine,
  connectionTop,
  connectionHeight,
  isFirstOfSet,
  isLastOfSet,
}: {
  taskKey: string;
  isOpen: boolean;
  isDone: boolean;
  isLocked?: boolean;
  hasMissingValue?: boolean;
  disableDirectAction?: boolean;
  linkedThoughtRecordKey?: string;
  setNumber?: number;
  hasConnectionLine?: boolean;
  connectionTop?: number;
  connectionHeight?: number;
  isFirstOfSet?: boolean;
  isLastOfSet?: boolean;
}) {
  const openTask = useOpenTask(`${taskKey}`);
  const openThoughtRecord = useOpenThoughtRecord(linkedThoughtRecordKey);
  const openNewThoughtRecord = useOpenNewThoughtRecord();
  const openPayment = useOpenTask("payment");
  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";

  const title = useGetTaskTitleFromTaskKey(taskKey);
  return (
    <Stack
      direction="row"
      alignItems={"center"}
      sx={{
        position: "relative",
        cursor: "pointer",
        px: "12px",
        py: "8px",
        borderRadius: "12px",
        "&:hover": {
          backgroundColor: "primary.soft",
        },
      }}
      onClick={() => {
        (document.activeElement as HTMLElement)?.blur();
        if (!disableDirectAction) {
          if (isLocked) {
            openPayment();
          } else {
            if (
              getTaskIdFromTaskKey(taskKey) === "0-7-A" ||
              getTaskIdFromTaskKey(taskKey) === "7-2-A" ||
              getTaskIdFromTaskKey(taskKey) === "9-5-A" ||
              getTaskIdFromTaskKey(taskKey) === "9-7-A" ||
              getTaskIdFromTaskKey(taskKey) === "9-9-A" ||
              getTaskIdFromTaskKey(taskKey) === "9-11-A"
            ) {
              if (linkedThoughtRecordKey) {
                openThoughtRecord();
              } else {
                openNewThoughtRecord();
              }
            } else {
              openTask();
            }
          }
        }
      }}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        spacing={"8px"}
        sx={{ flex: 1 }}
      >
        {!isLocked ? <Status isOpen={isOpen} isDone={isDone} /> : <LockIcon />}
        {hasConnectionLine && (
          <Box
            sx={{
              width: "8px",
              height: `${connectionHeight}px`,
              position: "absolute",
              top: `${connectionTop}px`,
              left: "-16px",
              borderRadius: `${isFirstOfSet ? 4 : 0}px 0px 0px ${
                isLastOfSet ? 4 : 0
              }px`,
              borderWidth: `1px 0px ${isLastOfSet ? 1 : 0}px 1px`,
              borderColor: "#C9CBCD",
            }}
          ></Box>
        )}

        <Typography sx={{ flex: 1 }} fontWeight={500}>
          {/* {title} */}
          {isDev && false
            ? `${setNumber}  ${getTaskIdFromTaskKey(taskKey)}`
            : title}
        </Typography>
      </Stack>

      {isCoach && hasMissingValue && <IncompleteChip />}
    </Stack>
  );
}

export default memo(TaskItemTemplate);
